import {
  PHOTOS_GET,
  ADD_PHOTOS,
  ACTION_START,
  ACTION_FAILED,
  ACTION_RESET,
  ACTION_SUCCESS,
} from '../constants';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PHOTOS_GET:
      if (action.status === ACTION_START) {
        const photos = Object.assign([...state], { isFetching: true });
        return photos;
      }
      if (action.status === ACTION_SUCCESS) {
        const photos = action.data.data || [];
        photos.isFetching = false;
        return photos;
      }
      if (action.status === ACTION_FAILED) {
        const photos = Object.assign([...state], { isFetching: false });
        return photos;
      }
      return state;

    case ADD_PHOTOS: {
      let newPhotos = [];

      if (action.status === ACTION_SUCCESS) {
        console.log(action);
        newPhotos = action.data;
      }

      const photos = Object.assign(
        [...state, ...newPhotos],
        action.status !== ACTION_RESET && { addPhotosStatus: action.status }
      );

      return photos;
    }

    default:
      return state;
  }
}
