import {
  NEW_ORDER_SET_ITEM_QUANTITY,
  NEW_ORDER_SET_MONTHS,
  NEW_ORDER_SET_DATE,
  NEW_ORDER_SET_TIMESLOT,
  NEW_ORDER_ADD_COUPON,
  NEW_ORDER_SET_PAYMENTMODE,
  NEW_ORDER_SET_ADDRESS,
  NEW_ORDER_SUBMIT,
  NEW_ORDER,
  NEW_ORDER_SET_ASSIGNEE_ID,
  NEW_ORDER_SET_COMMENT,
  NEW_ORDER_SET_NAME,
  NEW_ORDER_SET_EMAIL,
  NEW_ORDER_SET_PHONE,
  ACTION_START,
  ACTION_RESET,
  ACTION_WAITING,
} from '../constants';
import errorHandleActions from '../actions/errorHandle';

// import storageActions from './storages';
// import invoiceActions from './invoices';

const startOrder = () => ({
  type: NEW_ORDER,
  status: ACTION_START,
});

const setAssigneeId = assigneeId => ({
  type: NEW_ORDER_SET_ASSIGNEE_ID,
  assigneeId,
});

const setItemQuantity = (storagetypeId, quantity) => ({
  type: NEW_ORDER_SET_ITEM_QUANTITY,
  storagetypeId,
  quantity,
});

const setMonths = months => ({
  type: NEW_ORDER_SET_MONTHS,
  months,
});

const setOrderDate = requestDate => ({
  type: NEW_ORDER_SET_DATE,
  requestDate,
});

const setOrderTimeSlot = timeSlot => ({
  type: NEW_ORDER_SET_TIMESLOT,
  timeSlot,
});

const setPaymentMode = paymentMode => ({
  type: NEW_ORDER_SET_PAYMENTMODE,
  paymentMode,
});

const setOrderAddress = address => ({
  type: NEW_ORDER_SET_ADDRESS,
  address,
});

const setOrderComment = comment => ({
  type: NEW_ORDER_SET_COMMENT,
  comment,
});

const setOrderName = name => ({
  type: NEW_ORDER_SET_NAME,
  name,
});

const setOrderEmail = email => ({
  type: NEW_ORDER_SET_EMAIL,
  email,
});

const setOrderPhone = phone => ({
  type: NEW_ORDER_SET_PHONE,
  phone,
});

const addCoupon = coupon => (dispatch, getState) => {
  const { config } = getState();
  const { countryId } = config;

  dispatch({
    type: NEW_ORDER_ADD_COUPON,
    apiCall: {
      method: 'POST',
      path: 'coupon-validate',
      params: { code: coupon, countryId },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const removeCoupon = () => ({
  type: NEW_ORDER_ADD_COUPON,
  status: ACTION_RESET,
});

const confirmOrder = cb => (dispatch, getState) => {
  const { newOrder, user, config } = getState();
  const { countryId } = config;

  const {
    items,
    months,
    requestDate,
    timeSlot,
    address,
    coupon,
    paymentMode,
    comment,
    name,
    phone,
    email,
    assigneeId,
  } = newOrder;

  if ((newOrder || {}).status === ACTION_WAITING) {
    return;
  }

  dispatch({
    type: NEW_ORDER_SUBMIT,
    apiCall: {
      method: 'POST',
      path: 'orders',
      params: {
        userId: user.userId,
        items,
        months,
        prepayMonths: months,
        pickupDate: requestDate,
        pickupTimeFrom: timeSlot.from,
        pickupTimeTo: timeSlot.to,
        deliveryDate: requestDate,
        deliveryTimeFrom: timeSlot.from,
        deliveryTimeTo: timeSlot.to,
        line1: address.line1,
        area: address.area,
        cityId: address.cityId,
        couponId: coupon,
        paymentMode,
        notes: comment,
        name,
        phone,
        email,
        countryId,
        assigneeId,
        source: 'webapp',
        tags: ['webapp'],
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
        if (typeof cb === 'function') {
          cb(err, res);
        }
      },
    },
  });
};

const resetOrder = () => ({
  type: NEW_ORDER,
  status: ACTION_RESET,
});

export default {
  startOrder,
  setAssigneeId,
  setItemQuantity,
  setMonths,
  setOrderAddress,
  setOrderDate,
  setOrderTimeSlot,
  setPaymentMode,
  addCoupon,
  removeCoupon,
  setOrderComment,
  setOrderName,
  setOrderEmail,
  setOrderPhone,
  confirmOrder,
  resetOrder,
};
