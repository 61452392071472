// export function moneyString(
//   amount: number,
//   currency: string,
//   skipEmptyFraction = false
// ) {
//   const text = formatMoney(amount, currency, skipEmptyFraction);
//   if (text) {
//     return `${currency} ${text}`;
//   }
//   return text;
// }

// export function formatMoney(
//   amount: number,
//   currency: string,
//   skipEmptyFraction = false
// ): string {
//   if (amount === null || amount === undefined) {
//     return "";
//   }

//   let money;

//   if (currency === "KWD") {
//     money = (amount / 10000).toFixed(3);
//     if (skipEmptyFraction) {
//       return money.replace(/\.000$/, "");
//     }
//     return money;
//   } else if (currency === "AED" || currency === "SAR") {
//     money = (amount / 10000).toFixed(2);
//     if (skipEmptyFraction) {
//       return money.replace(/\.00$/, "");
//     }
//     return money;
//   }

//   return "";
// }

// export function roundMoney(amount, currency) {
//   // In Boxit, money is stored as an integer scaled by a scale factor of 10000
//   // This function returns similarly scaled integers
//   // but the actual monetary value rounded of by 2 or 3 decimal places.

//   if (amount > 0) {
//     let divisor;

//     if (currency === "KWD") {
//       divisor = 10;
//     } else if (currency === "AED" || currency === "SAR") {
//       divisor = 100;
//     } else {
//       throw new Error(`Unknown currency: ${currency}`);
//     }

//     return Math.round(amount / divisor) * divisor;
//   }

//   return amount;
// }

export function moneyString(
  amount: number,
  currency: string,
  skipEmptyFraction = false
) {
  const text = formatMoney(amount, currency, skipEmptyFraction);
  if (text) {
    return `${currency} ${text}`;
  }
  return text;
}

export function formatMoney(
  amount: number,
  currency: string,
  skipEmptyFraction = false,
  freeServices = false

): string {
  //edited by alabady
  if ((amount === null || amount === undefined) && freeServices == false) { 
    return "";
  } else if ((amount == 0 ||amount === null || amount === undefined) && freeServices == true) {
    return "Free";
  }

  let money;

  if (currency === "KWD") {
    money = (amount / 10000).toFixed(3);
    if (skipEmptyFraction) {
      return money.replace(/\.000$/, "");
    }
    return money;
  } else if (currency === "AED" || currency === "SAR") {
    money = (amount / 10000).toFixed(2);
    if (skipEmptyFraction) {
      return money.replace(/\.00$/, "");
    }
    return money;
  }

  return "";
}

export function roundMoney(amount, currency) {
  // In Boxit, money is stored as an integer scaled by a scale factor of 10000
  // This function returns similarly scaled integers
  // but the actual monetary value rounded of by 2 or 3 decimal places.

  if (amount > 0) {
    let divisor;

    if (currency === "KWD") {
      divisor = 10;
    } else if (currency === "AED" || currency === "SAR") {
      divisor = 100;
    } else {
      throw new Error(`Unknown currency: ${currency}`);
    }

    return Math.round(amount / divisor) * divisor;
  }

  return amount;
}
