import { bindActionCreators } from 'redux';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import validator from 'validator';
import Link from '../components/Link';
import Button from '../components/Button';
import Loading from '../components/Loading';
import { userActions, appActions } from '../data/actions';
import { ACTION_SUCCESS } from '../data/constants';
type Props = {
  user: any;
  routeTo: any;
  urlParams: any;
  magicLinkSubmit: any;
  magicLinkRequest: any;
};
type State = {
  email: any;
};
class MagicLogin extends React.Component<Props, State> {
  static propTypes = {
    magicLinkRequest: PropTypes.func.isRequired,
    magicLinkSubmit: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    urlParams: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };
  }

  componentDidMount() {
    const { user, routeTo, urlParams, magicLinkSubmit } = this.props;
    if (user.isLoggedIn) {
      routeTo('profile');
    } else {
      const token = urlParams.token;
      if (token) {
        magicLinkSubmit(token, () => {
          routeTo('home');
        });
      }
    }
  }

  render() {
    const { user, routeTo, urlParams, magicLinkRequest } = this.props;
    const token = urlParams.token;
    const actionStatus =
      (token ? user.magicLinkSubmit : user.magicLinkRequest) || {};
    const isValid = validator.isEmail(this.state.email);

    return (
      <div>
        <div className="grid grid-cols-1 mx-auto md:w-1/2">
          <div className="">
            <div className="box center mt-10 lg:p-16  rounded shadow-md">
              <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">Magic Link</h2>
              {!token && (
                <div>
                  <div className="text-center">
                    <h5>Enter your email address</h5>
                    <h5>We will send you a magic link to login</h5>
                  </div>
                  <div className=" w-3/4 my-12 center">
                    <input
                      type="text"
                      className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="email"
                      value={this.state.email}
                      placeholder="Email"
                      onChange={e => {
                        this.setState({
                          email: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <Button
                    className="block center my-8 rounded-lg"
                    disabled={!isValid}
                    onClick={() => {
                      magicLinkRequest(this.state.email);
                      this.setState({ email: '' });
                    }}
                    text="Get Magic Link"
                    big
                  />
                </div>
              )}

              {token && !actionStatus.error && <Loading />}

              {actionStatus.error && (
                <div className="alert alert-danger m-b-0 m-t-10" role="alert">
                  {token && actionStatus.error === 'User not found.'
                    ? 'Sorry, this link has expired.'
                    : actionStatus.error}
                </div>
              )}
              {actionStatus.status === ACTION_SUCCESS && (
                <div className="alert alert-success m-b-0 m-t-10" role="alert">
                  {!token && (
                    <p>We have sent you an email with the login link</p>
                  )}
                  {token && (
                    <p>
                      You have succeffully loged in with the magic link&nbsp;
                      <Link onClick={() => routeTo('profile')}>Dashboard</Link>
                    </p>
                  )}
                </div>
              )}
              <hr className="mt-8 mb-4" />
              <div className="pb-4 text-center">
                <Link className="text-blue-600 " onClick={() => routeTo('login')}>
                  Back to Login Page
                </Link>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

// MagicLogin.propTypes = {
//     magicLinkRequest: PropTypes.func.isRequired,
//     magicLinkSubmit: PropTypes.func.isRequired,
//     routeTo: PropTypes.func.isRequired,
//     urlParams: PropTypes.object.isRequired,
//     user: PropTypes.object.isRequired,
// };

const MagicLoginContainer = connect(
  state => ({ user: (state as any).user }),
  dispatch => bindActionCreators({ ...userActions, ...appActions }, dispatch)
)(MagicLogin);

export default MagicLoginContainer;
