import moment from 'moment';

import { CONFIG_GET, CONFIG_GET_BUSY_SLOTS } from '../constants';
import errorHandleActions from '../actions/errorHandle';
/* eslint-disable */
const getConfig = (country, city = null) => dispatch => {
  dispatch({
    type: CONFIG_GET,
    apiCall: {
      method: 'GET',
      path: 'config',
      queryParams: {
        partnerId: 1 /* Boxit */,
        //countryId:country,
        ...(typeof city === 'string' && { city }),
        ...(typeof country === 'string' && { country }),
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const getBusyslots = (_, cb) => (dispatch, getState) => {
  const today = moment().format('YYYY-MM-DD');
  const { user } = getState();
  dispatch({
    type: CONFIG_GET_BUSY_SLOTS,
    apiCall: {
      method: 'GET',
      path: 'busyslots',
      queryParams: {
        'from[$gte]': today,
        countryId: user.user.countryId,
      },
      callback: (err, res) => {
        if (typeof cb === 'function') {
          cb(err, res);
        }
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

export default {
  getConfig,
  getBusyslots,
};
