/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import 'intl-tel-input';
import { City, City2Country } from '../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { userActions } from '../../data/actions';

type Props = {
  selectedAddress: any;
  cityId: any;
  onChange: any;
  addresses: any;
  title: any;
  getCurrentCityId: any;
  user: any;
  getCities: any;
  getUserAddresses: any;
  isAddressNew: any;
  setIsNewAddress: any;
};

type State = {
  currentAddress: any;
  nameInput: any;
  addressInput: any;
  areaInput: any;
};

class AddressForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      currentAddress: null,
      nameInput: '',
      addressInput: '',
      areaInput: '',
    };
    this.setAddressValue = this.setAddressValue.bind(this);
  }
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    cityId: PropTypes.string.isRequired,
    selectedAddress: PropTypes.object,
    addresses: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    getCurrentCityId: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    getCities: PropTypes.func.isRequired,
    getUserAddresses: PropTypes.func.isRequired,
    isAddressNew: PropTypes.bool.isRequired,
    setIsNewAddress: PropTypes.func.isRequired,
  };
  public static defaultProps = {
    selectedAddress: null,
    addresses: [],
    title: null,
  };

  componentDidMount() {
    const {
      selectedAddress,
      cityId,
      onChange,
      getCities,
      getUserAddresses,
    } = this.props;
    let address = selectedAddress;

    getCities();
    getUserAddresses();

    if (!address) {
      address = {};
    }
    if (!address.cityId) {
      address.cityId = cityId;
    }
    if (!address.name) {
      address.name = 'Home';
    }
    onChange(address);
    this.props.getCurrentCityId(cityId);
    //this.setAddressValue('cityId', undefined);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { onChange, selectedAddress, user } = this.props;
    if (
      this.props.isAddressNew !== prevProps.isAddressNew &&
      this.props.isAddressNew
    ) {
      this.setAddressValue('name', undefined);
      this.setAddressValue('cityId', undefined);
      this.setAddressValue('area', undefined);
      this.setAddressValue('line1', undefined);
    } else if (
      this.props.isAddressNew !== prevProps.isAddressNew &&
      !this.props.isAddressNew
    ) {
      let address = selectedAddress;
      address = {
        ...address,
        line1: address.area,
      };
      if (!address) {
        address = {};
      }
      onChange(address);
    }

    if (user.userAddresses !== prevProps.user.userAddresses) {
      onChange(user.userAddresses[0]);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedAddress, addresses, onChange } = this.props;

    if (
      selectedAddress &&
      !selectedAddress.id &&
      addresses !== nextProps.addresses &&
      nextProps.addresses.length
    ) {
      onChange(nextProps.addresses[0]);
    }
  }

  setAddressValue(key, value) {
    const { selectedAddress, onChange } = this.props;
    const address = {
      ...selectedAddress,
      [key]: value,
    };
    /*if (!address.name) {
      address.name = 'Home';
    }*/
    onChange(address);
  }

  render() {
    const { cityId, selectedAddress, title, onChange, user } = this.props;
    const country = City2Country[cityId];
    let addresses = user.userAddresses || [];

    /*if (!addresses.length) {
      addresses = [
        {
          cityId,
          name: 'Home',
        },
      ];
    }*/

    return (
      <div>
        {title && <h3 className="sub-title m-b-20">{title}</h3>}

        <div className="form-group grid grid-cols-12">
          <label htmlFor="name " className="col-span-12 pt-3">
            Address Name
          </label>
          {!this.props.isAddressNew && (
            <select
              id="name"
              className="form-control col-span-12 p-3 focus:ring-indigo-500 focus:border-indigo-500 border relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
              value={(selectedAddress && selectedAddress.id) || undefined}
              onChange={e => {
                console.log(addresses);
                onChange(addresses.find(i => i.id == e.target.value));
              }}
            >
              {user.userAddresses &&
                user.userAddresses
                  .map(address => {
                    return { key: address.name, value: address.id };
                  })
                  .map(c => (
                    <option
                      key={c.key}
                      // @ts-ignore
                      name="name"
                      value={c.value}
                    >
                      {c.key}
                    </option>
                  ))}
            </select>
          )}

          {this.props.isAddressNew && (
            <input
              type="text"
              className="form-control col-span-12 p-3 focus:ring-indigo-500 focus:border-indigo-500 border relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
              id="name"
              //value={selectedAddress ? selectedAddress.name : ''}
              value={this.state.nameInput}
              placeholder="Address name"
              onChange={e => {
                if (this.props.isAddressNew) {
                  this.setState({ nameInput: e.target.value });
                }
                this.setAddressValue('name', e.target.value);
              }}
            />
          )}
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginTop: '.5rem',
              gap: '1rem',
            }}
            className="col-span-12"
          >
            <label>Enter new address</label>
            <input
              type="checkbox"
              style={{ width: '1rem', height: '1rem' }}
              onChange={e => {
                this.props.setIsNewAddress(!this.props.isAddressNew);
              }}
            />
          </div>
        </div>

        <div className="form-group grid grid-cols-12">
          <label htmlFor="addressCity" className="col-span-12 pt-3">
            City
          </label>
          <select
            id="addressCity"
            className="form-control col-span-12 p-3 focus:ring-indigo-500 focus:border-indigo-500 border relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
            value={selectedAddress ? selectedAddress.cityId : ''}
            disabled={!this.props.isAddressNew}
            onChange={e => {
              if (e.target.value !== 'Select City') {
                this.setAddressValue('cityId', e.target.value);
                this.props.getCurrentCityId(e.target.value);
              }
            }}
          >
            {user.cities &&
              user.cities.map(c => (
                <option
                  key={c.key}
                  // @ts-ignore
                  name="addressCity"
                  value={c.value}
                >
                  {c.key}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group grid grid-cols-12">
          <label htmlFor="addressArea" className="col-span-12 pt-3">
            Area
          </label>
          <input
            type="text"
            className="form-control col-span-12 p-3 focus:ring-indigo-500 focus:border-indigo-500 border relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
            id="addressArea"
            disabled={!this.props.isAddressNew}
            value={
              selectedAddress && !this.props.isAddressNew
                ? selectedAddress.area
                : this.state.areaInput
            }
            placeholder="Area"
            onChange={e => {
              if (this.props.isAddressNew) {
                this.setState({ areaInput: e.target.value });
              }
              this.setAddressValue('area', e.target.value);
            }}
          />
        </div>
        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '.5rem',
          }}
        ></div>

        <div className="form-group m-b-0 grid grid-cols-12">
          <label htmlFor="addressAddress" className="col-span-12 pt-3">
            Address
          </label>
          <input
            type="text"
            className="form-control col-span-12 p-3 focus:ring-indigo-500 focus:border-indigo-500 border relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
            id="addressAddress"
            disabled={!this.props.isAddressNew}
            value={
              selectedAddress && !this.props.isAddressNew
                ? selectedAddress.line1
                : this.state.addressInput
            }
            placeholder="Address"
            onChange={e => {
              if (this.props.isAddressNew) {
                this.setState({ addressInput: e.target.value });
              }
              this.setAddressValue('line1', e.target.value);
            }}
          />
        </div>
      </div>
    );
  }
}

const AddressFormContainer = connect(
  state => ({
    user: (state as any).user,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...userActions,
      },
      dispatch
    )
)(AddressForm);

export default AddressFormContainer;
