import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PaymentMode } from '../constants';
import Loading from '../components/Loading';
import { ACTION_START } from '../data/constants';
import { newPaymentActions, appActions } from '../data/actions';
type Props = {
  newPayment: any;
  routeTo: any;
  queryParams: any;
  urlParams: any;
  processPayment: any;
};
class PaymentProcess extends React.Component<Props> {
  static propTypes = {
    processPayment: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    urlParams: PropTypes.object.isRequired,
    queryParams: PropTypes.object.isRequired,
    newPayment: PropTypes.object.isRequired,
  };
  componentDidMount() {
    const {
      newPayment,
      routeTo,
      queryParams,
      urlParams,
      processPayment,
    } = this.props;
    const mode = urlParams.mode;
    let paymentId;
    if (mode === PaymentMode.tap) {
      paymentId = queryParams.trackid;
    }
    if (mode === PaymentMode.payfort) {
      paymentId = queryParams.merchant_reference;
    }

    if (!paymentId) {
      routeTo('home');
    }

    if (
      newPayment.processStatus.status &&
      newPayment.processStatus.status !== ACTION_START
    ) {
      routeTo('payment-status', { paymentId });
      return;
    }
    processPayment(mode, queryParams, () =>
      routeTo('payment-status', { paymentId })
    );
  }

  render() {
    return (
      <div>
        <div className="row">
          <h2 className="sub-title m-t-20 m-b-20">Processing...</h2>
          <div className="grid grid-cols-1 mx-auto md:w-1/2">
            <div className="box center text-center p-20">
              <Loading />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// PaymentProcess.propTypes = {
//   processPayment: PropTypes.func.isRequired,
//   routeTo: PropTypes.func.isRequired,
//   urlParams: PropTypes.object.isRequired,
//   queryParams: PropTypes.object.isRequired,
//   newPayment: PropTypes.object.isRequired,
// };

const PaymentProcessContainer = connect(
  state => ({
    newPayment: (state as any).newPayment,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...newPaymentActions,
        ...appActions,
      },
      dispatch
    )
)(PaymentProcess);

export default PaymentProcessContainer;
