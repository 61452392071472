import { CONFIG_GET, ACTION_SUCCESS } from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CONFIG_GET:
      if (action.status === ACTION_SUCCESS) {
        return action.data.data || {};
      }
      return state;

    default:
      return state;
  }
}
