export const ACTION_START = 'START';
export const ACTION_RESET = 'RESET';
export const ACTION_SUCCESS = 'SUCCESS';
export const ACTION_FAILED = 'FAILED';
export const ACTION_WAITING = 'WAITING';

export const CHARGES_GET = 'CHARGES_GET';
export const CONFIG_GET = 'CONFIG_GET';
export const CONFIG_GET_BUSY_SLOTS = 'CONFIG_GET_BUSY_SLOTS';

export const ROUTE_TO = 'ROUTE_TO';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SIGNUP = 'SIGNUP';
export const FB_LOGIN = 'FB_LOGIN';
export const USER_SET = 'USER_SET';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUBMIT = 'RESET_PASSWORD_SUBMIT';
export const MAGIC_LINK_REQUEST = 'MAGIC_LINK_REQUEST';
export const MAGIC_LINK_SUBMIT = 'MAGIC_LINK_SUBMIT';

export const USER_GET = 'USER_GET';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_CHANGE_PASWORD = 'USER_CHANGE_PASWORD';

export const STORAGES_GET = 'STORAGES_GET';
export const STORAGE_UPDATE = 'STORAGE_UPDATE';

export const PHOTOS_GET = 'PHOTOS_GET';
export const ADD_PHOTOS = 'ADD_PHOTOS';
export const DELETE_PHOTO = 'DELETE_PHOTO';
export const DEFAULT_PHOTO = 'DEFAULT_PHOTO';

export const NEW_ORDER = 'NEW_ORDER';
export const NEW_ORDER_SET_ASSIGNEE_ID = 'NEW_ORDER_SET_ASSIGNEE_ID';
export const NEW_ORDER_SET_ITEM_QUANTITY = 'NEW_ORDER_SET_ITEM_QUANTITY';
export const NEW_ORDER_SET_MONTHS = 'NEW_ORDER_SET_MONTHS';
export const NEW_ORDER_SET_DATE = 'NEW_ORDER_SET_DATE';
export const NEW_ORDER_SET_TIMESLOT = 'NEW_ORDER_SET_TIMESLOT';
export const NEW_ORDER_SET_PAYMENTMODE = 'NEW_ORDER_SET_PAYMENTMODE';
export const NEW_ORDER_SET_ADDRESS = 'NEW_ORDER_SET_ADDRESS';
export const NEW_ORDER_ADD_COUPON = 'NEW_ORDER_ADD_COUPON';
export const NEW_ORDER_CONFIRM = 'NEW_ORDER_CONFIRM';
export const NEW_ORDER_STATUS = 'NEW_ORDER_STATUS';
export const NEW_ORDER_RESET = 'NEW_ORDER_RESET';
export const NEW_ORDER_SUBMIT = 'NEW_ORDER_SUBMIT';
export const NEW_ORDER_SET_COMMENT = 'NEW_ORDER_SET_COMMENT';
export const NEW_ORDER_SET_NAME = 'NEW_ORDER_SET_NAME';
export const NEW_ORDER_SET_EMAIL = 'NEW_ORDER_SET_EMAIL';
export const NEW_ORDER_SET_PHONE = 'NEW_ORDER_SET_PHONE';

export const REQUEST_GET = 'REQUEST_GET';
export const REQUESTS_GET = 'REQUESTS_GET';
export const REQUESTS_UPDATE = 'REQUESTS_UPDATE';
export const NEW_REQUEST = 'NEW_REQUEST';
export const NEW_REQUEST_SET_TYPE = 'NEW_REQUEST_SET_TYPE';
export const NEW_REQUEST_SET_STORAGEIDS = 'NEW_REQUEST_SET_STORAGEIDS';
export const NEW_REQUEST_SET_DATE = 'NEW_REQUEST_SET_DATE';
export const NEW_REQUEST_SET_TIMESLOT = 'NEW_REQUEST_SET_TIMESLOT';
export const NEW_REQUEST_SET_ADDRESS = 'NEW_REQUEST_SET_ADDRESS';
export const NEW_REQUEST_RESET = 'NEW_REQUEST_RESET';
export const NEW_REQUEST_SUBMIT = 'NEW_REQUEST_SUBMIT';

export const INVOICES_GET = 'INVOICES_GET';

export const NEW_PAYMENT = 'NEW_PAYMENT';
export const PAYMENT_GET = 'PAYMENT_GET';
export const NEW_PAYMENT_CREATE = 'NEW_PAYMENT_CREATE';
export const NEW_PAYMENT_COMPUTE_HASH = 'NEW_PAYMENT_COMPUTE_HASH';
export const NEW_PAYMENT_PROCESS = 'NEW_PAYMENT_PROCESS';

export const CARDS_GET = 'CARDS_GET';
export const CARD_GET = 'CARD_GET';
export const DELETE_CARD = 'DELETE_CARD';
export const NEW_CARD = 'NEW_CARD';
export const NEW_CARD_CREATE = 'NEW_CARD_CREATE';
export const NEW_CARD_COMPUTE_HASH = 'NEW_CARD_COMPUTE_HASH';

export const ADDRESS_GET = 'ADDRESS_GET';
export const ADDRESSES_GET = 'ADDRESSES_GET';
export const ADDRESSES_ADD = 'ADDRESSES_ADD';
export const ADDRESSES_UPDATE = 'ADDRESSES_UPDATE';
export const DEFAULT_CARD = 'DEFAULT_CARD';

export const ORDER_UPDATE = 'ORDER_UPDATE';

export const COMPUTE_HASH = 'COMPUTE_HASH';

export const ERROR_HANDLE = 'ERROR_HANDLE';

export const GET_SURVEY = 'GET_SURVEY';
export const ADD_SURVEY_PHOTOS = 'ADD_SURVEY_PHOTOS';
export const PHOTOS_SURVEY_GET = 'PHOTOS_SURVEY_GET';
export const DELETE_SURVEY_PHOTO = 'DELETE_SURVEY_PHOTO';

export const CITIES_GET = 'CITIES_GET';
export const ADDRESSES_USER_GET = 'ADDRESSES_USER_GET';

export const STORAGES_GET_PARTS = 'STORAGES_GET_PARTS';
export const PHOTOS_GET_PARTS = 'PHOTOS_GET_PARTS';
export const STORAGES_GET_PARTS_NUMBER = 'STORAGES_GET_PARTS_NUMBER';
export const STORAGES_GET_NUMBER = 'STORAGES_GET_NUMBER';
export const STORAGES_GET_STATS = 'STORAGES_GET_STATS';
export const STORAGES_ALL_GET = 'STORAGES_ALL_GET';

export const STORAGES_GET_DELIVERED_NUMBER = 'STORAGES_GET_DELIVERED_NUMBER';

export const CURRENT_STORAGES_GET = 'CURRENT_STORAGES_GET';

export const CITIES_ALL_GET = 'CITIES_ALL_GET';
