import React from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import $ from 'jquery';
import swal from 'sweetalert2';
import cx from 'classnames';
import { StorageType } from '../../constants';
import s from './styles.module.scss';
import Button from '../../components/Button';
import imgBoxit from './images/boxit.png';
import imgShoe from './images/shoe.jpg';
import imgTee from './images/tee.jpg';
import imgSweater from './images/sweater.jpg';
import OrderQuantity from '../OrderQuantity';
// import { formatMoney } from '../../core/utils/money';

import OrderCart from '../OrderCart';

// eslint-disable-next-line react/prefer-stateless-function
class BBInfo extends React.PureComponent {
  render() {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-5">
          <img className="p-5" src={imgBoxit} alt="Boxit Box" />
          <br />
          <h4>Box Size: 60x40x38 cm</h4>
        </div>
        <div className="col-xs-12 col-sm-7 text-left">
          <div>
            <i
              className="fa fa-check p-5 green icon-small"
              aria-hidden="true"
            />
            <span>High quality plastic boxes.</span>
          </div>
          <div>
            <i
              className="fa fa-check p-5 green icon-small"
              aria-hidden="true"
            />
            <span>Zip ties to lock the boxes.</span>
          </div>

          <h4>Each box can store</h4>
          <div className="row">
            <div className="col-xs-12 col-sm-4 text-center">
              <img width="60" src={imgShoe} alt="Shoes" />
              <p>15- 20 pairs of Shoes</p>
            </div>
            <div className="col-xs-12 col-sm-4 text-center">
              <img width="60" src={imgTee} alt="Tee" />
              <p>35 - 45 Shirts/T-shirts</p>
            </div>
            <div className="col-xs-12 col-sm-4 text-center">
              <img width="60" src={imgSweater} alt="Sweater" />
              <p>13 - 15 Sweaters</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

type Props = {
  items: any;
  currency: string;
  comment: string;
  isHeaderContinueDisabled: boolean;
  storagetypes: any[];
  setItemQuantity: () => void;
  setOrderComment: (string) => void;
  onContinueClick: () => void;
};

// eslint-disable-next-line react/prefer-stateless-function
class OrderItems extends React.Component<Props> {
  static propTypes = {
    setItemQuantity: PropTypes.func.isRequired,
    setOrderComment: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    storagetypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    currency: PropTypes.string.isRequired,
    comment: PropTypes.string,
    onContinueClick: PropTypes.func,
    isHeaderContinueDisabled: PropTypes.bool.isRequired,
  };

  public static defaultProps = {
    comment: '',
  };

  componentDidMount() {
    ($('.item-flip') as any).flip({ trigger: 'manual' });
  }

  renderItemTypeRow(type) {
    const { storagetypes, items, setItemQuantity } = this.props;
    const sizes = type.sizes || [];
    const isBoxitBox = (sizes[0] || {}).code === StorageType.BB;
    const isSingleSize = sizes.length === 1;
    let singleQuantity = 0;
    let singleStorageType;
    let singleItem;
    // let defaultRent = 0;
    let totalQuantity = 0;
    const elementId = `type_${type.name}`.split(' ').join('_');
    if (isSingleSize) {
      singleItem =
        (items || []).find(it => it.storagetypeId === sizes[0].storagetypeId) ||
        {};
      singleQuantity = (singleItem && singleItem.quantity) || 0;
      singleStorageType =
        storagetypes.find(it => it.id === sizes[0].storagetypeId) || {};
      // defaultRent = `${currency} ${formatMoney(singleStorageType.rent, currency, true)} /mo`;
    } else {
      // const firstStorageType = storagetypes.find(it => it.id === sizes[0].storagetypeId) || {};
      // defaultRent = `${currency} ${formatMoney(firstStorageType.rent, currency, true)}+ /mo`;
      sizes.forEach(sz => {
        const sItem =
          (items || []).find(it => it.storagetypeId === sz.storagetypeId) || {};
        totalQuantity += (sItem && sItem.quantity) || 0;
      });
      if (totalQuantity === 0) {
        ($(`#${elementId} .item-flip`) as any).flip(false);
      } else {
        ($(`#${elementId} .item-flip`) as any).flip(true);
      }
    }

    return (
      <div id={elementId} className={s.itemType}>
        {isBoxitBox && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <span
            className={s.itemDetailButton}
            onClick={() => {
              swal.fire({
                html: ReactDOMServer.renderToString(<BBInfo />),
              });
            }}
          >
            <i className="fa fa-info-circle" aria-hidden="true" />
          </span>
        )}
        <div className={s.itemName}>{type.name}</div>
        <div className="item-flip">
          <div className="front">
            {/* <div className={s.itemPrice}>{defaultRent}</div> */}
            {type.image && (
              // eslint-disable-next-line
              <img
                className={s.itemImage}
                alt={type.name}
                src={require(`./images/${type.image}`)}
              />
            )}
            {isSingleSize && (
              <OrderQuantity
                storagetypeId={singleStorageType.id}
                quantity={singleQuantity}
                setItemQuantity={setItemQuantity}
              />
            )}
            {!isSingleSize && (
              <div className={s.itemAddContainer}>
                <Button
                  className={s.itemAdd}
                  onClick={e => {
                    ($(e.target).closest(
                      `.${s.itemType} .item-flip`
                    ) as any).flip(true);
                  }}
                  filled={false}
                >
                  Add
                </Button>
              </div>
            )}
          </div>
          <div className="back">
            {(sizes || []).map(size => {
              const storageType =
                (storagetypes || []).find(it => it.id === size.storagetypeId) ||
                {};
              const item =
                (items || []).find(
                  it => it.storagetypeId === size.storagetypeId
                ) || {};
              const quantity = item.quantity || 0;

              return (
                <div key={size.storagetypeId} className={s.itemSizeContainer}>
                  <div className={s.itemSizeName}>
                    {!isSingleSize && <span>{size.name}</span>}
                    {/* <div className={s.itemSizePrice}>
                    {`${currency} ${formatMoney(storageType.rent, currency, true)}/mo`}
                    </div> */}
                  </div>
                  {!isSingleSize && (
                    <OrderQuantity
                      storagetypeId={storageType.id}
                      quantity={quantity}
                      setItemQuantity={setItemQuantity}
                      pullRight
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      storagetypes,
      comment,
      setOrderComment,
      onContinueClick,
      setItemQuantity,
      isHeaderContinueDisabled,
    } = this.props;

    let groups = [
      {
        name: '',
        types: [
          {
            name: 'Boxit Box',
            image: 'boxit.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'BB',
              },
            ],
          },
          {
            name: 'Box',
            image: 'carton.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'BOX_S',
              },
              {
                name: 'Medium',
                storagetypeId: 'BOX_M',
              },
              {
                name: 'Large',
                storagetypeId: 'BOX_L',
              },
            ],
          },
          {
            name: 'Suitcase',
            image: 'suitcase.png',
            sizes: [
              {
                name: 'Carry on',
                storagetypeId: 'SUITCASE_S',
              },
              {
                name: '18 - 25kg',
                storagetypeId: 'SUITCASE_M',
              },
              {
                name: '25kg+',
                storagetypeId: 'SUITCASE_L',
              },
            ],
          },
        ],
      },
      {
        name: 'Living Room',
        types: [
          {
            name: 'Television',
            image: 'tv.png',
            sizes: [
              {
                name: '32" - 40"',
                storagetypeId: 'TV_S',
              },
              {
                name: '41" - 54"',
                storagetypeId: 'TV_M',
              },
              {
                name: '55" & above',
                storagetypeId: 'TV_L',
              },
            ],
          },
          {
            name: 'TV Stand',
            image: 'tv-stand.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'TV_STAND_S',
              },
              {
                name: 'Medium',
                storagetypeId: 'TV_STAND_M',
              },
              {
                name: 'Large',
                storagetypeId: 'TV_STAND_L',
              },
            ],
          },
          {
            name: 'Sofa',
            image: 'sofa.png',
            sizes: [
              {
                name: '1 Seater',
                storagetypeId: 'SOFA_1S',
              },
              {
                name: '2 Seater',
                storagetypeId: 'SOFA_2S',
              },
              {
                name: '3 Seater',
                storagetypeId: 'SOFA_3S',
              },
              {
                name: '4 Seater',
                storagetypeId: 'SOFA_4S',
              },
            ],
          },
          {
            name: 'Sofa cum Bed',
            image: 'sofa-bed.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'SOFA_BED',
              },
            ],
          },
          {
            name: 'Shoe Rack',
            image: 'shoe.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'SHOE_RACK_S',
              },
              {
                name: 'Medium',
                storagetypeId: 'SHOE_RACK_M',
              },
              {
                name: 'Large',
                storagetypeId: 'SHOE_RACK_L',
              },
            ],
          },
          {
            name: 'Coffee Table',
            image: 'coffee-table.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'COFFEE_TABLE',
              },
              {
                name: 'Large',
                storagetypeId: 'COFFEE_TABLE_L',
              },
            ],
          },
          {
            name: 'Center Table',
            image: 'center-table.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'CENTER_TABLE',
              },
              {
                name: 'Large',
                storagetypeId: 'CENTER_TABLE_L',
              },
              {
                name: 'Glass',
                storagetypeId: 'CENTER_TABLE_GLASS',
              },
            ],
          },
          {
            name: 'Side Table',
            image: 'side-table.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'SIDE_TABLE',
              },
            ],
          },
          {
            name: 'Chair',
            image: 'chair.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'CHAIR',
              },
              {
                name: 'Large',
                storagetypeId: 'CHAIR_L',
              },
            ],
          },
          {
            name: 'Arm Chair',
            image: 'armchair.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'ARM_CHAIR',
              },
            ],
          },
          {
            name: 'Foot Rest',
            image: 'foot-rest.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'FOOT_REST',
              },
            ],
          },
        ],
      },
      {
        name: 'Bedroom',
        types: [
          {
            name: 'Wardrobe',
            image: 'cupboard.png',
            sizes: [
              {
                name: '2 Door',
                storagetypeId: 'WARDROBE_2D',
              },
              {
                name: '3 Door',
                storagetypeId: 'WARDROBE_3D',
              },
              {
                name: '4 Door',
                storagetypeId: 'WARDROBE_4D',
              },
            ],
          },
          {
            name: 'Dressing Table',
            image: 'dressing.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'DRESSING_TABLE_S',
              },
              {
                name: 'Medium',
                storagetypeId: 'DRESSING_TABLE_M',
              },
              {
                name: 'Large',
                storagetypeId: 'DRESSING_TABLE_L',
              },
            ],
          },
          {
            name: 'Bed Cot',
            image: 'bed.png',
            sizes: [
              {
                name: 'Single',
                storagetypeId: 'BED_SINGLE',
              },
              {
                name: 'Double',
                storagetypeId: 'BED_DOUBLE',
              },
              {
                name: 'Queen',
                storagetypeId: 'BED_QUEEN',
              },
              {
                name: 'King',
                storagetypeId: 'BED_KING',
              },
              {
                name: 'Bunk',
                storagetypeId: 'BED_BUNK',
              },
            ],
          },
          {
            name: 'Mattress',
            image: 'mattress.png',
            sizes: [
              {
                name: 'Single',
                storagetypeId: 'MATTRESS_SINGLE',
              },
              {
                name: 'Double',
                storagetypeId: 'MATTRESS_DOUBLE',
              },
              {
                name: 'Queen',
                storagetypeId: 'MATTRESS_QUEEN',
              },
              {
                name: 'King',
                storagetypeId: 'MATTRESS_KING',
              },
              {
                name: 'Heavy',
                storagetypeId: 'MATTRESS_HEAVY',
              },
            ],
          },
          {
            name: 'Drawers',
            image: 'drawers.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'DRAWERS_S',
              },
              {
                name: 'Medium',
                storagetypeId: 'DRAWERS_M',
              },
              {
                name: 'Large',
                storagetypeId: 'DRAWERS_L',
              },
            ],
          },
          {
            name: 'Bedside Table',
            image: 'bedside.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'BEDSIDE_TABLE',
              },
            ],
          },
          {
            name: 'Mirror',
            image: 'mirror.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'MIRROR',
              },
            ],
          },
          {
            name: 'Night Stand',
            image: 'night-stand.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'NIGHT_STAND',
              },
            ],
          },
          {
            name: 'Curtain',
            image: 'curtain.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'CURTAIN',
              },
            ],
          },
        ],
      },
      {
        name: 'Kitchen',
        types: [
          {
            name: 'Kitchen Table',
            image: 'kitchen-table.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'KITCHEN_TABLE_S',
              },
              {
                name: 'Large',
                storagetypeId: 'KITCHEN_TABLE_L',
              },
            ],
          },
          {
            name: 'Fridge',
            image: 'fridge.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'FRIDGE_S',
              },
              {
                name: 'Medium',
                storagetypeId: 'FRIDGE_M',
              },
              {
                name: 'Large',
                storagetypeId: 'FRIDGE_L',
              },
            ],
          },
          {
            name: 'Washing Machine',
            image: 'washing.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'WASHING_MACHINE',
              },
            ],
          },
          {
            name: 'Microwave',
            image: 'microwave.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'MICROWAVE_S',
              },
              {
                name: 'Large',
                storagetypeId: 'MICROWAVE_L',
              },
            ],
          },
          {
            name: 'Cooking Range',
            image: 'cooker.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'COOKER',
              },
            ],
          },
          {
            name: 'Dish Washer',
            image: 'dish-washer.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'DISH_WASHER',
              },
            ],
          },
          {
            name: 'Water Dispenser',
            image: 'water-dispenser.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'WATER_DISPENSER',
              },
            ],
          },
        ],
      },
      {
        name: 'Dining',
        types: [
          {
            name: 'Dining Table',
            image: 'dining.png',
            sizes: [
              {
                name: '4 Seater',
                storagetypeId: 'DINING_TABLE_4S',
              },
              {
                name: '6 Seater',
                storagetypeId: 'DINING_TABLE_6S',
              },
              {
                name: '6 Seater Large',
                storagetypeId: 'DINING_TABLE_6S_L',
              },
              {
                name: '8 Seater',
                storagetypeId: 'DINING_TABLE_8S',
              },
            ],
          },
          {
            name: 'Dining Chair',
            image: 'dining-chair.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'DINING_CHAIR',
              },
            ],
          },
          {
            name: 'Side Server',
            image: 'side-server.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'SIDE_SERVER_S',
              },
              {
                name: 'Medium',
                storagetypeId: 'SIDE_SERVER_M',
              },
              {
                name: 'Large',
                storagetypeId: 'SIDE_SERVER_L',
              },
            ],
          },
          {
            name: 'Bar',
            image: 'bar.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'BAR',
              },
              {
                name: 'Large',
                storagetypeId: 'BAR_L',
              },
            ],
          },
          {
            name: 'Bar Stool',
            image: 'bar-stool.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'BAR_STOOL',
              },
            ],
          },
        ],
      },
      {
        name: 'Study',
        types: [
          {
            name: 'Desk',
            image: 'desk.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'DESK',
              },
            ],
          },
          {
            name: 'Book Shelf',
            image: 'book-shelf.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'BOOK_SHELF_S',
              },
              {
                name: 'Medium',
                storagetypeId: 'BOOK_SHELF_M',
              },
              {
                name: 'Large',
                storagetypeId: 'BOOK_SHELF_L',
              },
            ],
          },
          {
            name: 'Computer Table',
            image: 'computer-table.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'COMPUTER_TABLE_S',
              },
              {
                name: 'Medium',
                storagetypeId: 'COMPUTER_TABLE_M',
              },
              {
                name: 'Large',
                storagetypeId: 'COMPUTER_TABLE_L',
              },
            ],
          },
        ],
      },
      {
        name: 'Others',
        types: [
          {
            name: 'Bicycle',
            image: 'bicycle.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'BICYCLE',
              },
            ],
          },
          {
            name: 'Baby Stroller',
            image: 'stroller.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'STROLLER',
              },
            ],
          },
          {
            name: 'Dryer Rack',
            image: 'dryer-rack.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'CLOTHES_DRYER_RACK',
              },
            ],
          },
          {
            name: 'Cloth Hanger',
            image: 'cloth-hanger.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'CLOTHES_HANGER_STAND',
              },
            ],
          },
          {
            name: 'Treadmill',
            image: 'treadmill.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'TREADMILL',
              },
            ],
          },
          {
            name: 'Exercise Machine',
            image: 'exercise.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'EXCERCISE_MACHINE',
              },
            ],
          },
          {
            name: 'Fan',
            image: 'fan.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'FAN',
              },
            ],
          },
          {
            name: 'BBQ Grill',
            image: 'grill.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'BBQ_GRILL',
              },
            ],
          },
          {
            name: 'Carpet',
            image: 'carpet.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'CARPET_S',
              },
              {
                name: 'Medium',
                storagetypeId: 'CARPET_M',
              },
              {
                name: 'Large',
                storagetypeId: 'CARPET_L',
              },
            ],
          },
          {
            name: 'Vacuum Cleaner',
            image: 'vacuum-cleaner.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'VACUUM_CLEANER',
              },
            ],
          },
          {
            name: 'Coffee Machine',
            image: 'coffee-machine.png',
            sizes: [
              {
                name: 'Regular',
                storagetypeId: 'COFEE_MACHINE',
              },
            ],
          },
          {
            name: 'Christmas Tree',
            image: 'christmas-tree.png',
            sizes: [
              {
                name: 'Small',
                storagetypeId: 'CHRISTMAS_TREE_S',
              },
              {
                name: 'Large',
                storagetypeId: 'CHRISTMAS_TREE_L',
              },
            ],
          },
        ],
      },
    ];

    // Remove types/sizes not in the config
    groups = groups
      .map(g => {
        const types = (g.types || [])
          .map(t => {
            const sizes = (t.sizes || []).filter(
              sz => !!storagetypes.find(st => st.id === sz.storagetypeId)
            );
            return sizes.length ? { ...t, sizes } : null;
          })
          .filter(t => !!t);
        return types.length ? { ...g, types } : null;
      })
      .filter(g => !!g);

    return (
      <div className="grid grid-cols-12">
        <div className="grid grid-cols-12 col-span-12 md:col-span-8 m-4">
          {groups.length === 1
            ? [
                <h3
                  key="title1"
                  className={`${s.itemGroupTitle} center m-b-20`}
                >
                  Boxes
                </h3>,
                <div
                  key={groups[0].types[0].name}
                  className={`${s.itemTypeWrap} box`}
                >
                  {this.renderItemTypeRow(groups[0].types[0])}
                </div>,
              ]
            : [
                groups.map((group, i) => (
                  <div
                    key={group.types[0].name}
                    id={`group_${i}`}
                    className={`row ${s.itemGroup}`}
                  >
                    {group.name && (
                      <h3 className={s.itemGroupTitle}>{group.name}</h3>
                    )}
                    {(group.types || []).map(type => (
                      <div
                        key={type.name}
                        className={`${s.itemTypeContainer}  col-span-3`}
                      >
                        <div className={`${s.itemTypeWrap} box`}>
                          {this.renderItemTypeRow(type)}
                        </div>
                      </div>
                    ))}
                  </div>
                )),
              ]}

          <div className="grid grid-cols-12 col-span-12 form-group">
            <hr className="m-v-10" />
            <label className="p-5 col-span-12" htmlFor="email">
              Item not listed above? Special Requests?
            </label>
            <textarea
              className={cx('form-control col-span-12 p-3', s.verticalTextarea)}
              id="email"
              value={comment}
              placeholder="Comments"
              onChange={e => {
                setOrderComment(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-span-4 hidden md:block p-4">
          <OrderCart
            onContinueClick={onContinueClick}
            setItemQuantity={setItemQuantity}
            isHeaderContinueDisabled={isHeaderContinueDisabled}
          />
        </div>
      </div>
    );
  }
}

export default OrderItems;
