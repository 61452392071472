import { bindActionCreators } from 'redux';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import validator from 'validator';
import Link from '../components/Link';
import Button from '../components/Button';
import { userActions, appActions } from '../data/actions';
import { ACTION_SUCCESS } from '../data/constants';
import Loading from '../components/Loading';
type Props = {
  user: any;
  routeTo: any;
  resetPasswordRequest: any;
  resetPasswordSubmit: any;
  urlParams: any;
};
type State = {
  email: any;
  password: any;
  timeoutInterval?: any;
  timeout?: any;
  loading: boolean;
};
class ResetPassword extends React.Component<Props, State> {
  static propTypes = {
    resetPasswordRequest: PropTypes.func.isRequired,
    resetPasswordSubmit: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    urlParams: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      loading: false,
    };
  }

  componentDidMount() {
    const { user, routeTo } = this.props;
    if (user.isLoggedIn) {
      routeTo('profile');
    }
  }

  render() {
    const {
      user,
      routeTo,
      resetPasswordRequest,
      resetPasswordSubmit,
      urlParams,
    } = this.props;
    const token = urlParams.token;
    const actionStatus =
      (token ? user.resetPasswordSubmit : user.resetPasswordRequest) || {};
    const isValid = validator.isEmail(this.state.email) || this.state.password;

    return (
      <div>
        <div className="grid grid-cols-1 mx-auto md:w-1/2">
          <div className="">
            <div className="box center mt-10 lg:p-16  rounded shadow-md">
              {token && (
                <div>
                  <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">
                    Set New Password
                  </h2>
                  <p className="text-center">Enter your new password</p>
                  <div className="w-3/4 my-12 center">
                    <input
                      type="password"
                      className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="password"
                      value={this.state.password}
                      placeholder="Password"
                      onChange={e => {
                        this.setState({
                          password: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              )}

              {!token && (
                <div>
                  <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">
                    Reset Password
                  </h2>
                  <p className="text-center">
                    Enter the email address associated with your account. <br />
                    We will send you a password reset link.
                  </p>
                  <div className="w-3/4 my-12 center">
                    <input
                      type="text"
                      className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="email"
                      value={this.state.email}
                      placeholder="Email"
                      onChange={e => {
                        this.setState({
                          email: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {this.state.loading && <Loading />}

              <Button
                className="block center my-12"
                // loading={!error && signupStatus.status === ACTION_START}
                disabled={!isValid || this.state.timeout || this.state.loading}
                onClick={() => {
                  if (token) {
                    this.setState({
                      loading: true,
                    });
                    resetPasswordSubmit(token, this.state.password);
                  } else {
                    this.setState({
                      timeout: 60,
                      timeoutInterval: setInterval(() => {
                        if (this.state.timeout < 2) {
                          clearInterval(this.state.timeoutInterval);
                          this.setState({ timeout: null });
                        } else {
                          this.setState({ timeout: this.state.timeout - 1 });
                        }
                      }, 1000),
                    });
                    resetPasswordRequest(this.state.email);
                  }
                }}
                text="Submit"
                big
              />
              {this.state.timeout && (
                <h3>
                  You can send another reset email in {this.state.timeout}{' '}
                  seconds
                </h3>
              )}
              {/*actionStatus.error && (
                <div className="alert alert-danger m-b-0 m-t-10" role="alert">
                  {actionStatus.error}
                </div>
              )*/}
              {actionStatus.status === ACTION_SUCCESS && (
                <div className="alert alert-success m-b-0 m-t-10" role="alert">
                  {!token && (
                    <p>We have sent you a mail with the password reset link.</p>
                  )}
                  {token && (
                    <p>
                      Your password has been reset. &nbsp;
                      <Link onClick={() => routeTo('login')}>Login Now</Link>
                    </p>
                  )}
                </div>
              )}
              <hr className="mt-8 mb-4" />
              <div className="pb-4 text-center">
                <Link
                  className="text-blue-600"
                  onClick={() => routeTo('login')}
                >
                  Back to Login Page
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  resetPasswordRequest: PropTypes.func.isRequired,
  resetPasswordSubmit: PropTypes.func.isRequired,
  routeTo: PropTypes.func.isRequired,
  urlParams: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const ResetPasswordContainer = connect(
  state => ({ user: (state as any).user }),
  dispatch => bindActionCreators({ ...userActions, ...appActions }, dispatch)
)(ResetPassword);

export default ResetPasswordContainer;
