import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from 'classnames';
import Button from '../components/Button';
import Loading from '../components/Loading';
import s from './styles.module.css';
import { PaymentStatus as PS } from '../constants';
import {
  paymentActions,
  newOrderActions,
  newPaymentActions,
  appActions,
} from '../data/actions';
import { formatDateTime } from '../format';
import Money from '../components/Money';
type Props = {
  urlParams: any;
  payments: any;
  resetOrder: any;
  resetPayment: any;
  routeTo: any;
  getPayment: any;
};
class PaymentStatus extends React.Component<Props> {
  static propTypes = {
    resetOrder: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    getPayment: PropTypes.func.isRequired,
    resetPayment: PropTypes.func.isRequired,
    urlParams: PropTypes.object.isRequired,
    payments: PropTypes.arrayOf(PropTypes.object).isRequired,
  };
  componentDidMount() {
    const paymentId = this.props.urlParams.paymentId;
    this.props.getPayment(paymentId);
  }

  render() {
    const {
      payments,
      urlParams,
      resetOrder,
      resetPayment,
      routeTo,
    } = this.props;
    const paymentId = parseInt(urlParams.paymentId, 10);
    const payment = (payments || []).find(p => p && p.id === paymentId);

    return (
      <div>
        <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">Payment Status</h2>
        <div className="grid grid-cols-1 mx-auto md:w-1/2">
          <div className="m-b-20">
            <div className="box center text-center p-20">
              {(!payment ||
                !payment.status ||
                payment.status === PS.pending) && <Loading />}
              {payment && payment.status === PS.complete && (
                <div>
                  <i className="fa fa-5x fa-check-circle-o green m-10 m-t-0" />
                  <h3 className="sub-title m-t-0 m-b-10">Payment Success</h3>
                  <p className={s.statusText}>
                    We have received your payment.
                    <br />
                  </p>
                  <table
                    className={cx(s.summaryTable, 'table table-hover m-b-0')}
                  >
                    <tbody>
                      <tr>
                        <th className="w-1/3 md:w-1/2 px-4 py-2" scope="row">
                          Paid On
                        </th>
                        <td className="w-2/3 md:w-1/2 px-4 py-2 text-right">
                          {formatDateTime(payment.paidOn)}
                        </td>
                      </tr>
                      <tr>
                        <th className="w-1/3 md:w-1/2 px-4 py-2" scope="row">
                          Amount
                        </th>
                        <td className="w-2/3 md:w-1/2 px-4 py-2 text-right">
                          <Money
                            amount={payment.amount}
                            currency={payment.currency}
                            showCurrency
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {payment && payment.status === PS.failed && (
                <div>
                  <i className="fa fa-5x fa-times red m-10 m-t-0" />
                  <h3 className="sub-title m-t-0 m-b-10">Payment Failed</h3>
                  <p className={s.statusText}>
                    We were unable to receive your payment.
                    <br />
                    We will get in touch with you shortly.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <Button
          className="block center"
          onClick={() => {
            resetOrder();
            resetPayment();
            routeTo('home');
          }}
          text="Done"
          big
        />
      </div>
    );
  }
}

// PaymentStatus.propTypes = {
//   resetOrder: PropTypes.func.isRequired,
//   routeTo: PropTypes.func.isRequired,
//   getPayment: PropTypes.func.isRequired,
//   resetPayment: PropTypes.func.isRequired,
//   urlParams: PropTypes.object.isRequired,
//   payments: PropTypes.arrayOf(PropTypes.object).isRequired,
// };

const PaymentStatusContainer = connect(
  state => ({ payments: (state as any).payments }),
  dispatch =>
    bindActionCreators(
      {
        ...paymentActions,
        ...newPaymentActions,
        ...newOrderActions,
        ...appActions,
      },
      dispatch
    )
)(PaymentStatus);

export default PaymentStatusContainer;
