import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from 'classnames';
import { InvoiceStatus, PaymentMode } from '../constants';
import Button from '../components/Button';
import Loading from '../components/Loading';
import s from './styles.module.css';
import { format, formatDate } from '../format';
import {
  appActions,
  invoiceActions,
  cardActions,
  chargeActions,
} from '../data/actions';
import Money from '../components/Money';
import { groupCharges } from '../core/utils/invoice';
import Link from '../components/Link';
type Props = {
  getCards: any;
  user: any;
  invoices: any;
  config: any;
  routeTo: any;
  settings: any;
  cards: any;
  charges: any;
  services: any;
  getCharges: any;
};
class InvoicePage extends React.Component<Props> {
  static propTypes = {
    config: PropTypes.object.isRequired,
    invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.object.isRequired,
    routeTo: PropTypes.func.isRequired,
    services: PropTypes.array.isRequired,
    cards: PropTypes.array.isRequired,
    charges: PropTypes.array.isRequired,
    getCards: PropTypes.func.isRequired,
    getCharges: PropTypes.func.isRequired,
  };
  componentDidMount() {
    const { getCards, getCharges } = this.props;
    getCards();
    getCharges();
  }

  render() {
    const { user, invoices, config, services, cards, charges, routeTo } =
      this.props;
    const paymentMode = config.countryId == 2 ? 'tap' : 'payfort';

    const unpaidInvoices = (invoices || []).filter(
      i =>
        i.status === InvoiceStatus.unpaid || i.status === InvoiceStatus.partial
    );
    const unpaidAmount = (unpaidInvoices || [])
      .map(i => i.amount)
      .reduce((a, b) => (a || 0) + (b || 0), 0);

    if (invoices.isFetching) {
      return <Loading className="m-t-50" />;
    }

    const unpaid = unpaidInvoices.reduce((total, invoice) => {
      return total + (invoice.amount - invoice.amountPaid);
    }, 0);

    return (
      <div>
        <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">
          Invoices
        </h2>
        {invoices.length === 0 && (
          <p className="text-center">You don&#39;t have any invoices</p>
        )}
        {invoices.length > 0 && (
          <div className="grid grid-cols-12 ">
            {unpaid > 0 && (
              <div className="col-span-12">
                <div className={cx(s.invoice, 'box p-20')}>
                  <p className="text-center">
                    You have {unpaidInvoices.length} unpaid invoices with a
                    total amount of{' '}
                    <Money
                      amount={unpaid}
                      currency={unpaidInvoices[0].currency}
                      showCurrency
                    />
                  </p>
                  {cards.length === 0 && (
                    <Button
                      className={`${s.invoiceButton} mt-3`}
                      text="Add Card"
                      onClick={() => {
                        if (
                          user &&
                          (user.user.countryId === 1 ||
                            user.user.countryId === 3)
                        ) {
                          routeTo('card-payfort');
                        } else if (user && user.user.countryId === 2) {
                          routeTo('card-tap');
                        }
                      }}
                    />
                  )}
                  <Button
                    className={`${s.invoiceButton} mt-3`}
                    onClick={() => {
                      console.log(' user.user.countryId,', user.user.countryId)
                      if (
                        user.user.countryId === 1 ||
                        user.user.countryId === 3
                      ) {
                        routeTo('payment-payfort', {
                          userId: user.userId,
                          amount: unpaid,
                          // countryId: user.user.countryId,
                        }, {
                          countryId: user.user.countryId,
                        });
                      } else if (user.user.countryId === 2) {
                        routeTo('payment-tap', {
                          userId: user.userId,
                          amount: unpaid,
                        });
                      }
                      localStorage.setItem('countryId', user.user.countryId);

                    }}
                    text="Pay all"
                  />
                </div>
              </div>
            )}

            {_.orderBy(invoices, ['date'], ['desc']).map(invoice => {
              const statusClass =
                (invoice.status === InvoiceStatus.paid && 'bg-green') ||
                (invoice.status === InvoiceStatus.unpaid && 'bg-red') ||
                (invoice.status === InvoiceStatus.partial && 'bg-yellow') ||
                (invoice.status === InvoiceStatus.cancelled && 'bg-silver');

              return (
                <div key={invoice.id} className="col-span-12">
                  <div className={cx(s.invoice, 'box')}>
                    <div className={cx(statusClass, s.invoiceStatus)}>
                      {format(invoice.status)}
                    </div>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          gap: '1rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className={s.invoiceNo}>Invoice #{invoice.id}</div>
                        {invoice.status === InvoiceStatus.unpaid ||
                        invoice.status === InvoiceStatus.partial ? (
                          <button
                            style={{
                              backgroundColor: '#27ae60',
                              color: '#fff',
                              padding: '.2rem .5rem',
                              borderRadius: '4px',
                              fontWeight: 500,
                              fontSize: '1rem',
                            }}
                            onClick={() => {
                              const paymentAmount =
                                invoice.amount - invoice.amountPaid;
                              if (
                                user.user.countryId === 1 ||
                                user.user.countryId === 3
                              ) {
                                routeTo('payment-payfort', {
                                  userId: user.userId,
                                  amount: paymentAmount,
                                  // countryId: user.user.countryId,
                                }, {
                                  countryId: user.user.countryId,
                                });
                              } else if (user.user.countryId === 2) {
                                routeTo('payment-tap', {
                                  userId: user.userId,
                                  amount: paymentAmount,
                                });
                              }
                              localStorage.setItem('countryId', user.user.countryId);

                            }}
                          >
                            Pay Invoice
                          </button>
                        ) : null}
                      </div>
                      <div className={s.invoiceDate}>
                        {formatDate(invoice.date, true)}
                      </div>
                    </div>

                    <table
                      className={cx(
                        s.invoiceTable,
                        'table table-bordered table-hover w-full'
                      )}
                    >
                      <thead>
                        <tr className="border">
                          <th className="border">No</th>
                          <th className="border">Item</th>
                          <th className="border">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(
                          groupCharges(
                            (charges || []).filter(
                              c => c.invoiceId === invoice.id
                            ),
                            services
                          ) || []
                        ).map((charge, index) => (
                          <tr key={index}>
                            <th scope="row" className="border">
                              {index + 1}
                            </th>
                            <td className="border">{charge.description}</td>
                            <td className="border">
                              <Money
                                amount={charge.amount}
                                currency={invoice.currency}
                                showCurrency
                              />
                            </td>
                          </tr>
                        ))}
                        {!!invoice.taxAmount && (
                          <tr>
                            <th className="border" scope="row" />
                            <td className="border">Sub Total</td>
                            <td className="border">
                              <Money
                                amount={invoice.amountBeforeTax}
                                currency={invoice.currency}
                                showCurrency
                              />
                            </td>
                          </tr>
                        )}
                        {!!invoice.taxAmount && (
                          <tr>
                            <th className="border" scope="row" />
                            <td className="border">
                              VAT {user.user.countryId === 3 ? '15%' : '5%'}
                            </td>
                            <td className="border">
                              <Money
                                amount={invoice.taxAmount}
                                currency={invoice.currency}
                                showCurrency
                              />
                            </td>
                          </tr>
                        )}
                        <tr>
                          <th className="border" scope="row" />
                          <td className="border">Unpaid amount</td>
                          <td className="border">
                            <Money
                              amount={invoice.amount - invoice.amountPaid}
                              currency={invoice.currency}
                              showCurrency
                            />
                          </td>
                        </tr>
                        <tr>
                          <th className="border" scope="row" />
                          <td className="border">Paid amount</td>
                          <td className="border">
                            <Money
                              amount={invoice.amountPaid}
                              currency={invoice.currency}
                              showCurrency
                            />
                          </td>
                        </tr>
                        <tr>
                          <th className="border" scope="row" />
                          <td className="border p-3">
                            <strong>Net Total</strong>
                          </td>
                          <td className="border p-3">
                            <strong>
                              <Money
                                amount={invoice.amount}
                                currency={invoice.currency}
                                showCurrency
                              />
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

// InvoicePage.propTypes = {
//     config: PropTypes.object.isRequired,
//     invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
//     user: PropTypes.object.isRequired,
//     routeTo: PropTypes.func.isRequired,
//     services: PropTypes.array.isRequired,
//     cards: PropTypes.array.isRequired,
//     getCards: PropTypes.func.isRequired,
// };

const InvoicePageContainer = connect(
  state => ({
    config: (state as any).config,
    user: (state as any).user,
    invoices: (state as any).invoices,
    services: (state as any).services,
    cards: (state as any).cards,
    charges: (state as any).charges,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...appActions,
        ...invoiceActions,
        ...cardActions,
        ...chargeActions,
      },
      dispatch
    )
)(InvoicePage);

export default InvoicePageContainer;
