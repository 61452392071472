import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cx from 'classnames';
import s from './styles.module.scss';
import Button from '../../components/Button';
import OrderQuantity from '../OrderQuantity';
import { newOrderActions, appActions } from '../../data/actions';
import { formatMoney } from '../../core/utils/money';
type Props = {
  storagetypes: any;
  newOrder: any;
  currency: any;
  onContinueClick: any;
  setItemQuantity: any;
  resetOrder: any;
  isHeaderContinueDisabled: any;
};

class OrderCart extends React.PureComponent<Props> {
  static propTypes = {
    newOrder: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    onContinueClick: PropTypes.func,
    storagetypes: PropTypes.array.isRequired,
    setItemQuantity: PropTypes.func.isRequired,
    isHeaderContinueDisabled: PropTypes.bool.isRequired,
  };
  public static defaultProps = {
    onContinueClick: null,
  };

  typeName(storagetypeId) {
    const { storagetypes } = this.props;
    const type = storagetypes.find(st => st.id === storagetypeId);
    return type && type.name;
  }

  render() {
    const {
      newOrder,
      currency,
      onContinueClick,
      setItemQuantity,
      resetOrder,
      isHeaderContinueDisabled,
    } = this.props;
    const items = newOrder.items || [];
    const totalItems = items.map(i => i.quantity).reduce((a, b) => a + b, 0);

    return (
      <div style={{ position: 'relative', height: 'auto' }}>
        <div className={`cartContainer ${s.cartContainer}`}>
          <ul>
            <li className={s.cartTotal}>
              {totalItems ? (
                <div>
                  <span>{totalItems} Items to Store</span>
                  <span className="right">
                    <button
                      className={cx('btn bg-red', s.clearCart)}
                      onClick={() => {
                        if (
                          // eslint-disable-next-line no-restricted-globals
                          confirm(
                            'Are you sure you want to remove all items from cart?'
                          )
                        ) {
                          resetOrder();
                        }
                      }}
                    >
                      <i className="fa fa-times p-5" />
                    </button>
                  </span>
                </div>
              ) : (
                <div>Items</div>
              )}
            </li>
            <hr />
            {items.length === 0 && (
              <li style={{ fontWeight: 300 }}>No items added.</li>
            )}
            {items.map(item => {
              if (
                !item ||
                !item.quantity ||
                !this.typeName(item.storagetypeId)
              ) {
                return null;
              }
              return (
                <li key={item.storagetypeId}>
                  {this.typeName(item.storagetypeId)}
                  <OrderQuantity
                    storagetypeId={item.storagetypeId}
                    quantity={item.quantity}
                    setItemQuantity={setItemQuantity}
                    pullRight
                  />
                </li>
              );
            })}
            <hr />
            {newOrder.totalSize > 0 && (
              <li className={s.cartTotal}>
                <span>Total Size</span>{' '}
                <div className="right">
                  <span>{newOrder.totalSize.toFixed(2)}</span>
                  <span className={s.light}> CBM</span>
                </div>
              </li>
            )}
            {newOrder.totalSize > 0 && (
              <li className={s.cartTotal}>
                <span>Rate</span>{' '}
                <div className="right">
                  <span>
                    {currency} {formatMoney(newOrder.rate, currency, true)}
                  </span>
                  <span className={s.light}> /CBM</span>
                </div>
              </li>
            )}
            {newOrder.monthlyRent > 0 && (
              <li className={s.cartTotal}>
                <span>Total</span>{' '}
                <div className="right">
                  <span>
                    {currency}{' '}
                    {formatMoney(newOrder.monthlyRent, currency, true)}
                  </span>
                  <span className={s.light}> /month</span>
                </div>
              </li>
            )}
          </ul>
          {onContinueClick && (
            <Button
              onClick={onContinueClick}
              disabled={isHeaderContinueDisabled}
              text="Continue"
              big
              full
            />
          )}
        </div>
      </div>
    );
  }
}

// OrderCart.propTypes = {
//     newOrder: PropTypes.object.isRequired,
//     currency: PropTypes.string.isRequired,
//     onContinueClick: PropTypes.func,
//     storagetypes: PropTypes.array.isRequired,
//     setItemQuantity: PropTypes.func.isRequired,
//     isHeaderContinueDisabled: PropTypes.bool.isRequired,
// };

// OrderCart.defaultProps = {
//     onContinueClick: () => {},
// };

const OrderCartContainer = connect(
  state => ({
    newOrder: (state as any).newOrder,
    currency: (state as any).config.currency,
    storagetypes: (state as any).config.storagetypes,
  }),
  dispatch =>
    bindActionCreators({ ...newOrderActions, ...appActions }, dispatch)
)(OrderCart);

export default OrderCartContainer;
