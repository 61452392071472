import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cx from 'classnames';
import s from './OrderCart.module.scss';
import OrderQuantity from '../order/OrderQuantity';
import { newOrderActions, appActions } from '../data/actions';
import { formatMoney } from '../core/utils/money';
import Money from '../components/Money';
import OrderContinue from '../order/OrderContinue';
type Props = {
  storagetypes: any;
  newOrder: any;
  currency: any;
  onContinueClick: any;
  setItemQuantity: any;
  resetOrder: any;
  isHeaderContinueDisabled: any;
  isLoading: any;
};

class OrderCart extends React.PureComponent<Props> {
  static propTypes = {
    newOrder: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    onContinueClick: PropTypes.func,
    storagetypes: PropTypes.array.isRequired,
    setItemQuantity: PropTypes.func.isRequired,
    isHeaderContinueDisabled: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
  };
  public static defaultProps = {
    onContinueClick: null,
    isLoading: false,
  };

  typeName(storagetypeId) {
    const { storagetypes } = this.props;
    const type = storagetypes.find(st => st.id === storagetypeId);
    return type && type.name;
  }

  render() {
    const {
      newOrder,
      currency,
      onContinueClick,
      setItemQuantity,
      resetOrder,
      isHeaderContinueDisabled,
      isLoading,
    } = this.props;
    const { coupon, couponDiscount, totalRent } = newOrder;
    const items = newOrder.items || [];
    const totalItems = items.map(i => i.quantity).reduce((a, b) => a + b, 0);

    let dueNow = totalRent - (couponDiscount || 0);
    dueNow = Math.max(dueNow, 0);

    return (
      <div style={{ position: 'relative',  height: 'auto' , marginLeft:'20px'}}>
        <div className={`cartContainer ${s.cartContainer}`}>
          <ul>
            <li className={s.cartTotal}>
              {totalItems ? (
                <div>
                  <span>{totalItems} Items to store</span>
                  <span className="right">
                    <button
                      className={cx('btn bg-red', s.clearCart)}
                      onClick={() => {
                        if (
                          // eslint-disable-next-line no-restricted-globals
                          confirm(
                            'Are you sure you want to remove all items from cart?'
                          )
                        ) {
                          resetOrder();
                        }
                      }}
                    >
                      <i className="fa fa-times p-5" />
                    </button>
                  </span>
                </div>
              ) : (
                <div>Items</div>
              )}
            </li>
            <hr className="my-8" />
            {items.length === 0 && (
              <li style={{ fontWeight: 300 }}>
                {newOrder.comment ? newOrder.comment : 'No items added.'}
              </li>
            )}
            {items.map(item => {
              if (
                !item ||
                !item.quantity ||
                !this.typeName(item.storagetypeId)
              ) {
                return null;
              }
              return (
                <li key={item.storagetypeId}>
                  {this.typeName(item.storagetypeId)}
                  <OrderQuantity
                    storagetypeId={item.storagetypeId}
                    quantity={item.quantity}
                    setItemQuantity={setItemQuantity}
                    pullRight
                  />
                </li>
              );
            })}
            <hr className="my-8" />
            {/*
                        {(newOrder.totalSize > 0) && (
                        <li className={s.cartTotal}><span>Total Volume</span><div className="right"><span>{newOrder.totalSize.toFixed(2)}</span><span className={s.light}> CBM</span></div></li>
                        )}
                      */}
            {/* {(newOrder.totalSize > 0) && (
                        <li className={s.cartTotal}><span>Rate</span> <div className="right"><span>{currency} {formatMoney(newOrder.rate, currency, true)}</span><span className={s.light}> /CBM</span></div></li>
                        )} */}
            {newOrder.totalSize > 0 && (
              <li className={s.cartTotal}>
                <span>Estimated Rent</span>
                <div className="right">
                  <span>
                    <span className="text-lg">{currency}</span>{' '}
                    {formatMoney(newOrder.monthlyRent, currency, true)}
                  </span>
                  <span className={s.light}> /month</span>
                </div>
              </li>
            )}
            {newOrder.totalSize > 0 && coupon && couponDiscount && (
              <li style={{ marginTop: '-20px' }}>
                <span>Coupon {coupon}</span>
                <div className="right">
                  <span>
                    {' '}
                    -{' '}
                    <Money
                      amount={couponDiscount}
                      currency={currency}
                      showCurrency
                      skipEmptyFraction
                    />
                  </span>
                  <span className={s.light}> /month</span>
                </div>
              </li>
            )}
            {newOrder.totalSize > 0 &&
              coupon &&
              couponDiscount &&
              dueNow &&
              dueNow !== newOrder.monthlyRent && (
                <li className={s.cartTotal}>
                  <span>After Discount</span>
                  <div className="right">
                    <span>
                      {currency} {formatMoney(dueNow, currency, true)}
                    </span>
                    <span className={s.light}> /month</span>
                  </div>
                </li>
              )}
            {newOrder.totalSize > 0 && (
              <li className={s.cartNotice}>
                <span className={s.light}>
                  Note: Transportation charges may be added to the quotation if
                  applicable
                </span>
              </li>
            )}
          </ul>

          <OrderContinue
            continueText="Get Free Quote"
            onContinueClick={onContinueClick}
            isDisabled={isHeaderContinueDisabled}
            isLoading={isLoading}
          />
        </div>
      </div>
    );
  }
}

// OrderCart.propTypes = {
//     newOrder: PropTypes.object.isRequired,
//     currency: PropTypes.string.isRequired,
//     onContinueClick: PropTypes.func,
//     storagetypes: PropTypes.array.isRequired,
//     setItemQuantity: PropTypes.func.isRequired,
//     isHeaderContinueDisabled: PropTypes.bool.isRequired,
//     isLoading: PropTypes.bool,
// };

// OrderCart.defaultProps = {
//     onContinueClick: () => { },
//     isLoading: false,
// };

const OrderCartContainer = connect(
  state => ({
    newOrder: (state as any).newOrder,
    currency: (state as any).config.currency,
    storagetypes: (state as any).config.storagetypes,
  }),
  dispatch =>
    bindActionCreators({ ...newOrderActions, ...appActions }, dispatch)
)(OrderCart);

export default OrderCartContainer;
