import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import OrderItems from './OrderItems';
import { ACTION_START } from '../data/constants';
import { newOrderActions, appActions } from '../data/actions';
import OrderHeader from './OrderHeader';
import OrderContinue from './OrderContinue';
import s from './styles.module.scss';

type Props = {
  startOrder: any;
  setMonths: any;
  newOrder: any;
  config: any;
  setItemQuantity: any;
  setOrderComment: any;
  routeTo: any;
};

class OrderPage extends React.Component<Props> {
  static propTypes = {
    setItemQuantity: PropTypes.func.isRequired,
    setMonths: PropTypes.func.isRequired,
    startOrder: PropTypes.func.isRequired,
    setOrderComment: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    newOrder: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { startOrder, setMonths, newOrder } = this.props;
    if (!newOrder.status || newOrder.status !== ACTION_START) {
      startOrder();
    }
    setMonths(newOrder.months || 1);
  }

  render() {
    const {
      newOrder,
      config,
      setItemQuantity,
      setOrderComment,
      routeTo,
    } = this.props;

    return (
      <div>
        <OrderHeader
          newOrder={newOrder}
          currency={config.currency}
          storagetypes={config.storagetypes}
          current="storage"
          routeTo={routeTo}
          setItemQuantity={setItemQuantity}
          isHeaderContinueDisabled={!newOrder.items || !newOrder.items.length}
          onContinueClick={() => {
            routeTo('order-appointment');
          }}
        />
        <div className="container">
          <h3 key="title1" className={`${s.orderQuestion} text-center`}>
            What would you like to store?
          </h3>
          <OrderItems
            items={newOrder.items}
            currency={config.currency}
            storagetypes={config.storagetypes}
            setItemQuantity={setItemQuantity}
            comment={newOrder.comment}
            setOrderComment={setOrderComment}
            isHeaderContinueDisabled={!newOrder.items || !newOrder.items.length}
            onContinueClick={() => {
              routeTo('order-appointment');
            }}
          />
        </div>
        <OrderContinue
          isDisabled={!newOrder.items || !newOrder.items.length}
          onContinueClick={() => {
            routeTo('order-appointment');
          }}
        />
      </div>
    );
  }
}

// OrderPage.propTypes = {
//     setItemQuantity: PropTypes.func.isRequired,
//     setMonths: PropTypes.func.isRequired,
//     startOrder: PropTypes.func.isRequired,
//     setOrderComment: PropTypes.func.isRequired,
//     routeTo: PropTypes.func.isRequired,
//     newOrder: PropTypes.object.isRequired,
//     config: PropTypes.object.isRequired,
// };

const OrderPageContainer = connect(
  state => ({
    newOrder: (state as any).newOrder,
    config: (state as any).config,
  }),
  dispatch =>
    bindActionCreators({ ...newOrderActions, ...appActions }, dispatch)
)(OrderPage);

export default OrderPageContainer;
