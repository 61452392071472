import { COMPUTE_HASH, ACTION_RESET } from '../constants';
import errorHandleActions from '../actions/errorHandle';

const computeHash = (mode, params) => (dispatch, getState) => {
  dispatch({
    type: COMPUTE_HASH,
    apiCall: {
      method: 'POST',
      path: `${mode}-hash`,
      params,
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const resetHash = () => ({
  type: COMPUTE_HASH,
  status: ACTION_RESET,
});

export default {
  computeHash,
  resetHash,
};
