import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Header from './Header';
import s from './Layout.module.css';
import { InvoiceStatus } from '../../constants';
type Props = {
  user: any;
  className: any;
  children: any;
  invoices: any;
  routeTo: any;
};
// eslint-disable-next-line react/prefer-stateless-function
class Layout extends React.Component<Props> {
  static propTypes = {
    routeTo: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.object,
    className: PropTypes.string,
  };
  public static defaultProps = {
    user: {},
    className: '',
  };
  render() {
    const { user, className, children, invoices, routeTo } = this.props;
    const pendingInvoiceCount = (invoices || []).filter(
      i => i.status === InvoiceStatus.unpaid
    ).length;

    return (
      <main className={cx(s.main, 'bg-gray-200')}>
        <Header
          user={user}
          pendingInvoiceCount={pendingInvoiceCount}
          routeTo={routeTo}
        />
        <div
          className={cx(
            'container mx-auto pt-2 lg:pt-8 lg:pb-12',
            s.container,
            className
          )}
        >
          {children}
        </div>
      </main>
    );
  }
}

// Layout.propTypes = {
//     routeTo: PropTypes.func.isRequired,
//     children: PropTypes.node.isRequired,
//     invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
//     user: PropTypes.object,
//     className: PropTypes.string,
// };

// Layout.defaultProps = {
//     user: {},
//     className: "",
// };

export default Layout;
