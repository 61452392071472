import { INVOICES_GET } from '../constants';
import errorHandleActions from '../actions/errorHandle';

const getInvoices = () => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: INVOICES_GET,
    apiCall: {
      method: 'GET',
      path: 'invoices',
      queryParams: {
        userId: user.userId,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

export default {
  getInvoices,
};
