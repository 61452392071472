import {
  CARDS_GET,
  CARD_GET,
  ACTION_START,
  ACTION_FAILED,
  ACTION_SUCCESS,
} from '../constants';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CARDS_GET:
      if (action.status === ACTION_START) {
        const cards = Object.assign([...state], { isFetching: true });
        return cards;
      }
      if (action.status === ACTION_SUCCESS) {
        const cards = action.data.data;
        cards.isFetching = false;
        return cards;
      }
      if (action.status === ACTION_FAILED) {
        const cards = Object.assign([...state], { isFetching: false });
        return cards;
      }
      return state;

    case CARD_GET:
      if (action.status === ACTION_START) {
        const cards = Object.assign([...state], { isFetching: true });
        return cards;
      }
      if (action.status === ACTION_SUCCESS) {
        const cards = Object.assign([action.data || null], {
          isFetching: false,
        });
        return cards;
      }
      if (action.status === ACTION_FAILED) {
        const cards = Object.assign([...state], { isFetching: false });
        return cards;
      }
      return state;

    default:
      return state;
  }
}
