import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cx from 'classnames';
import OrderCart from './OrderCart';
import $ from 'jquery';
import s from './OrderHeader.module.scss';
import imgLogo from './images/logo.png';
import Link from '../components/Link';
import { newOrderActions, appActions } from '../data/actions';
import { formatMoney } from '../core/utils/money';
type Props = {
  newOrder: any;
  currency: any;
  headerText: any;
  headerTextSmall: any;
  onContinueClick: any;
  setItemQuantity: any;
  isHeaderContinueDisabled: any;
};
type State = {
  showCart: any;
};

const CartIcon = ({
  newOrder,
  currency,
  totalItems,
  onClick,
}: {
  newOrder: any;
  currency: string;
  totalItems: number;
  onClick: (e: any) => void;
}) => (
  <div className={s.summaryItem} onClick={onClick}>
    <i className="fa fa-2x fa-shopping-bag" aria-hidden="true" />

    {totalItems > 0 && <div className={s.summaryBadge}>{totalItems}</div>}

    {newOrder.totalSize > 0 && (
      <div className={s.summarySubtitle}>
        {currency}&nbsp;
        {formatMoney(newOrder.monthlyRent, currency, true)}
      </div>
    )}
  </div>
);

class OrderHeader extends React.PureComponent<Props, State> {
  static propTypes = {
    newOrder: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    headerText: PropTypes.string,
    headerTextSmall: PropTypes.string,
    onContinueClick: PropTypes.func,
    setItemQuantity: PropTypes.func.isRequired,
    isHeaderContinueDisabled: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      showCart: false,
    };

    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.onDocumentClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onDocumentClick);
  }

  onDocumentClick(e) {
    // const $ = window.$;
    const container = $('.cartContainer');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      this.setState({ showCart: false });
    }
  }

  render() {
    const {
      newOrder,
      currency,
      headerText,
      headerTextSmall,
      onContinueClick,
      setItemQuantity,
      isHeaderContinueDisabled,
    } = this.props;
    const { showCart } = this.state;
    const totalItems = (newOrder.items || [])
      .map(i => i.quantity)
      .reduce((a, b) => a + b, 0);

    return (
      <div className="m-b-20">
        <div className="spacer-50" />
        <div className={s.summaryContainer}>
          <div className="container flex mx-auto">
            <a href="https://www.boxitstorage.com">
              <img className={s.logo} src={imgLogo} alt="Boxit Storage" />
            </a>

            <div className={`${s.titleContainer}`}>
              {headerText && (
                <h3 className={`${s.headerTitle}  hidden md:block `}>
                  {headerText}
                </h3>
              )}
              {headerTextSmall && (
                <h3 className={`${s.headerTitle} block md:hidden`}>
                  {headerTextSmall}
                </h3>
              )}
            </div>

            <div className=" hidden md:block">
              <CartIcon
                newOrder={newOrder}
                currency={currency}
                totalItems={totalItems}
                onClick={e => e.stopPropagation()}
              />
            </div>

            <div className="block md:hidden">
              <CartIcon
                newOrder={newOrder}
                currency={currency}
                totalItems={totalItems}
                onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    showCart: !this.state.showCart,
                  });
                }}
              />
            </div>
          </div>

          <div
            className={cx(s.cartWrapper, 'container', {
              [s.open]: showCart && totalItems !== 0,
            })}
          >
            <OrderCart
              onContinueClick={onContinueClick}
              setItemQuantity={setItemQuantity}
              isHeaderContinueDisabled={isHeaderContinueDisabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

// OrderHeader.propTypes = {
//     newOrder: PropTypes.object.isRequired,
//     currency: PropTypes.string.isRequired,
//     headerText: PropTypes.string,
//     headerTextSmall: PropTypes.string,
//     onContinueClick: PropTypes.func,
//     setItemQuantity: PropTypes.func.isRequired,
//     isHeaderContinueDisabled: PropTypes.bool.isRequired,
// };

const OrderHeaderContainer = connect(
  state => ({
    newOrder: (state as any).newOrder,
    config: (state as any).config,
  }),
  dispatch =>
    bindActionCreators({ ...newOrderActions, ...appActions }, dispatch)
)(OrderHeader);

export default OrderHeaderContainer;
