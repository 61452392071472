import {
  ACTION_START,
  ACTION_FAILED,
  ACTION_SUCCESS,
  STORAGES_GET_PARTS,
  PHOTOS_GET_PARTS,
  STORAGES_GET_NUMBER,
  STORAGES_GET_PARTS_NUMBER,
  STORAGES_GET_STATS,
  STORAGES_ALL_GET,
  STORAGES_GET_DELIVERED_NUMBER,
  CURRENT_STORAGES_GET,
} from '../constants';

const initialState = {
  allStorages: [],
  storageParts: [],
  currentStorage: null,
  isCurrentStorageLoading: true,
  isStoragePartsLoading: false,
  partPhotos: [],
  storagesNumber: null,
  partsNumber: null,
  stats: null,
  deliveredNumber: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORAGES_GET_PARTS:
      if (action.status === ACTION_START) {
        const storages = Object.assign([...state.storageParts], {
          isFetching: true,
        });
        return {
          ...state,
          isStoragePartsLoading: true,
          storageParts: storages,
        };
      }
      if (action.status === ACTION_SUCCESS) {
        const storages = action.data.data || [];
        storages.total = action.data.total;
        storages.pageSize = action.data.limit;
        storages.isFetching = false;
        return {
          ...state,
          isStoragePartsLoading: false,
          storageParts: storages,
        };
      }
      if (action.status === ACTION_FAILED) {
        const storages = Object.assign([...state.storageParts], {
          isFetching: false,
        });
        return {
          ...state,
          isStoragePartsLoading: false,
          storageParts: storages,
        };
      }
      return state;

    case PHOTOS_GET_PARTS:
      if (action.status === ACTION_START) {
        const photos = Object.assign([...state.partPhotos], {
          isFetching: true,
        });
        return { ...state, partPhotos: photos };
      }
      if (action.status === ACTION_SUCCESS) {
        const photos = action.data.data || [];
        photos.isFetching = false;
        return { ...state, partPhotos: photos };
      }
      if (action.status === ACTION_FAILED) {
        const photos = Object.assign([...state.partPhotos], {
          isFetching: false,
        });
        return { ...state, partPhotos: photos };
      }
      return state;

    case STORAGES_GET_NUMBER:
      if (action.status === ACTION_START) {
        return { ...state, storagesNumber: null };
      }
      if (action.status === ACTION_SUCCESS) {
        return { ...state, storagesNumber: action.data.total };
      }
      if (action.status === ACTION_FAILED) {
        return { ...state, storagesNumber: null };
      }
      return state;

    case STORAGES_GET_PARTS_NUMBER:
      if (action.status === ACTION_START) {
        return { ...state, partsNumber: null };
      }
      if (action.status === ACTION_SUCCESS) {
        console.log(action);
        return { ...state, partsNumber: action.data.total };
      }
      if (action.status === ACTION_FAILED) {
        return { ...state, partsNumber: null };
      }
      return state;

    case STORAGES_GET_DELIVERED_NUMBER:
      if (action.status === ACTION_START) {
        return { ...state, deliveredNumber: null };
      }
      if (action.status === ACTION_SUCCESS) {
        console.log(action);
        return { ...state, deliveredNumber: action.data.total };
      }
      if (action.status === ACTION_FAILED) {
        return { ...state, deliveredNumber: null };
      }
      return state;

    case STORAGES_GET_STATS:
      if (action.status === ACTION_START) {
        return { ...state, stats: null };
      }
      if (action.status === ACTION_SUCCESS) {
        console.log(action);
        return {
          ...state,
          stats: action.data,
        };
      }
      if (action.status === ACTION_FAILED) {
        return { ...state, stats: null };
      }
      return state;

    case STORAGES_ALL_GET:
      if (action.status === ACTION_START) {
        const storages = Object.assign([...state.allStorages], {
          isFetching: true,
        });
        return { ...state, allStorages: storages };
      }
      if (action.status === ACTION_SUCCESS) {
        const storages = action.data.data || [];
        storages.total = action.data.total;
        storages.pageSize = action.data.limit;
        storages.isFetching = false;
        return { ...state, allStorages: storages };
      }
      if (action.status === ACTION_FAILED) {
        const storages = Object.assign([...state.allStorages], {
          isFetching: false,
        });
        return { ...state, allStorages: storages };
      }
      return state;

    case CURRENT_STORAGES_GET:
      if (action.status === ACTION_START) {
        return {
          ...state,
          currentStorage: null,
          isCurrentStorageLoading: true,
        };
      }
      if (action.status === ACTION_SUCCESS) {
        console.log(action);
        return {
          ...state,
          currentStorage: action.data.data[0],
          isCurrentStorageLoading: false,
        };
      }
      if (action.status === ACTION_FAILED) {
        return { ...state, isCurrentStorageLoading: false };
      }
      return state;

    default:
      return state;
  }
}
