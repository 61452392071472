import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import FastClick from 'fastclick';
import { Provider } from 'react-redux';
// import 'bootstrap';
import store from './data/store';
import Root from './root';
import './flip';
import 'intl-tel-input/build/js/utils';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'pretty-checkbox/src/pretty.min.css';
import 'react-phone-input-2/lib/plain.css';
import 'react-credit-cards/lib/styles.scss';
import './styles/main.scss';
import './styles/tailwind.output.css';

const container = document.getElementById('app');
if (container) {
  ReactDOM.render(
    <Provider store={store as any}>
      <Root />
    </Provider>,
    container
  );
}
FastClick['attach'](document.body);
