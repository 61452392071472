import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RequestType, StorageStatus } from '../constants';
import Button from '../components/Button';
import StorageList from '../components/StorageList';
import {
  appActions,
  newRequestActions,
  addressActions,
  storagePartsActions,
  storageActions,
} from '../data/actions';
type Props = {
  urlParams: any;
  setRequestType: any;
  storages: any;
  setStorageIds: any;
  getAddresses: any;
  settings: any;
  newRequest: any;
  routeTo: any;
  parts: any;
  stats: any;
  getStats: any;
  getStorageParts: any;
  storageParts: any;
  isStoragePartsLoading: any;
  pagination: any;
  currentStorages: any;
  getStorages: any;
};
type State = {
  title: any;
};
class RequestPage extends React.Component<Props, State> {
  static propTypes = {
    urlParams: PropTypes.object.isRequired,
    setStorageIds: PropTypes.func.isRequired,
    setRequestType: PropTypes.func.isRequired,
    getAddresses: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    newRequest: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    storages: PropTypes.arrayOf(PropTypes.object).isRequired,
    parts: PropTypes.arrayOf(PropTypes.object).isRequired,
    getStats: PropTypes.func.isRequired,
    stats: PropTypes.object.isRequired,
    storageParts: PropTypes.arrayOf(PropTypes.object).isRequired,
    getStorageParts: PropTypes.func.isRequired,
    isStoragePartsLoading: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
    currentStorages: PropTypes.arrayOf(PropTypes.object).isRequired,
    getStorages: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);

    const type = props.urlParams.type;
    const title =
      (type === RequestType.pickup && 'Pickup Items') ||
      (type === RequestType.delivery && 'Bring Back Items');

    this.state = {
      title,
    };
  }

  componentDidMount() {
    document.title = `${this.state.title} - Boxit Storage`;
    const {
      urlParams,
      setRequestType,
      storages,
      setStorageIds,
      getAddresses,
      getStats,
      getStorages,
    } = this.props;
    const type = urlParams.type;
    setStorageIds([]);
    setRequestType(type);
    getAddresses();
    if (type === RequestType.pickup) {
      const delivered = storages.filter(
        st => st.status === StorageStatus.delivered
      );
      //setStorageIds(delivered.map(re => re.id));
    }

    if (type === RequestType.pickup) {
      getStorages({ status: ['delivered'] });
      getStats(['delivered']);
    } else if (type === RequestType.delivery) {
      getStorages({ status: ['stored', 'in'] });
      getStats(['stored', 'in']);
    }
  }

  render() {
    const {
      settings,
      urlParams,
      storages,
      newRequest,
      setStorageIds,
      routeTo,
      parts,
      stats,
      getStorageParts,
      storageParts,
      isStoragePartsLoading,
      pagination,
      currentStorages,
    } = this.props;
    const type = urlParams.type;
    const isDisabled = newRequest.storageIds.length === 0;
    let filteredStorages = [];
    let subTitle;
    if (type === RequestType.delivery) {
      filteredStorages = storages.filter(
        st =>
          st.status === StorageStatus.stored || st.status === StorageStatus.in
      );
      subTitle = 'Select the items you would like to bring back';
    }
    if (type === RequestType.pickup) {
      filteredStorages = storages.filter(
        st => st.status === StorageStatus.delivered
      );
      subTitle = '';
    }
    console.log({ filteredStorages });
    return (
      <div>
        <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">
          {this.state.title}
        </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '.5rem',
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              border: '1px solid #e2e8f0',
              width: '20rem',
              height: '3rem',
            }}
          >
            <h4
              style={{
                padding: '.5rem',
                width: '70%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderRight: '1px solid #e2e8f0',
                fontWeight: 'bold',
              }}
            >
              Description
            </h4>
            <h4
              style={{
                padding: '.5rem',
                width: '30%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
              }}
            >
              Count
            </h4>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              border: '1px solid #e2e8f0',
              width: '20rem',
              height: '3rem',
            }}
          >
            <h4
              style={{
                padding: '.5rem',
                width: '70%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderRight: '1px solid #e2e8f0',
              }}
            >
              Total items
            </h4>
            <h3
              style={{
                padding: '.5rem',
                width: '30%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {!!stats && stats.parentStoragesCount}
            </h3>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              border: '1px solid #e2e8f0',
              width: '20rem',
              height: '3rem',
            }}
          >
            <h4
              style={{
                padding: '.5rem',
                width: '70%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderRight: '1px solid #e2e8f0',
              }}
            >
              Total parts
            </h4>
            <h3
              style={{
                padding: '.5rem',
                width: '30%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {!!stats && stats.partsCount}
            </h3>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              border: '1px solid #e2e8f0',
              width: '20rem',
              height: '3rem',
            }}
          >
            <h4
              style={{
                padding: '.5rem',
                width: '70%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderRight: '1px solid #e2e8f0',
              }}
            >
              Total items including parts
            </h4>
            <h3
              style={{
                padding: '.5rem',
                width: '30%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {!!stats && stats.parentStoragesCount + stats.partsCount}
            </h3>
          </div>
        </div>
        <StorageList
          mode="SELECT"
          type={
            urlParams.type === RequestType.delivery
              ? RequestType.delivery
              : RequestType.pickup
          }
          pagination={pagination}
          settings={settings}
          title={subTitle}
          storages={currentStorages}
          parts={parts || []}
          selectedIds={newRequest.storageIds}
          onClick={async storageId => {
            if (isStoragePartsLoading) {
              return;
            }
            /*if (type === RequestType.pickup) {
              return;
            }*/
            await getStorageParts(storageId, data => {
              console.log(data);

              const partsIds = data.data.map(item => {
                return item.id;
              });
              let selected = newRequest.storageIds;
              if (selected.indexOf(storageId) !== -1) {
                selected.splice(selected.indexOf(storageId), 1);
                selected = selected.filter(function (el) {
                  return !partsIds.includes(el);
                });
              } else {
                console.log(partsIds);
                selected.push(storageId);
                selected = [...selected, ...partsIds];
              }
              setStorageIds(selected);

              console.log(selected);
            });
          }}
        />

        <Button
          className="block center m-t-20"
          onClick={() => routeTo('request-appointment')}
          disabled={isDisabled || isStoragePartsLoading}
          text={'Continue'}
          big
        />
      </div>
    );
  }
}

// RequestPage.propTypes = {
//     urlParams: PropTypes.object.isRequired,
//     setStorageIds: PropTypes.func.isRequired,
//     setRequestType: PropTypes.func.isRequired,
//     getAddresses: PropTypes.func.isRequired,
//     routeTo: PropTypes.func.isRequired,
//     newRequest: PropTypes.object.isRequired,
//     settings: PropTypes.object.isRequired,
//     storages: PropTypes.arrayOf(PropTypes.object).isRequired,
// };

const RequestPageContainer = connect(
  state => ({
    newRequest: (state as any).newRequest,
    currentStorages: (state as any).storages,
    storages: (state as any).storageParts.allStorages.filter(
      storage => !storage.isPart
    ),
    parts: (state as any).storageParts.allStorages.filter(
      storage => storage.isPart
    ),
    stats: (state as any).storageParts.stats,
    storageParts: (state as any).storageParts.storageParts,
    isStoragePartsLoading: (state as any).storageParts.isStoragePartsLoading,
    pagination: (state as any).storages,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...newRequestActions,
        ...appActions,
        ...addressActions,
        ...storagePartsActions,
        ...storageActions,
      },
      dispatch
    )
)(RequestPage);

export default RequestPageContainer;
