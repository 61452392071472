import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loading from '../components/Loading';
import PropTypes from 'prop-types';
import { appActions } from '../data/actions';
type Props = {
  routeTo: any;
};

class OfferPage extends React.Component<Props> {
  static propTypes = {
    routeTo: PropTypes.func.isRequired,
  };
  componentDidMount() {
    const { routeTo } = this.props;
    routeTo('storage-calculator', null, { coupon: 'KUWAIT20' });
  }

  render() {
    return (
      <div className="mx-auto">
        <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">Offer</h2>
        <Loading />
      </div>
    );
  }
}

// OfferPage.propTypes = {};

const OfferPageContainer = connect(null, dispatch =>
  bindActionCreators({ ...appActions }, dispatch)
)(OfferPage);

export default OfferPageContainer;
