import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import s from './styles.module.scss';
type Props = {
  setItemQuantity: any;
  storagetypeId: any;
  quantity: any;
  pullRight: any;
};
class OrderQuantity extends React.PureComponent<Props> {
  static propTypes = {
    setItemQuantity: PropTypes.func.isRequired,
    storagetypeId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    pullRight: PropTypes.bool,
  };
  public static defaultProps = {
    pullRight: false,
  };
  render() {
    const { setItemQuantity, storagetypeId, quantity, pullRight } = this.props;

    if (quantity === 0) {
      return (
        <div
          className={cx({ [s.quantityFloat]: pullRight }, s.itemAddContainer)}
        >
          <Button
            className={s.itemAdd}
            onClick={() => {
              setItemQuantity(storagetypeId, quantity + 1);
            }}
            filled={false}
          >
            Add
          </Button>
        </div>
      );
    }

    return (
      <div className={cx({ [s.quantityFloat]: pullRight }, s.itemAddContainer)}>
        <Button
          className={s.itemQuantityChange}
          onClick={() => {
            setItemQuantity(storagetypeId, quantity - 1);
          }}
        >
          -
        </Button>
        <div className={s.itemQuantity}>{quantity}</div>
        <Button
          className={s.itemQuantityChange}
          onClick={() => {
            setItemQuantity(storagetypeId, quantity + 1);
          }}
        >
          +
        </Button>
      </div>
    );
  }
}

// OrderQuantity.propTypes = {
//   setItemQuantity: PropTypes.func.isRequired,
//   storagetypeId: PropTypes.string.isRequired,
//   quantity: PropTypes.number.isRequired,
//   pullRight: PropTypes.bool,
// };

// OrderQuantity.defaultProps = {
//   pullRight: false,
// };

export default OrderQuantity;
