import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import s from './styles.module.css';
import whatsLogo from './images/whats.png';

class NotLoggedInNewOrderPage extends React.PureComponent<any, any> {
  render() {
    return (
      <div className={s.contactPage}>
        <div className={s.column}>
          <h2>New Order</h2>
          <div className={s.contactContainer}>
            <div className={s.contactPart}>
              <div className={s.country}>
                <h2>UAE</h2>
              </div>
              <div className={s.countryWhatsapp}>
                <a href="https://api.whatsapp.com/send?phone=97180026948">
                  <img src={whatsLogo} />
                </a>
              </div>
            </div>
            <div className={s.contactPart}>
              <div className={s.country}>
                <h2>Kuwait</h2>
              </div>
              <div className={s.countryWhatsapp}>
                <a href="https://api.whatsapp.com/send?phone=9651800071">
                  <img src={whatsLogo} />
                </a>
              </div>
            </div>
            <div className={s.contactPart}>
              <div className={s.country}>
                <h2>KSA</h2>
              </div>
              <div className={s.countryWhatsapp}>
                <a href="https://api.whatsapp.com/send?phone=9651800071">
                  <img src={whatsLogo} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => bindActionCreators({}, dispatch)
)(NotLoggedInNewOrderPage);
