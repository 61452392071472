import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './Loading.module.css';
type Props = {
  className: any;
  color: any;
};
class Loading extends React.PureComponent<Props> {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };
  public static defaultProps = {
    className: null,
    color: '#95a5a6',
  };
  render() {
    const { className, color } = this.props;
    return (
      <div className={`${className} ${s.skFadingCircle}`}>
        <style>{`.${s.skCircle}:before { background-color: ${color}}`}</style>
        <div className={cx(s.skCircle1, s.skCircle)} />
        <div className={cx(s.skCircle2, s.skCircle)} />
        <div className={cx(s.skCircle3, s.skCircle)} />
        <div className={cx(s.skCircle4, s.skCircle)} />
        <div className={cx(s.skCircle5, s.skCircle)} />
        <div className={cx(s.skCircle6, s.skCircle)} />
        <div className={cx(s.skCircle7, s.skCircle)} />
        <div className={cx(s.skCircle8, s.skCircle)} />
        <div className={cx(s.skCircle9, s.skCircle)} />
        <div className={cx(s.skCircle10, s.skCircle)} />
        <div className={cx(s.skCircle11, s.skCircle)} />
        <div className={cx(s.skCircle12, s.skCircle)} />
      </div>
    );
  }
}

// Loading.propTypes = {
//   className: PropTypes.string,
//   color: PropTypes.string,
// };

// Loading.defaultProps = {
//   className: null,
//   color: '#95a5a6',
// };

export default Loading;
