import $ from 'jquery';
import { Country } from './constants';

export function getQueryParams() {
  // eslint-disable-next-line no-restricted-globals
  const query = location.search.substr(1);
  const result = {};
  query.split('&').forEach(part => {
    const item = part.split('=');
    const key = item[0];
    const value = decodeURI(encodeURI(decodeURIComponent(item[1])));
    if (key && value) {
      result[key] = value;
    }
  });
  return result;
}

export function getCountryFromIP(callback) {
  $.ajax({
    url: 'https://ipinfo.io',
    dataType: 'jsonp',
    timeout: 5000,
    success: response => {
      let country;
      if (response.country === 'KW') {
        country = Country.kuwait;
      } else if (response.country === 'SA') {
        country = Country.SaudiArabia;
      } else {
        country = Country.uae;
      }
      if (typeof callback === 'function') {
        callback(null, country);
      }
    },
    error: e => {
      if (typeof callback === 'function') {
        callback(e);
      }
    },
  });
}
