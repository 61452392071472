import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '../components/Button';
import AddressForm from '../components/AddressForm';
import Appointment from '../components/Appointment';
import { ACTION_START } from '../data/constants';
import {
  newRequestActions,
  appActions,
  configActions,
  addressActions,
} from '../data/actions';
import NewAddressForm from '../components/AddressForm/NewAddressForm';
type Props = {
  addresses: any;
  newRequest: any;
  setRequestAddress: any;
  config: any;
  user: any;
  setRequestTimeSlot: any;
  setRequestDate: any;
  confirmRequest: any;
  routeTo: any;
  getBusyslots: any;
  addAddress: any;
};

type State = {
  currentCityId: any;
  isSlotValid: boolean;
  isAddressNew: boolean;
};
// eslint-disable-next-line react/prefer-stateless-function
class RequestAppointment extends React.Component<Props, State> {
  static propTypes = {
    getBusyslots: PropTypes.func.isRequired,
    setRequestTimeSlot: PropTypes.func.isRequired,
    setRequestDate: PropTypes.func.isRequired,
    setRequestAddress: PropTypes.func.isRequired,
    confirmRequest: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    newRequest: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    addresses: PropTypes.arrayOf(PropTypes.object).isRequired,
    addAddress: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentCityId: null,
      isSlotValid: false,
      isAddressNew: false,
    };
  }

  appointment: any;
  componentDidMount() {
    const { addresses, newRequest, setRequestAddress } = this.props;

    if (newRequest && newRequest.address && !newRequest.address.area) {
      setRequestAddress(addresses[0]);
    }
  }

  confirm = () => {
    const { newRequest, setRequestAddress } = this.props;
    if (this.state.isAddressNew) {
      this.props.addAddress(
        newRequest.address.name,
        newRequest.address.line1,
        newRequest.address.area,
        newRequest.address.cityId,
        res => {
          console.log(res);
          const address = {
            ...newRequest.address,
            id: res.id,
          };
          setRequestAddress(address);
          this.props.confirmRequest(() => this.props.routeTo('request-status'));
        }
      );
    } else {
      this.props.confirmRequest(() => this.props.routeTo('request-status'));
    }
  };

  render() {
    const {
      newRequest,
      config,
      user,
      setRequestTimeSlot,
      setRequestDate,
      setRequestAddress,
      confirmRequest,
      routeTo,
      getBusyslots,
    } = this.props;
    /*const isSlotValid =
      this.appointment &&
      this.appointment.isSlotValid(newRequest.date, newRequest.timeSlot);*/
    const isDisabled =
      newRequest && newRequest.address
        ? !this.state.isSlotValid ||
          !newRequest.address.cityId ||
          !newRequest.address.area ||
          !newRequest.address.line1
        : true;

    return (
      <div>
        <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">
          Appointment Details
        </h2>

        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-6 m-b-20">
            <Appointment
              ref={app => {
                this.appointment = app;
              }}
              cityId={config.cityId}
              getBusyslots={getBusyslots}
              timeslots={config.timeslots}
              selectedDate={newRequest.date}
              selectedTimeSlot={newRequest.timeSlot}
              onDateChange={setRequestDate}
              onTimeSlotChange={setRequestTimeSlot}
              currentCityId={this.state.currentCityId}
              getIsSlotValid={val => {
                this.setState({ isSlotValid: val });
              }}
            />
          </div>
          <div className="col-span-12 md:col-span-6 m-b-20">
            <div className="box center p-20">
              <AddressForm
                title={'Address'}
                cityId={config.cityId}
                addresses={user.addresses || []}
                selectedAddress={newRequest.address}
                onChange={setRequestAddress}
                isAddressNew={this.state.isAddressNew}
                setIsNewAddress={val => {
                  this.setState({ isAddressNew: val });
                }}
                getCurrentCityId={id => {
                  this.setState({ currentCityId: id });
                }}
              />
              {/*<NewAddressForm />*/}
            </div>
          </div>
        </div>

        <Button
          className="block center"
          loading={newRequest.status === ACTION_START}
          onClick={this.confirm}
          disabled={isDisabled}
          text="Confirm"
          big
        />
      </div>
    );
  }
}

// RequestAppointment.propTypes = {
//     getBusyslots: PropTypes.func.isRequired,
//     setRequestTimeSlot: PropTypes.func.isRequired,
//     setRequestDate: PropTypes.func.isRequired,
//     setRequestAddress: PropTypes.func.isRequired,
//     confirmRequest: PropTypes.func.isRequired,
//     routeTo: PropTypes.func.isRequired,
//     newRequest: PropTypes.object.isRequired,
//     config: PropTypes.object.isRequired,
//     user: PropTypes.object.isRequired,
//     addresses: PropTypes.arrayOf(PropTypes.object).isRequired,
// };

const RequestAppointmentContainer = connect(
  state => ({
    config: (state as any).config,
    newRequest: (state as any).newRequest,
    user: (state as any).user,
    addresses: (state as any).addresses,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...newRequestActions,
        ...appActions,
        ...configActions,
        ...addressActions,
      },
      dispatch
    )
)(RequestAppointment);

export default RequestAppointmentContainer;
