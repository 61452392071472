import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PaymentMode } from '../constants';
import { newHashActions } from '../data/actions';
import Loading from '../components/Loading';
type Props = {
  newCard: any;
  settings: any;
  computeHash: any;
  user: any;
  merchantReference: any;
  hash: any;
};
type State = {
  merchantReference: any;
  hash: any;
};

class CardVerify extends React.Component<Props, State> {
  static propTypes = {
    user: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    hash: PropTypes.string,
    newCard: PropTypes.object.isRequired,
    computeHash: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);

    const { newCard } = this.props;

    this.state = {
      hash: null,
      merchantReference: `VERIFY_CARD-${newCard.id}-${new Date().getTime()}`,
    };
  }

  componentDidMount() {
    const { computeHash, settings, newCard, user } = this.props;
    const { merchantReference } = this.state;
    const payfortSettings = settings.payfort;
    const {
      merchantId,
      saudiMerchentId,
      accessCode,
      saudiAccessCode,
      createTokenReturnUrl,
    } = payfortSettings || {};

    computeHash(PaymentMode.payfort, {
      command: 'PURCHASE',
      access_code: user.countryId === 3 ? saudiAccessCode : accessCode,
      merchant_identifier: user.countryId === 3 ? saudiMerchentId : merchantId,
      merchant_reference: merchantReference,
      amount: 100,
      currency: user.countryId === 3 ? 'SAR' : 'AED',
      language: 'en',
      token_name: newCard.tokenName,
      order_description: 'Boxit Storage Card Verification',
      return_url: createTokenReturnUrl,
      customer_email: user.email,
      customer_name: user.name ? user.name.substring(0, 40) : '',
      phone_number: user.phone,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.hash && nextProps.hash !== this.props.hash) {
      const { hash, user, settings, newCard } = nextProps;
      const { merchantReference } = this.state;
      const payfortSettings = settings.payfort;
      const {
        merchantId,
        saudiMerchantId,
        accessCode,
        saudiAccessCode,
        createTokenReturnUrl,
        recurringPaymentUrl,
      } = payfortSettings || {};

      const request = {
        command: 'PURCHASE',
        access_code: user.countryId === 3 ? saudiAccessCode : accessCode,
        merchant_identifier: merchantId,
        merchant_reference: merchantReference,
        amount: 100,
        currency: user.countryId === 3 ? 'SAR' : 'AED',
        language: 'en',
        token_name: newCard.tokenName,
        order_description: 'Boxit Storage Card Verification',
        return_url: createTokenReturnUrl,
        customer_email: user.email,
        customer_name: user.name ? user.name.substring(0, 40) : '',
        phone_number: user.phone,
      };

      return axios
        .post(recurringPaymentUrl, {
          ...request,
          signature: hash,
        })
        .then(response => {
          const data = response.data;
          console.log({ '3ds_url': data['3ds_url'] });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  render() {
    // const { newCard } = this.props;
    // const { merchantReference } = this.state;
    // const payfortSettings = settings.payfort;
    // const { merchantId, accessCode, createTokenReturnUrl, paymentUrl } = payfortSettings || {};
    // const { tokenName } = newCard || {};

    return (
      <div>
        <div className="">
          <h2 className="sub-title m-t-20 m-b-20">Redirecting...</h2>
          <div className="grid grid-cols-1 mx-auto md:w-1/2">
            <div className="box center text-center p-20">
              <Loading />
              {/* <form
              id="payfortForm"
              method="post"
              action={paymentUrl}
              >
              <input type="hidden" name="access_code" value={accessCode} />
              <input type="hidden" name="amount" value={100} />
              <input type="hidden" name="command" value="PURCHASE" />
              <input type="hidden" name="currency" value="AED" />
              <input type="hidden" name="customer_email" value={user.email} />
              <input type="hidden" name="customer_name" value={user.name} />
              <input type="hidden" name="phone_number" value={user.phone} />
              <input type="hidden" name="language" value="en" />
              <input type="hidden" name="token_name" value={tokenName} />
              <input type="hidden" name="merchant_identifier" value={merchantId} />
              <input type="hidden" name="merchant_reference" value={merchantReference} />
              <input type="hidden" name="order_description" value="Boxit Storage Card Verification" />
              <input type="hidden" name="return_url" value={createTokenReturnUrl} />
              <input id="signature" type="hidden" name="signature" value={hash} />
              </form> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CardVerify.propTypes = {
  user: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  hash: PropTypes.string,
  newCard: PropTypes.object.isRequired,
  computeHash: PropTypes.func.isRequired,
};

const CardVerifyContainer = connect(
  state => ({
    user: (state as any).user,
    hash: (state as any).newHash && (state as any).newHash.hash,
    newCard: (state as any).newCard,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...newHashActions,
      },
      dispatch
    )
)(CardVerify);

export default CardVerifyContainer;
