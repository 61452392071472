import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '../core/utils/money';

const Money = ({ amount, currency, showCurrency, skipEmptyFraction }) => (
  <span>
    {showCurrency ? currency : ''}{' '}
    {formatMoney(amount, currency, skipEmptyFraction)}
  </span>
);

Money.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  showCurrency: PropTypes.bool,
  skipEmptyFraction: PropTypes.bool,
};

Money.defaultProps = {
  showCurrency: false,
  skipEmptyFraction: false,
};

export default Money;
