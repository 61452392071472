import {
  ADD_SURVEY_PHOTOS,
  ACTION_RESET,
  PHOTOS_SURVEY_GET,
  ACTION_SUCCESS,
  DELETE_SURVEY_PHOTO,
} from '../constants';
import errorHandleActions from '../actions/errorHandle';

const getSurveyPhotos = surveyId => (dispatch, getState) => {
  dispatch({
    type: PHOTOS_SURVEY_GET,
    apiCall: {
      method: 'GET',
      path: `survey-photos`,
      queryParams: {
        surveyId,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const addPhotos = (
  surveyId,
  userId,
  countryId,
  partnerId,
  files
) => dispatch => {
  files.forEach(file => {
    dispatch({
      type: ADD_SURVEY_PHOTOS,
      apiCall: {
        method: 'POST',
        path: 'survey-photos',
        params: {
          surveyId,
          userId,
          countryId,
          partnerId,
          file,
        },
        callback: (err, res) => {
          setTimeout(() => {
            dispatch({
              type: ADD_SURVEY_PHOTOS,
              status: ACTION_RESET,
              params: {
                surveyId,
              },
            });
            dispatch(getSurveyPhotos(surveyId));
          }, 1000);
          if (err) {
            dispatch(
              errorHandleActions.setError(
                true,
                err.response.data.message || 'unknown error'
              )
            );
          }
        },
      },
    });
  });
};

const deletePhoto = (surveyId, photoId) => dispatch => {
  dispatch({
    type: DELETE_SURVEY_PHOTO,
    apiCall: {
      method: 'DELETE',
      path: `survey-photos/${photoId}`,
      callback: (err, res) => {
        dispatch(getSurveyPhotos(surveyId));
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

export default {
  addPhotos,
  getSurveyPhotos,
  deletePhoto,
};
