import {
  ADDRESSES_GET,
  ACTION_START,
  ACTION_FAILED,
  ACTION_SUCCESS,
} from '../constants';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADDRESSES_GET:
      if (action.status === ACTION_START) {
        const addresses = Object.assign([...state], { isFetching: true });
        return addresses;
      }
      if (action.status === ACTION_SUCCESS) {
        const addresses = action.data.data;
        addresses.isFetching = false;
        return addresses;
      }
      if (action.status === ACTION_FAILED) {
        const addresses = Object.assign([...state], { isFetching: false });
        return addresses;
      }
      return state;

    default:
      return state;
  }
}
