import {
  STORAGES_GET,
  STORAGE_UPDATE,
  ACTION_START,
  ACTION_FAILED,
  ACTION_SUCCESS,
} from '../constants';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORAGES_GET:
      if (action.status === ACTION_START) {
        const storages = Object.assign([...state], { isFetching: true });
        return storages;
      }
      if (action.status === ACTION_SUCCESS) {
        const storages = action.data.data || [];
        storages.total = action.data.total;
        storages.pageSize = action.data.limit;
        storages.isFetching = false;
        return storages;
      }
      if (action.status === ACTION_FAILED) {
        const storages = Object.assign([...state], { isFetching: false });
        return storages;
      }
      return state;

    case STORAGE_UPDATE: {
      if (action.status === ACTION_SUCCESS) {
        const storages = state;
        const { id, name } = action.data;
        const storage = storages.find(s => s.id === id);
        storage.name = name;
        return [...storages];
      }
      return state;
    }

    default:
      return state;
  }
}
