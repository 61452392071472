import React from 'react';
import PropTypes from 'prop-types';
import s from './ErrorPopup.module.scss';

type Props = {
  error: any;
  setError: any;
};

class ErrorContainer extends React.PureComponent<any> {
  static propTypes = {
    error: PropTypes.object,
    setError: PropTypes.func.isRequired,
  };
  public static defaultProps = {
    text: 'Error',
  };

  componentDidMount() {
    setTimeout(() => {
      this.props.setError(false, null);
    }, 3000);
  }

  render() {
    const { error, ...props } = this.props;

    return (
      <div className={s.error}>
        {error.errorText}
        <button>close</button>
      </div>
    );
  }
}

export default ErrorContainer;
