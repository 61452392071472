import React from 'react';
import PropTypes from 'prop-types';
import FBLogin from 'react-facebook-login';
import s from './styles.module.css';
type Props = {
  appId: any;
  onLogin: any;
  buttonText: any;
};
class FacebookLogin extends React.PureComponent<Props> {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    onLogin: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
  };
  public static defaultProps = {
    buttonText: 'Login With Facebook',
  };
  render() {
    const { appId, onLogin, buttonText } = this.props;
    return (
      <FBLogin
        appId={appId}
        fields="name,first_name,last_name,gender,email,link,locale,age_range"
        scope="public_profile,email"
        cssClass={`${s.btnFacebook} m-b-20`}
        callback={onLogin}
        textButton={buttonText}
      />
    );
  }
}

// FacebookLogin.propTypes = {
//   appId: PropTypes.string.isRequired,
//   onLogin: PropTypes.func.isRequired,
//   buttonText: PropTypes.string,
// };

// FacebookLogin.defaultProps = {
//   buttonText: 'Login With Facebook',
// };

export default FacebookLogin;
