import { push } from 'connected-react-router';
import routes from '../../routes';
import { ERROR_HANDLE } from '../constants';

const routeTo = (routeName, params = {}, queryParams = {}) => dispatch => {
  const route = routes.find(r => r.name === routeName);
  if (!route) {
    console.error('No route found for: ', routeName);
    const errorRoute = routes.find(r => r.name === 'error');
    return dispatch(push(errorRoute.path));
  }

  let path = route.path;
  const urlParams = path.match(/(:[\w\(\)\\\+\*\.\?]+)+/g); // eslint-disable-line no-useless-escape
  if (urlParams) {
    urlParams.forEach(u => {
      const key = u.replace(':', '').replace('?', '');
      const value = (params && params[key]) || '';
      path = path.replace(`:${key}`, value).replace(`:${key}?`, value);
    });
  }

  if (typeof queryParams === 'object') {
    Object.keys(queryParams).forEach((k, i) => {
      if (i === 0) {
        path = `${path}?${k}=${queryParams[k]}`;
      } else {
        path = `${path}&${k}=${queryParams[k]}`;
      }
    });
  }

  return dispatch(push(path));
};

const setError = (isError, errorText) => dispatch => {
  dispatch({
    type: ERROR_HANDLE,
    isError,
    errorText,
  });
};

export default {
  routeTo,
  setError,
};
