import {
  NEW_PAYMENT,
  NEW_PAYMENT_COMPUTE_HASH,
  NEW_PAYMENT_CREATE,
  NEW_PAYMENT_PROCESS,
  ACTION_SUCCESS,
  ACTION_RESET,
} from '../constants';

const initialState = {
  hashStatus: {},
  processStatus: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case NEW_PAYMENT_CREATE:
      if (action.status === ACTION_SUCCESS) {
        return {
          ...action.data,
          status: action.status,
        };
      }
      return initialState;

    case NEW_PAYMENT_COMPUTE_HASH:
      if (action.status === ACTION_SUCCESS) {
        return {
          ...state,
          hashStatus: {
            status: action.status,
            hash: action.data ? action.data.hash : null,
          },
        };
      }
      return {
        ...state,
        hashStatus: {
          status: action.status,
        },
      };

    case NEW_PAYMENT_PROCESS:
      return {
        ...state,
        processStatus: {
          status: action.status,
        },
      };

    case NEW_PAYMENT:
      if (action === ACTION_RESET) {
        return initialState;
      }
      return state;

    default:
      return state;
  }
}
