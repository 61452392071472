import { CHARGES_GET } from '../constants';
import errorHandleActions from '../actions/errorHandle';

const getCharges = () => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: CHARGES_GET,
    apiCall: {
      method: 'GET',
      path: 'charges',
      queryParams: {
        userId: user.userId,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

export default {
  getCharges,
};
