import app from './app';
import config from './config';
import newOrder from './newOrder';
import storages from './storages';
import photos from './photos';
import user from './user';
import invoices from './invoices';
import payments from './payments';
import requests from './requests';
import cards from './cards';
import charges from './charges';
import addresses from './addresses';
import newRequest from './newRequest';
import newPayment from './newPayment';
import newCard from './newCard';
import newHash from './newHash';
import errorHandle from './errorHandle';
import survey from './survey';
import storageParts from './storageParts';

export default {
  app,
  config,
  user,
  storages,
  photos,
  invoices,
  payments,
  requests,
  cards,
  charges,
  addresses,
  newOrder,
  newRequest,
  newPayment,
  newCard,
  newHash,
  errorHandle,
  survey,
  storageParts,
};
