export const AddressType = {
  home: 'home',
  office: 'office',
};

export const Country = {
  uae: 'UAE',
  kuwait: 'Kuwait',
  SaudiArabia: 'ksa',
};

export const CountryCode = {
  uae: 'AE',
  kuwait: 'KW',
  SaudiArabia: 'SA',
};

export const City = {
  dubai: 'Dubai',
  abudhabi: 'Abu Dhabi',
  sharjah: 'Sharjah',
  ajman: 'Ajman',
  alain: 'Al Ain',
  kuwait: 'Kuwait',
  riyadh: 'riyadh',
};

export const City2Country = {
  dubai: 'UAE',
  abudhabi: 'UAE',
  'Abu Dhabi': 'UAE',
  sharjah: 'UAE',
  ajman: 'UAE',
  alain: 'UAE',
  'Al Ain': 'UAE',
  kuwait: 'Kuwait',
  riyadh: 'ksa',
};

export const TZ = {
  UAE: 'Asia/Dubai',
  Dubai: 'Asia/Dubai',
  AbuDhabi: 'Asia/Dubai',
  Sharjah: 'Asia/Dubai',
  Ajman: 'Asia/Dubai',
  AlAin: 'Asia/Dubai',
  Kuwait: 'Asia/Kuwait',
  riyadh: 'Asia/Riyadh',
};

export const Currency = {
  uae: 'AED',
  kuwait: 'KWD',
  saudi: 'SAR',
};

export const StorageType = {
  BB: 'BB',
  LG: 'LG',
  BEDSIDE_TABLE: 'BEDSIDE_TABLE',
  BOX_S: 'BOX_S',
  BOX_M: 'BOX_M',
  BOX_L: 'BOX_L',
  CHAIR: 'CHAIR',
  CLOTHES_DRYER_RACK: 'CLOTHES_DRYER_RACK',
  CLOTHES_HANGER_STAND: 'CLOTHES_HANGER_STAND',
  COFFEE_TABLE: 'COFFEE_TABLE',
  BED_SINGLE: 'BED_SINGLE',
  BED_QUEEN: 'BED_QUEEN',
  BED_KING: 'BED_KING',
  WARDROBE_2D: 'WARDROBE_2D',
  WARDROBE_3D: 'WARDROBE_3D',
  WARDROBE_4D: 'WARDROBE_4D',
  CURTAIN: 'CURTAIN',
  DINING_TABLE_4S: 'DINING_TABLE_4S',
  DINING_TABLE_6S: 'DINING_TABLE_6S',
  DRAWERS_S: 'DRAWERS_S',
  DRAWERS_M: 'DRAWERS_M',
  DRAWERS_L: 'DRAWERS_L',
  DRESSING_TABLE_S: 'DRESSING_TABLE_S',
  DRESSING_TABLE_M: 'DRESSING_TABLE_M',
  DRESSING_TABLE_L: 'DRESSING_TABLE_L',
  FAN: 'FAN',
  FRIDGE_S: 'FRIDGE_S',
  FRIDGE_M: 'FRIDGE_M',
  FRIDGE_L: 'FRIDGE_L',
  MATTRESS_SINGLE: 'MATTRESS_SINGLE',
  MATTRESS_QUEEN: 'MATTRESS_QUEEN',
  MATTRESS_KING: 'MATTRESS_KING',
  MATTRESS_HEAVY: 'MATTRESS_HEAVY',
  MICROWAVE_S: 'MICROWAVE_S',
  MICROWAVE_L: 'MICROWAVE_L',
  SHOE_RACK_S: 'SHOE_RACK_S',
  SHOE_RACK_M: 'SHOE_RACK_M',
  SHOE_RACK_L: 'SHOE_RACK_L',
  SOFA_1S: 'SOFA_1S',
  SOFA_2S: 'SOFA_2S',
  SOFA_3S: 'SOFA_3S',
  STROLLER: 'STROLLER',
  SUITCASE_M: 'SUITCASE_M',
  TREADMILL: 'TREADMILL',
  TV_S: 'TV_S',
  TV_M: 'TV_M',
  TV_L: 'TV_L',
  TV_STAND_S: 'TV_STAND_S',
  TV_STAND_M: 'TV_STAND_M',
  TV_STAND_L: 'TV_STAND_L',
  WASHING_MACHINE: 'WASHING_MACHINE',
};

export const StorageStatus = {
  new: 'new',
  forpickup: 'forpickup',
  pickedup: 'pickedup',
  in: 'in',
  stored: 'stored',
  fordelivery: 'fordelivery',
  out: 'out',
  delivered: 'delivered',
  closed: 'closed',
  cancelled: 'cancelled',
  unknown: 'unknown',
};

export const RequestStatus = {
  new: 'new',
  confirmed: 'confirmed',
  onhold: 'onhold',
  pending: 'pending',
  approved: 'approved',
  transit: 'transit',
  received: 'received',
  delivered: 'delivered',
  complete: 'complete',
  rejected: 'rejected',
  cancelled: 'cancelled',
};

export const RequestType = {
  newbox: 'newbox',
  pickup: 'pickup',
  delivery: 'delivery',
  drop: 'drop',
  collect: 'collect',
  close: 'close',
  survey: 'survey',
  scrap: 'scrap',
  transfer: 'transfer',
  moving: 'moving',
};

export const PaymentMode = {
  cod: 'cod',
  credits: 'credits',
  tap: 'tap',
  payfort: 'payfort',
};

export const PaymentStatus = {
  pending: 'pending',
  complete: 'complete',
  failed: 'failed',
};

export const OfferType = {
  discount: 'discount',
  percent: 'percent',
};

export const InvoiceItemType = {
  rent: 'rent',
  delivery: 'delivery',
  coupon: 'coupon',
  credits: 'credits',
  discount: 'discount',
};

export const InvoiceStatus = {
  unpaid: 'unpaid',
  paid: 'paid',
  partial: 'partial',
  cancelled: 'cancelled',
};

export const UserStatus = {
  new: 'new',
  active: 'active',
  inactive: 'inactive',
  rejected: 'rejected',
  onhold: 'onhold',
};
