import { REQUEST_GET, REQUESTS_GET, REQUESTS_UPDATE } from '../constants';
import errorHandleActions from '../actions/errorHandle';

const getRequest = (id, accessToken) => (dispatch, getState) => {
  dispatch({
    type: REQUEST_GET,
    apiCall: {
      method: 'GET',
      path: `requests/${id}`,
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const getRequests = () => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: REQUESTS_GET,
    apiCall: {
      method: 'GET',
      path: 'requests',
      queryParams: {
        userId: user.userId,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const updateRequest = (id, params, accessToken) => dispatch => {
  dispatch({
    type: REQUESTS_UPDATE,
    apiCall: {
      method: 'PATCH',
      path: accessToken
        ? `requests-access/${id}?accessToken=${accessToken}`
        : `requests/${id}`,
      params,
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

export default {
  getRequest,
  getRequests,
  updateRequest,
};
