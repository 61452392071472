import React from 'react';
import PropTypes from 'prop-types';
import s from './styles.module.css';
type Props = {
  current: any;
  routeTo: any;
};
class OrderSteps extends React.PureComponent<Props> {
  static propTypes = {
    current: PropTypes.string.isRequired,
    routeTo: PropTypes.func.isRequired,
  };
  render() {
    const { current, routeTo } = this.props;

    let clsOrder = '';
    let clsDateTime = '';
    let clsPay = '';
    let clkOrder;
    let clkDateTime;
    let clkPay;

    if (routeTo) {
      clkOrder = () => {
        routeTo('order-storage');
      };
      clkDateTime = () => {
        routeTo('order-appointment');
      };
      clkPay = () => {
        routeTo('order-confirm');
      };
    }

    if (current === 'storage') {
      clsOrder = `${clsOrder} ${s.active}`;
      clkDateTime = null;
      clkPay = null;
    } else if (current === 'details') {
      clsOrder = `${clsOrder} ${s.done}`;
      clsDateTime = `${clsDateTime} ${s.active}`;
      clkPay = null;
    } else if (current === 'confirm') {
      clsOrder = `${clsOrder} ${s.done}`;
      clsDateTime = `${clsDateTime} ${s.done}`;
      clsPay = `${clsPay} ${s.active}`;
    }

    return (
      <div className={`${s.progWrap} clearfix`}>
        <ul className={s.progBar}>
          <li className={clsOrder} onClick={clkOrder}>
            Storage
          </li>
          <li className={clsDateTime} onClick={clkDateTime}>
            Details
          </li>
          <li className={clsPay} onClick={clkPay}>
            Billing
          </li>
        </ul>
      </div>
    );
  }
}

// OrderSteps.propTypes = {
//     current: PropTypes.string.isRequired,
//     routeTo: PropTypes.func.isRequired,
// };

export default OrderSteps;
