import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '../components/Button';
import s from './CardStatus.module.css';
import { newCardActions, cardActions, appActions } from '../data/actions';

type Props = {
  cards: any;
  getCard: any;
  routeTo: any;
  resetCard: any;
  urlParams: any;
};

class CardStatus extends React.Component<Props> {
  static propTypes = {
    newCard: PropTypes.object.isRequired,
    resetOrder: PropTypes.func.isRequired,
    resetCard: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    urlParams: PropTypes.object.isRequired,
    cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  };
  componentDidMount() {
    const cardId = this.props.urlParams.cardId;
    this.props.getCard(cardId);
  }

  render() {
    const { urlParams, cards, resetCard, routeTo } = this.props;
    const cardId = parseInt(urlParams.cardId, 10);
    const card = (cards || []).find(p => p && p.id === cardId);
    console.log(cards);

    return (
      <div>
        <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">
          Status
        </h2>
        <div className="grid grid-cols-1 mx-auto md:w-1/2">
          <div className="m-b-20">
            <div className="box center text-center p-20">
              {card && card.status === 'active' && (
                <div>
                  <h3 className="text-3xl">Success</h3>
                  <i className="fa fa-5x fa-check-circle-o green m-10 m-t-0" />
                  <p className="text-lg">Card has been added to your account</p>
                </div>
              )}
              {card && card.status !== 'active' && (
                <div>
                  <h3 className="text-3xl">Error</h3>
                  <i className="fa fa-5x fa-times red m-10 m-t-0" />
                  <p className={s.statusText}>
                    We were unable to process your card.
                    <br />
                  </p>
                </div>
              )}
              {card && card.status === 'active' && (
                <button
                  className={`mt-6 px-12 py-2 bg-green-500 hover:bg-green-400 text-white rounded text-lg`}
                  type="button"
                  onClick={() => {
                    resetCard();
                    routeTo('home');
                  }}
                >
                  Done
                </button>
              )}
              {card && card.status !== 'active' && (
                <button
                  className={`mt-6 px-12 py-2 bg-green-500 hover:bg-green-400 text-white rounded text-lg`}
                  type="button"
                  onClick={() => {
                    if (card && card.mode === 'payfort') {
                      routeTo('card-payfort');
                    } else if (card && card.mode === 'tap') {
                      routeTo('card-tap');
                    }
                  }}
                >
                  Try Again
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const CardStatusContainer = connect(
  state => ({ newCard: (state as any).newCard, cards: (state as any).cards }),
  dispatch =>
    bindActionCreators(
      {
        ...cardActions,
        ...newCardActions,
        ...appActions,
      },
      dispatch
    )
)(CardStatus);

export default CardStatusContainer;
