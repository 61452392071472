import React from 'react';
import PropTypes from 'prop-types';
import Link from '../components/Link';
import s from './styles.module.css';
type Props = {
  error: any;
};
class ErrorPage extends React.Component<Props> {
  static propTypes = {
    error: PropTypes.object,
  };
  public static defaultProps = {
    error: {
      message: 'Unexpected error occured',
    },
  };
  componentDidMount() {
    document.title =
      this.props.error && this.props.error.status === 404
        ? 'Page Not Found'
        : 'Error';
  }

  render() {
    if (this.props.error) console.error(this.props.error); // eslint-disable-line no-console

    const [code, title] = ['404', 'Page not found'];
    /*
    const [code, title] =
      this.props.error && this.props.error.status === 404
        ? ['404', 'Page not found']
        : ['Error', 'Oops, something went wrong'];
        */

    return (
      <div className={s.container}>
        <main className={s.content}>
          <h1 className={s.code}>{code}</h1>
          <p className={s.title}>{title}</p>
          {code === '404' && (
            <p className={s.text}>
              The page you&apos;re looking for does not exist or an another
              error occurred.
            </p>
          )}
          <p className={s.text}>
            <Link to="/">Go back to home page</Link>
          </p>
        </main>
      </div>
    );
  }
}

export default ErrorPage;
