import {
  ACTION_START,
  ACTION_SUCCESS,
  ACTION_FAILED,
  ACTION_RESET,
  LOGOUT,
  SIGNUP,
  FB_LOGIN,
  LOGIN,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUBMIT,
  MAGIC_LINK_REQUEST,
  MAGIC_LINK_SUBMIT,
  USER_GET,
  USER_CHANGE_PASWORD,
  CITIES_GET,
  ADDRESSES_USER_GET,
  CITIES_ALL_GET,
} from '../constants';

const initialState = {
  isLoggedIn: false,
  accessToken: null,
  userId: null,
  user: {},
  loginStatus: {},
  signupStatus: {},
  signupFBStatus: {},
  facebookLogin: {},
  changePasswordStatus: {},
  resetPasswordRequest: {},
  resetPasswordSubmit: {},
  cities: [],
  allCities: [],
  userAddresses: [],
};

export default function reducer(state = initialState, action) {
  const loginStart = () => ({
    ...state,
    loginStatus: {
      status: action.status,
    },
    isLoggedIn: false,
  });

  const loginFailed = () => ({
    ...state,
    loginStatus: {
      status: action.status,
      error: action.error,
    },
    isLoggedIn: false,
  });

  const loginSuccess = () => {
    const { accessToken, user } = action.data;
    const userId = user.id;

    if (userId === 'new') {
      return state;
    }

    return {
      ...state,
      loginStatus: {
        status: action.status,
        error: action.error,
      },
      isLoggedIn: true,
      userId,
      user,
      accessToken,
    };
  };

  switch (action.type) {
    case LOGIN:
      if (action.status === ACTION_START) {
        return loginStart();
      } else if (action.status === ACTION_SUCCESS) {
        return loginSuccess();
      } else if (action.status === ACTION_FAILED) {
        return loginFailed();
      } else if (action.status === ACTION_RESET) {
        return {
          ...state,
          loginStatus: initialState.loginStatus,
          isLoggedIn: false,
          accessToken: null,
          userId: null,
        };
      }
      return state;

    case FB_LOGIN:
      if (action.status === ACTION_START) {
        return {
          ...loginStart(),
          facebookLogin: action.params || {},
        };
      } else if (action.status === ACTION_SUCCESS) {
        return {
          ...loginSuccess(),
          facebookLogin: action.params || {},
        };
      } else if (action.status === ACTION_FAILED) {
        return {
          ...loginFailed(),
          facebookLogin: action.params || {},
        };
      }
      return state;

    case SIGNUP:
      if (action.status === ACTION_START) {
        return {
          ...state,
          signupStatus: {
            status: action.status,
          },
        };
      } else if (action.status === ACTION_SUCCESS) {
        return {
          ...state,
          signupStatus: {
            status: action.status,
          },
        };
      } else if (action.status === ACTION_FAILED) {
        return {
          ...state,
          signupStatus: {
            status: action.status,
            error: action.error,
          },
        };
      } else if (action.status === ACTION_RESET) {
        return {
          ...state,
          signupStatus: initialState.signupStatus,
        };
      }
      return {
        ...state,
      };

    case RESET_PASSWORD_REQUEST:
      if (action.status === ACTION_START) {
        return {
          ...state,
          resetPasswordRequest: {
            status: action.status,
          },
        };
      } else if (action.status === ACTION_SUCCESS) {
        return {
          ...state,
          resetPasswordRequest: {
            status: action.status,
          },
        };
      } else if (action.status === ACTION_FAILED) {
        return {
          ...state,
          resetPasswordRequest: {
            status: action.status,
            error: action.error,
          },
        };
      } else if (action.status === ACTION_RESET) {
        return {
          ...state,
          resetPasswordRequest: {},
        };
      }
      return {
        ...state,
      };

    case RESET_PASSWORD_SUBMIT:
      if (action.status === ACTION_START) {
        return {
          ...state,
          resetPasswordSubmit: {
            status: action.status,
          },
        };
      } else if (action.status === ACTION_SUCCESS) {
        return {
          ...state,
          resetPasswordSubmit: {
            status: action.status,
          },
        };
      } else if (action.status === ACTION_FAILED) {
        return {
          ...state,
          resetPasswordSubmit: {
            status: action.status,
            error: action.error,
          },
        };
      } else if (action.status === ACTION_RESET) {
        return {
          ...state,
          resetPasswordSubmit: {},
        };
      }
      return {
        ...state,
      };

    case MAGIC_LINK_REQUEST:
      if (action.status === ACTION_START) {
        return {
          ...state,
          magicLinkRequest: {
            status: action.status,
          },
        };
      } else if (action.status === ACTION_SUCCESS) {
        return {
          ...state,
          magicLinkRequest: {
            status: action.status,
          },
        };
      } else if (action.status === ACTION_FAILED) {
        return {
          ...state,
          magicLinkRequest: {
            status: action.status,
            error: action.error,
          },
        };
      } else if (action.status === ACTION_RESET) {
        return {
          ...state,
          magicLinkRequest: {},
        };
      }
      return {
        ...state,
      };

    case MAGIC_LINK_SUBMIT:
      if (action.status === ACTION_START) {
        return {
          ...state,
          magicLinkSubmit: {
            status: action.status,
          },
        };
      } else if (action.status === ACTION_SUCCESS) {
        return {
          ...loginSuccess(),
          magicLinkSubmit: {
            status: action.status,
          },
        };
      } else if (action.status === ACTION_FAILED) {
        return {
          ...state,
          magicLinkSubmit: {
            status: action.status,
            error: action.error,
          },
        };
      }
      return state;

    case USER_GET:
      if (action.status === ACTION_SUCCESS) {
        return {
          ...state,
          isLoggedIn: true,
          user: action.data,
        };
      }
      return state;

    // case USER_UPDATE:
    //   if (action.status === ACTION_SUCCESS) {
    //     return {
    //       ...state,
    //       user: action.data,
    //     };
    //   }
    //   return state;

    case USER_CHANGE_PASWORD:
      return {
        ...state,
        // ...(action.status === ACTION_SUCCESS) && { ...loginSuccess() },
        changePasswordStatus: {
          status: action.status,
          error: action.error,
        },
      };

    case LOGOUT:
      return {
        ...state,
        loginStatus: initialState.loginStatus,
        isLoggedIn: false,
        accessToken: null,
        userId: null,
      };

    case CITIES_GET:
      if (action.status === ACTION_SUCCESS) {
        let cities = action.data.data.map(city => {
          return { key: city.name, value: city.id };
        });
        cities = [{ key: 'Select City', value: undefined }, ...cities];
        console.log(cities);
        return {
          ...state,
          cities,
        };
      } else {
        return state;
      }

    case CITIES_ALL_GET:
      if (action.status === ACTION_SUCCESS) {
        const allCities = action.data.data;
        return {
          ...state,
          allCities,
        };
      } else {
        return state;
      }

    case ADDRESSES_USER_GET:
      if (action.status === ACTION_SUCCESS) {
        const userAddresses =
          action.data
            .data; /*.map(address => {
          return { key: address.name, value: address.name };
        });*/
        /*userAddresses = [
          { key: 'Select Address', value: undefined },
          ...userAddresses,
        ];*/
        return {
          ...state,
          userAddresses,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
}
