import {
  createStore,
  combineReducers,
  applyMiddleware,
  Middleware,
  Reducer,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';
import persistState from 'redux-localstorage';
import history from '../history';
import reducers from './reducers';
import apiMiddleware from './apiMiddleware';

const globalMiddleware = store => next => action => {
  next({ ...action, globalState: store.getState });
};

function configureStore() {
  const routeMiddleware = routerMiddleware(history);

  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');
  const userid = query.get('userid');

  if (userid) {
    localStorage.setItem('userId', userid);
  }

  if (token) {
    localStorage.setItem('feathers-jwt', token);
  }

  const slicer = () => state => {
    const subset = {
      newOrder: state.newOrder,
      //newRequest: state.newRequest,
      user: {
        isLoggedIn: userid && token ? true : state.user.isLoggedIn,
        accessToken: token ? token : state.user.accessToken,
        userId: userid ? userid : state.user.userId,
      },
    };
    return subset;
  };
  if (userid) {
    window.history.pushState({}, document.title, window.location.pathname);
    window.location.reload();
  }

  const enhancer = composeWithDevTools(
    applyMiddleware(
      routeMiddleware,
      apiMiddleware,
      thunk,
      globalMiddleware as Middleware
      //createLogger(),
    ),
    persistState(['newOrder', 'user'], {
      key: 'boxit',
      slicer,
    })
  );

  const store = createStore(
    combineReducers({
      ...reducers,
      router: connectRouter(history),
    }),
    {},
    enhancer
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextRootReducer = reducers;
      // @ts-ignore
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

export default configureStore();
