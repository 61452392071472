import React, { useState } from 'react';
import PropTypes, { any } from 'prop-types';

const Pagination = ({
  pageSize,
  total,
  paginate,
}: {
  pageSize: number;
  total: number;
  paginate: (number) => void;
}) => {
  const pageNumbers = [];
  const [currentPage, setCurrentPage] = useState(1);
  const handleClick = page => {
    setCurrentPage(page);
    paginate(page);
  };
  for (let i = 1; i <= Math.ceil(total / pageSize); i++) {
    pageNumbers.push(i);
  }
  console.log(total);
  return total ? (
    <div
      className="flex items-center justify-between mt-5 p-2 pl-3 space-x-4 rounded bg-white"
      style={{ marginLeft: '7.5px', marginRight: '7.5px' }}
    >
      <span className="text-gray-400">
        {(currentPage - 1) * pageSize + 1} to{' '}
        {Math.min(currentPage * pageSize, total)}
        <span className="hidden md:inline"> of {total} items</span>
      </span>
      <span>
        {pageNumbers.map((page, index) => {
          return (
            <span
              key={page}
              id={page}
              onClick={() => handleClick(page)}
              className={`${
                index === currentPage - 1
                  ? `z-10 bg-green-500 border-green-500 text-white inline-flex`
                  : `bg-gray-100 border-gray-300 text-gray-400 hover:text-white hover:bg-green-500 inline-flex`
              } relative items-center px-4 py-2 border text-sm font-medium cursor-pointer rounded-full`}
            >
              {page}
            </span>
          );
        })}
      </span>
    </div>
  ) : null;
};

Pagination.propTypes = {
  pageSize: PropTypes.number,
  total: PropTypes.arrayOf(PropTypes.number),
  paginate: PropTypes.func.isRequired,
};

export default Pagination;
