import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import $ from 'jquery';
import 'intl-tel-input';
import s from './styles.module.css';
import { City } from '../../constants';
import FacebookLogin from '../FacebookLogin';
import settings from '../../settings';
type Props = {
  cityId: any;
  onChange: any;
  fbLoginText: any;
  signupError: any;
};
type State = {
  name: any;
  email: any;
  password: any;
  address: any;
  isFacebook: any;
  fbLoginParams: any;
  phone: any;
};

class SignupForm extends React.Component<Props, State> {
  static propTypes = {
    cityId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    fbLoginText: PropTypes.string,
    signupError: PropTypes.string,
  };
  public static defaultProps = {
    includeAddress: false,
    fbLoginText: 'Login With Facebook',
    signupError: null,
  };
  phoneInput: any;
  constructor(props) {
    super(props);

    this.state = {
      isFacebook: false,
      name: '',
      email: '',
      phone: '',
      password: '',
      fbLoginParams: null,
      address: '',
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    const initialCountry =
      this.props.cityId === City.kuwait
        ? 'kw'
        : this.props.cityId === City.riyadh
        ? 'sa'
        : 'ae';
    if (this.phoneInput) {
      ($(this.phoneInput) as any).intlTelInput({
        onlyCountries: ['ae', 'kw', 'sa'],
        initialCountry,
      });
    }
  }

  getData() {
    const {
      name,
      email,
      password,
      address,
      isFacebook,
      fbLoginParams,
    } = this.state;
    const phone = ($(this.phoneInput) as any).intlTelInput('getNumber').trim();
    const countryData = ($('#phone') as any).intlTelInput(
      'getSelectedCountryData'
    );
    const countryCode = countryData && countryData.iso2;
    const countryId = countryCode === 'ae' ? 1 : countryCode === 'kw' ? 2 : 3;

    let isValid = name && this.validatePhone(phone) && validator.isEmail(email);
    if (isFacebook) {
      isValid = isValid && fbLoginParams && fbLoginParams.accessToken;
    } else {
      isValid = isValid && password;
    }
    return {
      name,
      phone,
      email,
      password,
      address,
      countryId,
      isValid,
      isFacebook,
      ...(isFacebook && { fbLoginParams }),
    };
  }

  validatePhone(phone) {
    if (phone.length < 5) {
      return false;
    }
    const phoneNumber = phone[0] === '+' ? phone.substring(1) : phone;
    return validator.isNumeric(phoneNumber);
  }

  render() {
    const { isFacebook, name, phone, email, password } = this.state;
    const { onChange, fbLoginText, signupError } = this.props;
    let existingUser = false;

    if (signupError === 'Validation error') {
      existingUser = true;
    }

    return (
      <div>
        <FacebookLogin
          appId={settings.facebook.appId}
          onLogin={params => {
            if (params.accessToken) {
              this.setState({
                isFacebook: true,
                name: params.name,
                email: params.email,
                fbLoginParams: params,
              });
            }
          }}
          buttonText={fbLoginText}
        />
        <hr />
        <div className="mb-6 m-t-20">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            value={name}
            placeholder="Full Name"
            onChange={e => {
              this.setState({ name: e.target.value }, () => {
                onChange(this.getData());
              });
            }}
          />
        </div>
        <div className={`${s.phoneInputGroup} mb-6`}>
          <label htmlFor="phone">Phone</label>
          <input
            type="text"
            className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            ref={input => {
              this.phoneInput = input;
            }}
            id="phone"
            value={phone}
            placeholder=""
            onChange={e => {
              this.setState({ phone: e.target.value }, () => {
                onChange(this.getData());
              });
            }}
          />
        </div>
        <div className="mb-6">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            value={email}
            placeholder="Email Address"
            onChange={e => {
              this.setState({ email: e.target.value }, () => {
                onChange(this.getData());
              });
            }}
            disabled={isFacebook}
          />
        </div>
        {!isFacebook && (
          <div className="mb-6">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              placeholder="Password"
              value={password}
              onChange={e => {
                this.setState({ password: e.target.value }, () => {
                  onChange(this.getData());
                });
              }}
            />
          </div>
        )}

        {signupError && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative  text-left p-0 p-v-10 mb-8 m-t-20"
            role="alert"
          >
            <p className="m-0">
              <i className="fa fa-info icon-small p-5 red" aria-hidden="true" />
              {existingUser && (
                <span>
                  Email/Phone already exists. Please{' '}
                  <a href="/login">Login Here</a>
                </span>
              )}
              {!existingUser && signupError}
            </p>
          </div>
        )}
      </div>
    );
  }
}

// SignupForm.propTypes = {
//     cityId: PropTypes.string.isRequired,
//     onChange: PropTypes.func.isRequired,
//     fbLoginText: PropTypes.string,
//     signupError: PropTypes.string,
// };

// SignupForm.defaultProps = {
//     includeAddress: false,
//     fbLoginText: "Login With Facebook",
//     signupError: null,
// };

export default SignupForm;
