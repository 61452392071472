import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import 'intl-tel-input';
import $ from 'jquery';
import { connect } from 'react-redux';
import Link from '../components/Link';
import SignupForm from '../components/SignupForm';
import Button from '../components/Button';
import { appActions, userActions } from '../data/actions';
import { ACTION_START } from '../data/constants';

type Props = {
  user: any;
  routeTo: any;
  login: any;
  facebookLogin: any;
  signup: any;
  config: any;
};

type State = {
  returnPath: any;
  signupData: any;
  countryId: any;
};

class SignupPage extends React.Component<Props, State> {
  static propTypes = {
    signup: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    queryParams: PropTypes.object.isRequired,
  };

  phoneInput: any;
  signupForm: SignupForm;

  constructor(props) {
    super(props);

    const queryParams = props.queryParams || {};
    const returnPath = queryParams.returnPath || 'home';

    this.state = {
      signupData: {},
      returnPath,
      countryId: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { user, routeTo } = this.props;

    if (user.isLoggedIn) {
      routeTo(this.state.returnPath);
    }

    const initialCountry =
      this.state.countryId === 'kuwait'
        ? 'kw'
        : this.state.countryId === 'uae'
        ? 'ae'
        : 'sa';
    if (this.phoneInput) {
      ($(this.phoneInput) as any).intlTelInput({
        onlyCountries: ['ae', 'kw', 'sa'],
        initialCountry,
      });
    }
  }

  onSubmit() {
    const { signup, routeTo } = this.props;
    const { returnPath, signupData } = this.state;
    const {
      name,
      password,
      email,
      phone,
      countryId,
      isFacebook,
      fbLoginParams,
      isValid,
    } = signupData;
    if (!isValid) return;

    const generatePassword = () => {
      let text = '';
      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      for (let i = 0; i < 5; i += 1) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    };

    const params = {
      name,
      phone,
      countryId,
      ...(!isFacebook && {
        email,
        password: password || generatePassword(),
      }),
      ...(isFacebook && { facebookLogin: fbLoginParams }),
    };

    signup(params, err => {
      if (!err) routeTo(returnPath);
    });
  }

  render() {
    const { config, user, routeTo } = this.props;
    const { signupData } = this.state;
    const signupStatus = user.signupStatus || {};
    const error = signupStatus.error;

    return (
      <div>
        <div className="mx-auto">
          <h2 className="title md:mt-10">Signup</h2>
          <div className="w-full max-w-3xl mx-auto p-3 md:p-16 bg-white rounded shadow">
            <div className="center p-5">
              <SignupForm
                cityId={config.cityId}
                ref={signupForm => {
                  this.signupForm = signupForm;
                }}
                fbLoginText="Signup with Facebook"
                onChange={data => this.setState({ signupData: data })}
                signupError={error}
              />
              <Button
                className="block mx-auto mb-6"
                loading={!error && signupStatus.status === ACTION_START}
                disabled={!signupData.isValid}
                onClick={this.onSubmit}
                text="Signup"
                big
              />
              <hr />
              Already registed?{' '}
              <Link onClick={() => routeTo('login')}>Login Here</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// SignupPage.propTypes = {
//     signup: PropTypes.func.isRequired,
//     routeTo: PropTypes.func.isRequired,
//     config: PropTypes.object.isRequired,
//     user: PropTypes.object.isRequired,
//     queryParams: PropTypes.object.isRequired,
// };

const SignupPageContainer = connect(
  state => ({ config: (state as any).config, user: (state as any).user }),
  dispatch => bindActionCreators({ ...appActions, ...userActions }, dispatch)
)(SignupPage);

export default SignupPageContainer;
