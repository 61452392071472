import HomePage from './home';
import LoginPage from './login';
import SignupPage from './login/signup';
import OrderPage from './order';
import OrderAppointmentPage from './order/appointment';
import OrderConfirmPage from './order/confirm';
import OrderStatusPage from './order/status';
import OrderActionPage from './order/action';
import StorageCalculatorPage from './quote/calculator';
import QuoteFormPage from './quote/QuoteForm';
import QuoteStatusPage from './quote/status';
import LeadSurveyPage from './lead/';
import RequestPage from './request';
import RequestAppointmentPage from './request/appointment';
import RequestStatusPage from './request/status';
import RequestFeedbackPage from './request/feedback';
import RequestNotePage from './request/RequestNote';
import InvoicePage from './invoices';
import StoragePage from './storage';
import ContactPage from './contact';
import ProfilePage from './profile';
import ResetPassword from './login/reset';
import MagicLogin from './login/magic';
import PayfortPay from './payment/payfort';
import {
  CardPayfort,
  CardProcess,
  CardVerify,
  CardStatus,
  CardTap,
} from './cards';
import TapPay from './payment/tap';
import PaymentProcess from './payment/process';
import PaymentStatus from './payment/status';
import { OfferPage } from './offer';
import ErrorPage from './error';
import settings from './settings';
import CardTapSave from './cards/CardTapSave';
import Survey from './survey';
import NotLoggedInNewOrderPage from './notLoggedInNewOrder';

const routes = [
  {
    path: 'login',
    name: 'login',
    component: LoginPage,
    title: 'Login',
  },
  {
    path: 'signup',
    name: 'signup',
    component: SignupPage,
    title: 'Signup',
  },
  {
    path: '',
    name: 'home',
    component: HomePage,
    loginRequired: true,
    title: 'Storages',
  },
  {
    path: '',
    name: 'error',
    component: HomePage,
    loginRequired: true,
    title: 'Home',
  },
  {
    path: 'order/storage',
    name: 'order-storage',
    component: OrderPage,
    title: 'Book a Storage',
    fullWidth: true,
  },
  {
    path: 'order/details',
    name: 'order-appointment',
    component: OrderAppointmentPage,
    title: 'Book a Storage',
    fullWidth: true,
  },
  {
    path: 'order/confirm',
    name: 'order-confirm',
    component: OrderConfirmPage,
    title: 'Book a Storage',
    fullWidth: true,
  },
  {
    path: 'order/status',
    name: 'order-status',
    component: OrderStatusPage,
    title: 'Order',
  },
  {
    path: 'order/action/:orderId?',
    name: 'order',
    component: OrderActionPage,
    title: 'Order Action',
  },
  {
    path: 'lead/:leadId?',
    name: 'lead',
    component: LeadSurveyPage,
    title: 'Survey',
  },
  /*{
    path: 'storage-calculator',
    name: 'storage-calculator',
    component: StorageCalculatorPage,
    title: 'Storage Calculator | Costs Calculator | Boxit Storage App',
    description:
      'Boxit Storage is an on-demand self storage company with pickup and delivery. Affordable rates. Calculate costs.',
    keywords: 'self storage costs calculator, storage calculator',
    fullWidth: true,
  },*/
  {
    name: 'notLoggedInOrder',
    component: NotLoggedInNewOrderPage,
    title: 'New order',
    path: 'order/contact',
  },
  {
    path: 'quote',
    name: 'quote-form',
    component: QuoteFormPage,
    title: 'Get Free Quote',
    fullWidth: true,
  },
  {
    path: 'quote/status',
    name: 'quote-status',
    component: QuoteStatusPage,
    title: 'Quote Status',
  },
  {
    path: 'request/appointment',
    name: 'request-appointment',
    component: RequestAppointmentPage,
    loginRequired: true,
  },
  {
    path: 'request/status',
    name: 'request-status',
    component: RequestStatusPage,
    loginRequired: true,
  },
  {
    path: 'request/feedback/:requestId?',
    name: 'request-feedback',
    component: RequestFeedbackPage,
    loginRequired: false,
  },
  {
    path: 'requests/:requestId/note',
    name: 'request-note',
    component: RequestNotePage,
    loginRequired: true,
  },
  {
    path: 'request/:type',
    name: 'request',
    component: RequestPage,
    loginRequired: true,
  },
  {
    path: 'invoices',
    name: 'invoices',
    component: InvoicePage,
    loginRequired: true,
    title: 'Invoices',
  },
  {
    path: 'storage/:storageId',
    name: 'storage',
    component: StoragePage,
    loginRequired: true,
  },
  {
    path: 'profile',
    name: 'profile',
    component: ProfilePage,
    loginRequired: true,
    title: 'Profile',
  },
  {
    path: 'survey',
    name: 'survey',
    component: Survey,
    title: 'survey',
  },
  {
    path: 'contact',
    name: 'contact',
    component: ContactPage,
    title: 'Contact Us',
  },
  {
    path: 'reset-password/:token?',
    name: 'password-reset',
    component: ResetPassword,
    title: 'Reset Password',
  },
  {
    path: 'magic/:token?',
    name: 'magic-login',
    component: MagicLogin,
    title: 'Magic Login',
  },
  {
    path: 'payment/status/:paymentId',
    name: 'payment-status',
    component: PaymentStatus,
    title: 'Payment Status',
  },
  {
    path: 'payment/:mode/process',
    name: 'payment-process',
    component: PaymentProcess,
  },
  {
    path: 'payment/tap/:userId/:amount',
    name: 'payment-tap',
    component: TapPay,
  },
  {
    path: 'payment/payfort/:userId/:amount',
    name: 'payment-payfort',
    component: PayfortPay,
  },
  {
    path: 'card/payfort',
    name: 'card-payfort',
    component: CardPayfort,
  },
  {
    path: 'card/tap',
    name: 'card-tap',
    component: CardTap,
  },
  {
    path: 'card/tap/save',
    name: 'card-tap-save',
    component: CardTapSave,
  },
  {
    path: 'card/:mode/process',
    name: 'card-process',
    component: CardProcess,
  },
  {
    path: 'card/verify',
    name: 'card-verify',
    component: CardVerify,
  },
  {
    path: 'card/status/:cardId',
    name: 'card-status',
    component: CardStatus,
  },
  {
    path: 'offer/:countryId',
    name: 'offer',
    component: OfferPage,
    title: 'Offer',
  },
  {
    name: 'error',
    component: ErrorPage,
    title: 'Error',
  },
];

const prefixed = routes.map(r => ({
  ...r,
  ...(typeof r.path === 'string' && { path: `${settings.rootUrl}${r.path}` }),
}));

export default prefixed;
