import React from 'react';
import s from './PhotoBox.module.scss';
import Button from '../Button';
import ImageContainer from './imgContainer/imgContainer';

type State = {
  currentPhotoIndex: number;
};

class PhotoBox extends React.PureComponent<any, State> {
  constructor(props) {
    super(props);
    this.state = {
      currentPhotoIndex: this.props.initialPhotoIndex,
    };
  }

  componentDidMount(): void {
    console.log(this.props.photos);
    document.onkeydown = (evt: any) => {
      evt = evt || window.event;
      if (evt.keyCode == 27) {
        this.props.cancel();
      } else if (evt.keyCode == 37) {
        if (this.state.currentPhotoIndex !== 0) {
          this.setState({
            currentPhotoIndex: this.state.currentPhotoIndex - 1,
          });
        }
      } else if (evt.keyCode == 39) {
        if (this.state.currentPhotoIndex !== this.props.photos.length - 1) {
          this.setState({
            currentPhotoIndex: this.state.currentPhotoIndex + 1,
          });
        }
      }
    };

    console.log({
      index: this.state.currentPhotoIndex,
    });
  }

  render() {
    return (
      <div className={s.photobox}>
        <button className={s.cancelBtn} onClick={this.props.cancel}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.828"
            height="17.828"
          >
            <path d="m2.828 17.828 6.086-6.086L15 17.828 17.828 15l-6.086-6.086 6.086-6.086L15 0 8.914 6.086 2.828 0 0 2.828l6.085 6.086L0 15l2.828 2.828z" />
          </svg>
        </button>
        {this.state.currentPhotoIndex !== 0 ? (
          <button
            className={s.navBtnprev}
            onClick={() =>
              this.setState({
                currentPhotoIndex: this.state.currentPhotoIndex - 1,
              })
            }
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <path d="M15.293 3.293 6.586 12l8.707 8.707 1.414-1.414L9.414 12l7.293-7.293-1.414-1.414z" />
            </svg>
          </button>
        ) : null}
        <ImageContainer
          photo={this.props.photos[this.state.currentPhotoIndex].photo}
        />
        {this.props.parts && (
          <div
            style={{
              position: 'absolute',
              bottom: '1rem',
              right: '0rem',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              color: 'white',
              boxSizing: 'border-box',
              padding: '0 1rem',
            }}
          >
            <h3>{`Item Code: ${
              this.props.photos[this.state.currentPhotoIndex].code
            }`}</h3>
            <h3>{this.props.photos[this.state.currentPhotoIndex].number}</h3>
          </div>
        )}
        {this.state.currentPhotoIndex !== this.props.photos.length - 1 ? (
          <button
            className={s.navBtnnext}
            onClick={() =>
              this.setState({
                currentPhotoIndex: this.state.currentPhotoIndex + 1,
              })
            }
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
            </svg>
          </button>
        ) : null}
        {!this.props.removeMakeDefault &&
        !this.props.photos[this.state.currentPhotoIndex].default ? (
          /*<button
            style={{ position: 'absolute', bottom: '1rem', left: '1rem' }}
            className={[
              'relative inline-flex items-center ml-4 px-4 py-2 border border-transparent shadow-sm font-medium rounded-md sm-boxit sm-boxit-light focus:outline-none active:bg-boxit text-white text-center uppercase tracking-wider font-medium',
            ].join(' ')}
            onClick={() => {
              this.props.makeDefaultPhoto(
                this.props.photos[this.state.currentPhotoIndex].id,
                this.props.storageId
              );
            }}
          >
            Set as default
          </button>*/
          <Button
            style={{ position: 'fixed', bottom: '1rem', left: '1rem' }}
            className={`bg-primary p-2`}
            text="Make Default"
            onClick={() => {
              this.props.makeDefaultPhoto(
                this.props.photos[this.state.currentPhotoIndex].id,
                this.props.storageId
              );
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default PhotoBox;
