import {
  ADDRESS_GET,
  ADDRESSES_GET,
  ADDRESSES_ADD,
  ADDRESSES_UPDATE,
  ERROR_HANDLE,
} from '../constants';

import errorHandleActions from '../actions/errorHandle';

const getAddress = id => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: ADDRESS_GET,
    apiCall: {
      method: 'GET',
      path: `addresses/${id}`,
      queryParams: {
        userId: user.userId,
      },
      callback: (err, res) => {
        dispatch(
          errorHandleActions.setError(
            true,
            err.response.data.message || 'unknown error'
          )
        );
      },
    },
  });
};

const getAddresses = () => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: ADDRESSES_GET,
    apiCall: {
      method: 'GET',
      path: 'addresses',
      queryParams: {
        userId: user.userId,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const addAddress = (addressName, addressLine, addressArea, addressCity, cb) => (
  dispatch,
  getState
) => {
  const { config, user } = getState();
  console.log(user);
  dispatch({
    type: ADDRESSES_ADD,
    apiCall: {
      method: 'POST',
      path: 'addresses',
      params: {
        userId: user.userId,
        name: addressName,
        line1: addressLine,
        area: addressArea,
        cityId: addressCity || config.cityId,
        countryId: user.user.countryId,
      },
      callback: (err, res) => {
        if (!err) {
          dispatch(getAddresses());
          if (typeof cb === 'function') {
            cb(res);
          }
        } else {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const updateAddress = (id, params) => dispatch => {
  dispatch({
    type: ADDRESSES_UPDATE,
    apiCall: {
      method: 'PATCH',
      path: `addresses/${id}`,
      params,
      callback: (err, res) => {
        dispatch(getAddresses());
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

export default {
  getAddresses,
  addAddress,
  updateAddress,
};
