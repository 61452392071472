import decode from 'jwt-decode';
import crypto from 'crypto';
import sha256 from 'sha256';
import {
  LOGIN,
  SIGNUP,
  FB_LOGIN,
  ACTION_FAILED,
  LOGOUT,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUBMIT,
  MAGIC_LINK_REQUEST,
  MAGIC_LINK_SUBMIT,
  USER_GET,
  USER_UPDATE,
  USER_CHANGE_PASWORD,
  CITIES_GET,
  ADDRESSES_USER_GET,
  CITIES_ALL_GET,
} from '../constants';
import { formatError } from '../../format';
import appActions from './app';
import configActions from './config';
import storageActions from './storages';
import requestActions from './requests';
import invoiceActions from './invoices';
import errorHandleActions from '../actions/errorHandle';

const getUser = (cb = null) => (dispatch, getState) => {
  const userId = getState().user.userId;
  dispatch({
    type: USER_GET,
    apiCall: {
      method: 'GET',
      path: `users/${userId}`,
      callback: (err, data) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
          return;
        }

        const user = data;

        if (user && user.countryId) {
          dispatch(configActions.getConfig(user.countryId));
        }

        if (typeof cb === 'function') {
          cb(err);
        }
      },
    },
  });
};

const login = (email, password, cb) => (dispatch, getState) => {
  dispatch({
    type: LOGIN,
    apiCall: {
      method: 'POST',
      path: 'authentication',
      params: {
        strategy: 'local',
        email,
        password: sha256(password),
      },
      callback: err => {
        if (!err) {
          const user = getState().user;
          if (user && user.countryId) {
            dispatch(configActions.getConfig(user.countryId));
          }
          dispatch(storageActions.getStorages());
          dispatch(invoiceActions.getInvoices());
          dispatch(requestActions.getRequests());
        } else {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }

        if (typeof cb === 'function') {
          cb(err);
        }
      },
    },
  });
};

const facebookLogin = (params, cb) => dispatch => {
  if (!params.accessToken || !params.email) {
    return dispatch({
      type: FB_LOGIN,
      status: ACTION_FAILED,
      error: formatError('FACEBOOK_LOGIN_FAILED'),
    });
  }

  return dispatch({
    type: FB_LOGIN,
    apiCall: {
      method: 'GET',
      path: 'auth/facebook-token/callback',
      queryParams: {
        access_token: params.accessToken,
      },
      callback: (err, res) => {
        if (err || !res) {
          dispatch({
            type: FB_LOGIN,
            status: ACTION_FAILED,
            error: formatError('FACEBOOK_LOGIN_FAILED'),
          });
          return;
        }

        const { accessToken } = res;
        const { userId } = decode(accessToken);

        if (userId === 'new') {
          dispatch(appActions.routeTo('signup', {}, { facebook: true }));
          return;
        }

        dispatch(getUser());
        dispatch(storageActions.getStorages());
        dispatch(invoiceActions.getInvoices());
        dispatch(requestActions.getRequests());

        if (typeof cb === 'function') {
          cb();
        }
      },
    },
  });
};

const signup = (params, cb) => dispatch => {
  dispatch({
    type: SIGNUP,
    apiCall: {
      method: 'POST',
      path: 'users',
      params: {
        ...params,
        password: sha256(params.password),
      },
      callback: (err, data) => {
        if (err || !data) return;

        if (params.facebookLogin) {
          dispatch(
            facebookLogin(params.facebookLogin, () => {
              if (typeof cb === 'function') {
                cb();
              }
            })
          );
        } else {
          dispatch(
            login(params.email, params.password, () => {
              if (typeof cb === 'function') {
                cb();
              }
            })
          );
        }
      },
    },
  });
};

const resetPasswordRequest = (email, cb) => dispatch => {
  dispatch({
    type: RESET_PASSWORD_REQUEST,
    apiCall: {
      method: 'POST',
      path: 'authManagement',
      params: {
        action: 'sendResetPwd',
        value: { email },
      },
      callback: (err, res) => {
        if (typeof cb === 'function') {
          cb(err, res);
        }
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const resetPasswordSubmit = (token, password) => dispatch => {
  dispatch({
    type: RESET_PASSWORD_SUBMIT,
    apiCall: {
      method: 'POST',
      path: 'authManagement',
      params: {
        action: 'resetPwdLong',
        value: {
          token,
          password: sha256(password),
        },
      },
      callback: error => {
        if (error) {
          let message;
          if (error.message === 'Token expired [403]') {
            // TODO: Find feathers error
            message = formatError('LINK_EXPIRED');
          } else {
            message = formatError('PASSWORD_RESET_ERROR');
          }
          dispatch({
            type: RESET_PASSWORD_SUBMIT,
            status: ACTION_FAILED,
            error: new Error(message),
          });

          dispatch(
            errorHandleActions.setError(
              true,
              error.response.data.message || 'unknown error'
            )
          );
        } else {
          window.location.href = '/login';
        }
      },
    },
  });
};

const magicLinkRequest = (email, cb) => dispatch => {
  dispatch({
    type: MAGIC_LINK_REQUEST,
    apiCall: {
      method: 'POST',
      path: 'magic-link',
      params: {
        action: 'sendResetPwd',
        value: { email },
      },
      callback: (err, res) => {
        if (typeof cb === 'function') {
          cb(err, res);
        }
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const magicLinkSubmit = (token, cb) => dispatch => {
  dispatch({
    type: MAGIC_LINK_SUBMIT,
    apiCall: {
      method: 'POST',
      path: 'magic-link',
      params: {
        action: 'resetPwdLong',
        value: { token },
      },
      callback: (error, res) => {
        if (!error) {
          dispatch(getUser());
          dispatch(storageActions.getStorages());
          dispatch(invoiceActions.getInvoices());
          dispatch(requestActions.getRequests());

          if (typeof cb === 'function') {
            cb();
          }
        } else {
          dispatch(
            errorHandleActions.setError(
              true,
              error.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const updateUser = params => (dispatch, getState) => {
  const userId = getState().user.userId;

  dispatch({
    type: USER_UPDATE,
    apiCall: {
      method: 'PATCH',
      path: `users/${userId}`,
      params,
      callback: (err, res) => {
        dispatch(getUser());
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const changePassword = (oldPassword, newPassword) => (dispatch, getState) => {
  const user = getState().user;

  dispatch({
    type: USER_CHANGE_PASWORD,
    apiCall: {
      method: 'POST',
      path: 'authManagement',
      params: {
        action: 'passwordChange',
        value: {
          user: { email: user.user.email },
          oldPassword: crypto
            .createHash('sha256')
            .update(oldPassword)
            .digest('hex'),
          password: crypto
            .createHash('sha256')
            .update(newPassword)
            .digest('hex'),
        },
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const logout = cb => dispatch => {
  dispatch({
    type: LOGOUT,
    apiCall: {
      method: 'DELETE',
      path: 'authentication',
      callback: (err, res) => {
        if (typeof cb === 'function') {
          cb(err, res);
        }
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const getCities = () => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: CITIES_GET,
    apiCall: {
      method: 'GET',
      path: 'city',
      queryParams: {
        countryId: user.user.countryId,
      },
      callback: (err, res) => {
        console.log('err', err);
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
        return res;
      },
    },
  });
};

const getAllCities = () => (dispatch, getState) => {
  dispatch({
    type: CITIES_ALL_GET,
    apiCall: {
      method: 'GET',
      path: 'city',
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
        return res;
      },
    },
  });
};

const getUserAddresses = () => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: ADDRESSES_USER_GET,
    apiCall: {
      method: 'GET',
      path: 'addresses',
      queryParams: {
        userId: user.user.id,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
        return res;
      },
    },
  });
};

export default {
  getUser,
  updateUser,
  changePassword,
  resetPasswordRequest,
  resetPasswordSubmit,
  magicLinkRequest,
  magicLinkSubmit,
  login,
  logout,
  signup,
  facebookLogin,
  getCities,
  getUserAddresses,
  getAllCities,
};
