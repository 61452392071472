import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PaymentMode } from '../constants';
import Loading from '../components/Loading';
import { ACTION_START } from '../data/constants';
import { newCardActions, appActions } from '../data/actions';
type Props = {
  newCard: any;
  routeTo: any;
  queryParams: any;
  urlParams: any;
  createCard: any;
};
class CardProcess extends React.Component<Props> {
  static propTypes = {
    createCard: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    urlParams: PropTypes.object.isRequired,
    queryParams: PropTypes.object.isRequired,
    newCard: PropTypes.object.isRequired,
  };
  componentDidMount() {
    const { newCard, routeTo, queryParams, urlParams, createCard } = this.props;
    const mode = urlParams.mode;
    let ref;
    if (mode === PaymentMode.tap) {
      ref = queryParams.trackid;
    }
    if (mode === PaymentMode.payfort) {
      ref = queryParams.merchant_reference;
    }

    if (!ref) {
      routeTo('home');
    }

    if (
      newCard.processStatus.status &&
      newCard.processStatus.status !== ACTION_START
    ) {
      return;
    }

    createCard(mode, queryParams, (err, response) => {
      if (!err && response && response['3ds_url']) {
        window.location = response['3ds_url'];
      } else {
        routeTo('card-status');
      }
      /* if (!err) {
       *   routeTo('card-verify');
       * } else {
       *   routeTo('card-status');
       * }*/
    });
  }

  render() {
    return (
      <div>
        <div className="">
          <h2 className="sub-title m-t-20 m-b-20">Processing...</h2>
          <div className="grid grid-cols-1 mx-auto md:w-1/2">
            <div className="box center text-center p-20">
              <Loading />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// CardProcess.propTypes = {
//   createCard: PropTypes.func.isRequired,
//   routeTo: PropTypes.func.isRequired,
//   urlParams: PropTypes.object.isRequired,
//   queryParams: PropTypes.object.isRequired,
//   newCard: PropTypes.object.isRequired,
// };

const CardProcessContainer = connect(
  state => ({
    newCard: (state as any).newCard,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...newCardActions,
        ...appActions,
      },
      dispatch
    )
)(CardProcess);

export default CardProcessContainer;
