import React, { Component } from 'react';
import swal from 'sweetalert2';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { appActions, orderActions, userActions } from '../data/actions';
import Button from '../components/Button';
import s from './styles.module.css';

type Props = {
  queryParams: any;
  routeTo: any;
  orderUpdate: any;
  urlParams: any;
};

type State = {
  reason: string;
  note: string;
};

class LeadSurveyPage extends Component<Props, State> {
  static propTypes = {
    queryParams: PropTypes.object.isRequired,
    routeTo: PropTypes.func.isRequired,
    orderUpdate: PropTypes.func.isRequired,
    urlParams: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      note: '',
    };

    this.onValueChange = this.onValueChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  componentDidMount() {
    const { queryParams, routeTo, orderUpdate, urlParams } = this.props;
    const leadId = urlParams.leadId;
    const action = queryParams.action;
    const accessToken = queryParams.accessToken;
    if (leadId && action && accessToken) {
      if (action === 'quote_accepted') {
        orderUpdate(leadId, { status: action }, accessToken);
      } else if (action === 'quote_rejected') {
        orderUpdate(leadId, { status: action }, accessToken);
      } else if (action === 'renew') {
        orderUpdate(
          leadId,
          { status: action, reason: 'robot: Customer wants a callback' },
          accessToken
        );
      } else if (action === 'lost') {
        orderUpdate(
          leadId,
          {
            status: action,
            reason: 'robot: Customer does not want the service',
          },
          accessToken
        );
      }
    } else {
      routeTo('home');
    }
  }

  onValueChange(event) {
    this.setState({ reason: event.target.value });
  }
  handleChange(event) {
    this.setState({ note: event.target.value });
  }

  formSubmit(event) {
    event.preventDefault();
    const { orderUpdate, urlParams, queryParams } = this.props;
    const leadId = urlParams.leadId;
    const accessToken = queryParams.accessToken;
    const reason = this.state.reason;
    const note = this.state.note;
    const exitSurvey = {
      reason,
      note,
    };
    if (reason.length || note) {
      orderUpdate(leadId, { exitSurvey }, accessToken);
      this.setState({ reason: '', note: '' });
      swal.fire('Done!', 'Thank you for your feedback', 'success').then(() => {
        window.location.href = 'https://www.boxitstorage.com/';
      });
    }
  }

  render() {
    const { queryParams } = this.props;
    const action = queryParams.action;
    return (
      <div>
        {action === 'quote_accepted' && (
          <div>
            <div className="box mt-20 py-20 px-40 mx-auto max-w-4xl">
              <h1 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">Thank You!</h1>
              <p className="text-center sub-title">
                Thank you for accepting the quote.
                <br />
                We will contact you shortly.
              </p>
              <div className="text-center mt-20 mb-20">
                <Button
                  className="center"
                  onClick={() => {
                    window.location.href = 'https://www.boxitstorage.com/';
                  }}
                  text="Home"
                  full={false}
                  big
                />
              </div>
            </div>
          </div>
        )}
        {action === 'renew' && (
          <div>
            <div className="box mt-20 py-20 px-40 mx-auto max-w-4xl">
              <h1 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">Thank You!</h1>
              <p className="text-center sub-title">
                Thank you for your interest.
                <br />
                We will contact you shortly.
              </p>
              <div className="text-center mt-20 mb-20">
                <Button
                  className="center"
                  onClick={() => {
                    window.location.href = 'https://www.boxitstorage.com/';
                  }}
                  text="Home"
                  full={false}
                  big
                />
              </div>
            </div>
          </div>
        )}
        {(action === 'lost' || action === 'quote_rejected') && (
          <div className="container mx-auto">
            <h1 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">Share your feedback</h1>
            <div className="box center py-20 px-40 rounded shadow-md box-border w-3/4">
              <form onSubmit={this.formSubmit}>
                <h3 className="mb-10 text-3xl">
                  Please tell us the reason behind your decision
                </h3>
                <div className="mt-2 text-lg">
                  <label>
                    <input
                      type="radio"
                      name="reason"
                      value="I no longer need storage"
                      className="mr-3 cursor-pointer"
                      checked={this.state.reason === 'I no longer need storage'}
                      onChange={this.onValueChange}
                    />
                    I no longer need storage
                  </label>
                </div>
                <div className="mt-2 text-lg">
                  <label>
                    <input
                      type="radio"
                      name="reason"
                      value="Your prices are too expensive"
                      className="mr-3 cursor-pointer"
                      checked={
                        this.state.reason === 'Your prices are too expensive'
                      }
                      onChange={this.onValueChange}
                    />
                    Your prices are too expensive
                  </label>
                </div>
                <div className="mt-2 text-lg">
                  <label>
                    <input
                      type="radio"
                      name="reason"
                      value="You did not contact me on time"
                      className="mr-3 cursor-pointer"
                      checked={
                        this.state.reason === 'You did not contact me on time'
                      }
                      onChange={this.onValueChange}
                    />
                    You did not contact me on time
                  </label>
                </div>
                <div className="mt-2 text-lg">
                  <label>
                    <input
                      type="radio"
                      name="reason"
                      value="I have selected another company"
                      className="mr-3 cursor-pointer"
                      checked={
                        this.state.reason === 'I have selected another company'
                      }
                      onChange={this.onValueChange}
                    />
                    I have selected another company
                  </label>
                </div>
                <div className="mt-2 text-lg">
                  <label>
                    <input
                      type="radio"
                      name="reason"
                      value="I am just enquiring for now"
                      className="mr-3 cursor-pointer"
                      checked={
                        this.state.reason === 'I am just enquiring for now'
                      }
                      onChange={this.onValueChange}
                    />
                    I am just enquiring for now
                  </label>
                </div>
                <div className="mt-2 text-lg">
                  <label>
                    <input
                      type="radio"
                      name="reason"
                      value="other"
                      className="mr-3 cursor-pointer"
                      checked={this.state.reason === 'other'}
                      onChange={this.onValueChange}
                    />
                    Something else
                  </label>
                </div>
                <h3 className="mt-10 mb-10 text-3xl">
                  Do you have any suggestions to improve our services
                </h3>
                <div className={`${s.inputTag}`} style={{ width: '100%' }}>
                  <textarea
                    className="form-control p-4 mb-10 border w-full"
                    rows={4}
                    value={this.state.note}
                    onChange={this.handleChange}
                  />
                </div>
                <div className={s.btnAlign}>
                  <button
                    className={`${s.btnCustom} btn w-full uppercase`}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const LeadSurveyContainer = connect(
  state => ({
    order: (state as any).order,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...appActions,
        ...orderActions,
        ...userActions,
      },
      dispatch
    )
)(LeadSurveyPage);

export default LeadSurveyContainer;
