import _ from 'lodash';
import moment from 'moment';
import capitalize from 'capitalize';
import { Charge, Service, Storage } from '../types';

export function groupCharges(charges: Charge[], services: Service[] = []) {
    const combined: Charge[] = [];
    const discounts = charges.filter(c => c.type === 'discount');
    const nonDiscountCharges = charges.filter(c => c.type !== 'discount');
    const storageCharges = nonDiscountCharges.filter(
        c => c.type === 'storage' || c.type === 'order'
    );
    const restCharges = nonDiscountCharges.filter(
        c => c.type !== 'storage' && c.type !== 'order'
    );

    const chargesByOrder = _.groupBy(storageCharges, 'orderId');

    const otherCharges = restCharges
        .concat(chargesByOrder['null'])
        .filter(c => !!c);
    delete chargesByOrder.null;

    Object.keys(chargesByOrder).map(orderId => {
        if (orderId === undefined) return;
        if (!chargesByOrder[orderId]) return;

        const chargesForOrder = chargesByOrder[orderId];
        const totalCharge = chargesForOrder
            .map(s => s.amount)
            .reduce((a, b) => a + b, 0);
        const currency = chargesForOrder[0].currency;
        const userId = chargesForOrder[0].userId;

        const chargeFrom = chargesForOrder
            .map(c =>
                moment({ year: c.year, month: c.month - 1, day: c.startDate })
            )
            .reduce((a, b) => (a < b ? a : b));
        const chargeTo = chargesForOrder
            .map(c =>
                moment({ year: c.year, month: c.month - 1, day: c.endDate })
            )
            .reduce((a, b) => (a > b ? a : b));

            const description = `Storage charges for order ${orderId} from ${chargeFrom.format(
                'DD-MM-YYYY'
            )} to ${chargeTo.format('DD-MM-YYYY')}`;

        combined.push({
            startDate: chargeFrom,
            endDate: chargeTo,
            description,
            amount: totalCharge,
            currency,
            orderId: parseInt(orderId),
            userId,
            type: 'storage',
            id: 0 /* dummy */,
        });
    });

    (otherCharges || []).forEach(charge => {
        const description = describeCharge(
            charge,
            null,
            null,
            services.find((s: Service) => s.id === charge.serviceId)
        );
        const chargeFrom = moment({
            year: charge.year,
            month: charge.month - 1,
            day: charge.startDate,
        });
        const chargeTo = moment({
            year: charge.year,
            month: charge.month - 1,
            day: charge.endDate,
        });
        combined.push({
            startDate: chargeFrom,
            endDate: chargeTo,
            description: charge.orderId
                ? `${description} for order ${charge.orderId}`
                : description,
            amount: charge.amount,
            currency: charge.currency,
            orderId: charge.orderId,
            userId: charge.userId,
            type: 'service',
            id: 0 /* dummy */,
        });
    });

    /*
  const groupedDiscounts = (discounts || []).reduce((group, discount) => {
    const index = group.findIndex(i => i.description === discount.description);

    if (index === -1) {
      group.push(discount);
    } else {
      const currentAmount = group[index].amount;
      group[index] = {
        description: discount.description,
        currency: discount.currency,
        amount: currentAmount + discount.amount,
      };
    }
    return group;
  }, []);
  */

    (discounts || []).forEach(discount => {
        combined.push({
            description:
                `${
                    !!discount.description ? discount.description : 'Discount'
                }` + (discount.orderId ? ` for order ${discount.orderId}` : ''),
            amount: discount.amount,
            currency: discount.currency,
            orderId: discount.orderId,
            userId: discount.userId,
            type: 'discount',
            id: 0 /* dummy */,
        });
    });

    return combined;
}

export function describeCharge(charge, storage, storageType, service?) {
    if (!charge) return null;

    // request charge
    switch (charge.type) {
        case 'pickup':
        case 'delivery':
        case 'packing':
        case 'loading':
            return `${capitalize(charge.type)} charges`;
        case 'pickup_packing':
            return `Pickup and packing charges`;
        case 'pickup_delivery':
            return `Transportation charges`;
        case 'pickup_packing_delivery':
            return `Transportation and packing charges`;
        case 'discount':
            return 'Discount';
        case 'order':
            return 'Storage charges';
    }

    // storage charge
    if (charge.storageId) {
        const chargeFrom = moment({
            year: charge.year,
            month: charge.month - 1,
            day: charge.startDate,
        });
        const chargeTo = moment({
            year: charge.year,
            month: charge.month - 1,
            day: charge.endDate,
        });
        return createStorageChargeDescription(
            storage && storage.code ? storage : { id: charge.storageId },
            chargeFrom,
            chargeTo,
            storageType
        );
    }

    if (charge.serviceId && service) {
        return `${service.servicetype.name} charges`;
    }

    // custom charge
    if (charge.description) return charge.description;

    if (charge.type) {
        return `${capitalize(charge.type.replace(/_/g, ' '))} charges`;
    }

    return null;
}

export function createStorageChargeDescription(
    storage,
    chargeFrom,
    chargeTo,
    storageType
) {
    let storageStr;

    if (storage) {
        if (storage.code) {
            if (storageType) {
                storageStr = `${storage.code} [${storageType.name}]`;
            } else {
                storageStr = `${storage.code}`;
            }
        } else {
            storageStr = `${storage.id}`;
        }

        if (moment(chargeFrom).isSame(chargeTo)) {
            return `Rent for ${storageStr} for ${moment(chargeFrom).format(
                'YYYY-MM-DD'
            )}`;
        } else {
            let description = `Rent for ${storageStr} from ${moment(
                chargeFrom
            ).format('YYYY-MM-DD')}`;
            if (chargeTo > chargeFrom) {
                description += ` to ${moment(chargeTo).format('YYYY-MM-DD')}`;
            }
            return description;
        }
    }

    return null;
}
