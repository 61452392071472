import React, { Component } from 'react';
import s from './imgContainer.module.scss';

import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from 'react-zoom-pan-pinch';

const ImageContainer = (props: any) => {
  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <div className={s.controls}>
        <button onClick={() => zoomIn()}>Zoom In</button>
        <button onClick={() => zoomOut()}>Zoom Out</button>
        <button onClick={() => resetTransform()}>Reset</button>
      </div>
    );
  };
  return (
    <TransformWrapper
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}
      wheel={{ step: 10 }}
      pinch={{ step: 10 }}
      minScale={0.5}
      centerZoomedOut={true}
      centerOnInit={true}
      limitToBounds={true}
    >
      <Controls />
      <TransformComponent
        wrapperClass={s.wrapperContainer}
        contentClass={s.contentContainer}
      >
        <img className={s.image} src={props.photo} />
      </TransformComponent>
    </TransformWrapper>
  );
};

export default ImageContainer;
