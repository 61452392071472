import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';
import s from './Header.module.css';
import imgLogo from './images/logo.png';
import { MenuIcon, XIcon } from '@heroicons/react/solid';
import { Disclosure } from '@headlessui/react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { userActions, appActions } from '../../data/actions';

type Props = { user: any; pendingInvoiceCount: any; routeTo: any; logout: any };

class Header extends React.PureComponent<Props> {
  static propTypes = {
    routeTo: PropTypes.func.isRequired,
    user: PropTypes.object,
    pendingInvoiceCount: PropTypes.number,
    logout: PropTypes.func.isRequired,
  };
  public static defaultProps = {
    user: {},
    pendingInvoiceCount: 0,
  };
  render() {
    const { user, pendingInvoiceCount, routeTo } = this.props;
    const isLoggedIn = user && user.email;

    return (
      <Disclosure as="nav" className="bg-white ">
        {({ open }) => (
          <>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 md:px-9">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <a href="/">
                      <img
                        className={s.logo}
                        src={imgLogo}
                        alt="Boxit Storage"
                      />
                    </a>
                  </div>
                </div>

                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {isLoggedIn && (
                      <Link
                        className={s.navlink}
                        onClick={() => routeTo('home')}
                      >
                        Items
                      </Link>
                    )}
                    {isLoggedIn && (
                      <Link
                        className={s.navlink}
                        onClick={() => routeTo('invoices')}
                      >
                        Invoices
                        {pendingInvoiceCount > 0 && (
                          <span
                            className={`${s.navNumber} ${s.invoiceFloat} bg-yellow items-center rounded-full h-6 w-6 flex justify-center `}
                          >
                            {pendingInvoiceCount}
                          </span>
                        )}
                        &nbsp;
                      </Link>
                    )}
                    {isLoggedIn && (
                      <Link
                        className={s.navlink}
                        onClick={() => routeTo('profile')}
                      >
                        Profile
                        {user.credits > 0 && (
                          <span className={`${s.navNumber} bg-green`}>
                            <i className="fa fa-money" aria-hidden="true" />
                          </span>
                        )}
                        &nbsp;
                      </Link>
                    )}
                    {!isLoggedIn && (
                      <Link
                        className={s.navlink}
                        onClick={() => routeTo('login')}
                      >
                        Login
                      </Link>
                    )}
                    <Link
                      className={s.navlink}
                      onClick={() => routeTo('contact')}
                    >
                      Contact
                    </Link>
                    <Link
                      className={s.navlink}
                      onClick={() => {
                        //routeTo('storage-calculator')
                        if (isLoggedIn) {
                          if (user.countryId === 1) {
                            window.location.href =
                              'https://api.whatsapp.com/send?phone=97180026948';
                          } else if (user.countryId === 2) {
                            window.location.href =
                              'https://api.whatsapp.com/send?phone=9651800071';
                          } else if (user.countryId === 3) {
                            window.location.href =
                              'https://api.whatsapp.com/send?phone=966920033368';
                          }
                        } else {
                          routeTo('notLoggedInOrder');
                        }
                      }}
                    >
                      New Order
                    </Link>
                    {isLoggedIn && (
                      <button
                        className={s.navlink}
                        onClick={() => {
                          this.props.logout(() => {
                            this.props.routeTo('login');
                            window.location.reload();
                          });
                        }}
                        style={{}}
                      >
                        Logout
                      </button>
                    )}
                  </div>
                </div>
                <div className="-mr-2 flex sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                <ul className="nav navbar-nav navbar-right">
                  <li>
                    {isLoggedIn && (
                      <Link
                        className={s.navlink}
                        onClick={() => routeTo('home')}
                      >
                        Items
                      </Link>
                    )}
                  </li>
                  <li>
                    {isLoggedIn && (
                      <Link
                        className={s.navlink}
                        onClick={() => routeTo('invoices')}
                      >
                        Invoices
                        {pendingInvoiceCount > 0 && (
                          <span className="table-caption items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-yellow rounded-full">
                            {pendingInvoiceCount}
                          </span>
                        )}
                        &nbsp;
                      </Link>
                    )}
                  </li>
                  <li>
                    {isLoggedIn && (
                      <Link
                        className={s.navlink}
                        onClick={() => routeTo('profile')}
                      >
                        Profile
                        {user.credits > 0 && (
                          <span className={`${s.navNumber} bg-green`}>
                            <i className="fa fa-money" aria-hidden="true" />
                            {/* {user.credits} */}
                          </span>
                        )}
                        &nbsp;
                      </Link>
                    )}
                  </li>
                  <li>
                    {!isLoggedIn && (
                      <Link
                        className={s.navlink}
                        onClick={() => routeTo('login')}
                      >
                        Login
                      </Link>
                    )}
                  </li>
                  <li>
                    <Link
                      className={s.navlink}
                      onClick={() => routeTo('contact')}
                    >
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={s.navlink}
                      onClick={() => {
                        //routeTo('storage-calculator')
                        if (isLoggedIn) {
                          if (user.countryId === 1) {
                            window.location.href =
                              'https://api.whatsapp.com/send?phone=97180026948';
                          } else if (user.countryId === 2) {
                            window.location.href =
                              'https://api.whatsapp.com/send?phone=9651800071';
                          } else if (user.countryId === 3) {
                            window.location.href =
                              'https://api.whatsapp.com/send?phone=966920033368';
                          }
                        } else {
                          routeTo('notLoggedInOrder');
                        }
                      }}
                    >
                      New Order
                    </Link>
                  </li>
                </ul>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }
}

export default connect(
  state => ({}),
  dispatch =>
    bindActionCreators(
      {
        ...userActions,
        ...appActions,
      },
      dispatch
    )
)(Header);
