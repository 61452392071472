import {
  PHOTOS_GET,
  ADD_PHOTOS,
  DELETE_PHOTO,
  ACTION_RESET,
  DEFAULT_PHOTO,
} from '../constants';
import errorHandleActions from '../actions/errorHandle';

const getPhotos = storageId => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: PHOTOS_GET,
    apiCall: {
      method: 'GET',
      path: 'photos',
      queryParams: {
        userId: user.userId,
        storageId,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const addPhotos = (storageId, files) => dispatch => {
  files.forEach(file => {
    dispatch({
      type: ADD_PHOTOS,
      apiCall: {
        method: 'POST',
        path: 'photos',
        params: {
          storageId,
          file,
          userId: localStorage.getItem('userId'),
        },
        callback: (err, res) => {
          setTimeout(() => {
            dispatch(getPhotos(storageId));
            dispatch({
              type: ADD_PHOTOS,
              status: ACTION_RESET,
              params: {
                storageId,
              },
            });
          }, 1000);
          if (err) {
            dispatch(
              errorHandleActions.setError(
                true,
                err.response.data.message || 'unknown error'
              )
            );
          }
        },
      },
    });
  });
};

const deletePhoto = (storageId, photoId) => dispatch => {
  dispatch({
    type: DELETE_PHOTO,
    apiCall: {
      method: 'DELETE',
      path: `photos/${photoId}`,
      callback: (err, res) => {
        dispatch(getPhotos(storageId));
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const makeDefaultPhoto = (photoId, storageId) => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: DEFAULT_PHOTO,
    apiCall: {
      method: 'PATCH',
      params: {
        default: true,
      },
      path: `photos/${photoId}`,
      callback: (err, res) => {
        dispatch(getPhotos(storageId));
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

export default {
  getPhotos,
  addPhotos,
  deletePhoto,
  makeDefaultPhoto,
};
