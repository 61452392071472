import moment from 'moment';
import swal from 'sweetalert2';
import _ from 'lodash';
import {
  NEW_ORDER_SET_ITEM_QUANTITY,
  NEW_ORDER_SET_MONTHS,
  NEW_ORDER_SET_DATE,
  NEW_ORDER_SET_TIMESLOT,
  NEW_ORDER_SET_PAYMENTMODE,
  NEW_ORDER_ADD_COUPON,
  NEW_ORDER,
  NEW_ORDER_SUBMIT,
  NEW_ORDER_SET_ADDRESS,
  ACTION_START,
  NEW_ORDER_SET_COMMENT,
  NEW_ORDER_SET_ASSIGNEE_ID,
  NEW_ORDER_SET_NAME,
  NEW_ORDER_SET_EMAIL,
  NEW_ORDER_SET_PHONE,
  ACTION_SUCCESS,
  ACTION_RESET,
  ACTION_FAILED,
  ACTION_WAITING,
} from '../constants';
import { OfferType } from '../../constants';
import { formatError } from '../../format';
import {
  computeOrderSize,
  computeStorageRate,
  computeMonthlyRent,
  computePackingCharge,
  computePickupCharge,
} from '../../core/utils/pricing';

const initialState = {
  items: [],
  months: 0,
  requestDate: null,
  timeSlot: {
    from: 9,
    to: 11,
  },
  paymentMode: null,
  address: {
    name: 'Home',
  },
  totalSize: 0,
  monthlyRent: 0,
  totalRent: 0,
  discount: 0,
  coupon: null,
  offer: null,
  couponDiscount: 0,
  comment: '',
  name: null,
  email: null,
  phone: null,
  status: null,
  invoiceIds: null,
  assigneeId: null,
};

const calculateDiscount = (totalRent, months, discounts) => {
  return 0;

  const discount = (discounts || [])
    .sort((a, b) => b.target - a.target)
    .find(d => totalRent >= d.target);
  return discount ? discount.discount : 0;
};

const calculateCouponDiscount = (totalRent, coupon, offer) => {
  let discount = 0;
  if (coupon && offer) {
    if (offer.type === OfferType.discount) {
      discount = offer.discount;
    }
    if (offer.type === OfferType.percent) {
      discount = totalRent * offer.percent * 0.01;
    }
    discount = Math.min(discount, totalRent);
    discount = +discount.toFixed(offer.countryId === 'kuwait' ? 3 : 2);
  }
  return discount;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case NEW_ORDER:
      if (action.status === ACTION_START) {
        const globalState = action.globalState();
        const storagetypes = globalState.config.storagetypes;
        const discounts = globalState.config.discounts;
        const totalSize = computeOrderSize(initialState.items, storagetypes);
        const rate = computeStorageRate(
          totalSize,
          globalState.config.countryId
        );
        const monthlyRent = computeMonthlyRent(
          totalSize,
          rate,
          globalState.config.countryId
        );
        const totalRent = monthlyRent * initialState.months;
        const discount = calculateDiscount(
          totalRent,
          initialState.months,
          discounts
        );
        const couponDiscount = calculateCouponDiscount(
          totalRent,
          state.coupon,
          state.offer
        );
        const packingCharge = computePackingCharge(totalSize);
        const pickupCharge = computePickupCharge(totalSize);

        return {
          ...initialState,
          status: ACTION_START,
          totalSize,
          rate,
          monthlyRent,
          totalRent,
          discount,
          couponDiscount,
          packingCharge,
          pickupCharge,
        };
      }
      if (action.status === ACTION_RESET) {
        return initialState;
      }
      return {
        ...state,
        status: action.status,
      };

    case NEW_ORDER_SET_MONTHS: {
      const globalState = action.globalState();
      const storagetypes = globalState.config.storagetypes;
      const discounts = globalState.config.discounts;
      const totalSize = computeOrderSize(state.items, storagetypes);
      const rate = computeStorageRate(totalSize, globalState.config.countryId);
      const monthlyRent = computeMonthlyRent(
        totalSize,
        rate,
        globalState.config.countryId
      );
      const totalRent = monthlyRent * action.months;
      const discount = calculateDiscount(totalRent, action.months, discounts);
      const couponDiscount = calculateCouponDiscount(
        totalRent,
        state.coupon,
        state.offer
      );
      const packingCharge = computePackingCharge(totalSize);
      const pickupCharge = computePickupCharge(totalSize);

      return {
        ...state,
        months: action.months,
        totalSize,
        rate,
        monthlyRent,
        totalRent,
        discount,
        couponDiscount,
        packingCharge,
        pickupCharge,
      };
    }

    case NEW_ORDER_SET_ITEM_QUANTITY: {
      const { storagetypeId } = action;
      let quantity = action.quantity;
      if (quantity >= 100) {
        quantity = 100;
      }
      let items = (state.items || []).filter(
        i => i.storagetypeId !== storagetypeId
      );
      if (!isNaN(quantity) && quantity > 0) {
        items.push({ storagetypeId, quantity });
      }

      items = _.sortBy(items, i => i.storagetypeId);

      const globalState = action.globalState();
      const storagetypes = globalState.config.storagetypes;
      const discounts = globalState.config.discounts;
      const totalSize = computeOrderSize(items, storagetypes);
      const rate = computeStorageRate(totalSize, globalState.config.countryId);
      const monthlyRent = computeMonthlyRent(
        totalSize,
        rate,
        globalState.config.countryId
      );
      const totalRent = monthlyRent * state.months;
      const discount = calculateDiscount(totalRent, state.months, discounts);
      const couponDiscount = calculateCouponDiscount(
        totalRent,
        state.coupon,
        state.offer
      );
      const packingCharge = computePackingCharge(totalSize);
      const pickupCharge = computePickupCharge(totalSize);

      return {
        ...state,
        items,
        totalSize,
        rate,
        monthlyRent,
        totalRent,
        discount,
        couponDiscount,
        packingCharge,
        pickupCharge,
      };
    }

    case NEW_ORDER_SET_TIMESLOT:
      return {
        ...state,
        timeSlot: action.timeSlot,
      };

    case NEW_ORDER_SET_DATE:
      return {
        ...state,
        requestDate: moment(action.requestDate).format('YYYY-MM-DD'),
      };

    case NEW_ORDER_SET_ADDRESS:
      return {
        ...state,
        address: action.address,
      };

    case NEW_ORDER_SET_PAYMENTMODE:
      return {
        ...state,
        paymentMode: action.paymentMode,
      };

    case NEW_ORDER_SET_COMMENT:
      return {
        ...state,
        comment: action.comment,
      };
    case NEW_ORDER_SET_ASSIGNEE_ID:
      return {
        ...state,
        assigneeId: action.assigneeId,
      };

    case NEW_ORDER_SET_NAME:
      return {
        ...state,
        name: action.name,
      };

    case NEW_ORDER_SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };

    case NEW_ORDER_SET_PHONE:
      return {
        ...state,
        phone: action.phone,
      };

    case NEW_ORDER_ADD_COUPON:
      if (action.status === ACTION_SUCCESS) {
        const coupon = action.params.code;
        const offer = action.data.offer;
        const couponDiscount = calculateCouponDiscount(
          state.totalRent,
          coupon,
          offer
        );

        return {
          ...state,
          coupon,
          offer,
          couponDiscount,
        };
      }
      if (action.status === ACTION_FAILED) {
        const errorMessage =
          (action.error && action.error.reason) ||
          formatError('ERR_INVALID_OR_EXPIRED_COUPON');
        swal.fire(errorMessage, '', 'error'); // TODO: Not good to do this in reducer

        return {
          ...state,
          coupon: null,
          couponDiscount: 0,
        };
      }
      if (action.status === ACTION_RESET) {
        return {
          ...state,
          coupon: null,
          couponDiscount: 0,
        };
      }
      return state;

    case NEW_ORDER_SUBMIT:
      if (action.status === ACTION_START) {
        return {
          ...state,
          status: ACTION_WAITING,
        };
      }
      if (action.status === ACTION_SUCCESS) {
        return {
          ...state,
          status: action.status,
        };
      }
      if (action.status === ACTION_FAILED) {
        return {
          ...state,
          status: action.status,
          error: action.error,
        };
      }
      return state;

    default:
      return state;
  }
}
