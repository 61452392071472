import { CARDS_GET, CARD_GET, DELETE_CARD, DEFAULT_CARD } from '../constants';
import errorHandleActions from '../actions/errorHandle';

const getCards = () => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: CARDS_GET,
    apiCall: {
      method: 'GET',
      path: 'cards',
      queryParams: {
        userId: user.userId,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const getCard = cardId => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: CARD_GET,
    apiCall: {
      method: 'GET',
      path: `cards/${cardId}`,
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const deleteCard = cardId => dispatch => {
  dispatch({
    type: DELETE_CARD,
    apiCall: {
      method: 'DELETE',
      path: `cards/${cardId}`,
      callback: (err, res) => {
        dispatch(getCards());
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const makeDefault = cardId => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: DEFAULT_CARD,
    apiCall: {
      method: 'PATCH',
      params: {
        default: true,
        userId: user.userId,
      },
      path: `cards/${cardId}`,
      callback: (err, res) => {
        dispatch(getCards());
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

export default {
  getCards,
  getCard,
  deleteCard,
  makeDefault,
};
