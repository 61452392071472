import React from 'react';
import PropTypes, { any, number } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cx from 'classnames';
import ImageLoader from 'react-imageloader';
import { RequestType, StorageStatus } from '../../constants';
import Loading from '../Loading';
import Pagination from '../Pagination';
import s from './StorageList.module.css';
import { format, formatDate } from '../../format';
import { photoActions, storageActions } from '../../data/actions';
type Props = {
  getPhotos: any;
  title: any;
  onClick: any;
  selectedIds: any;
  mode: any;
  storages: any;
  parts: any;
  requests: any;
  photos: any;
  pagination: any;
  getStorages: (any) => void;
  type: any;
};

type State = {
  page: number;
  itemsPerPage: number;
};

class StorageList extends React.Component<Props, State> {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    storages: PropTypes.arrayOf(PropTypes.object).isRequired,
    parts: PropTypes.arrayOf(PropTypes.object).isRequired,
    photos: PropTypes.arrayOf(PropTypes.object).isRequired,
    settings: PropTypes.object.isRequired,
    requests: PropTypes.arrayOf(PropTypes.object),
    selectedIds: PropTypes.arrayOf(PropTypes.number),
    mode: PropTypes.string,
    title: PropTypes.string,
    getPhotos: PropTypes.func.isRequired,
    getStorages: PropTypes.func.isRequired,
    pagination: PropTypes.object,
    type: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      itemsPerPage: 100,
    };
  }
  public static defaultProps = {
    requests: [],
    selectedIds: [],
    mode: 'LIST',
    title: null,
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { getPhotos } = this.props;
    if (prevProps.storages !== this.props.storages) {
      getPhotos(this.props.storages.map(item => item.id));
    }
  }

  paginate = pageNumber => {
    const { getStorages, type } = this.props;
    const pageSize = 100;
    if (pageNumber !== this.state.page) {
      this.setState({ page: pageNumber });
      const skipNumber = (pageNumber - 1) * pageSize;
      if (type === RequestType.delivery) {
        getStorages({
          skip: skipNumber,
          limit: pageSize,
          status: ['stored', 'in'],
        });
      } else if (type === RequestType.pickup) {
        getStorages({
          skip: skipNumber,
          limit: pageSize,
          status: ['delivered'],
        });
      }
    }
  };

  render() {
    const { title, onClick, selectedIds, mode, pagination } = this.props;
    const selectMode = mode === 'SELECT';
    const StatusValue = {
      forpickup: 0,
      fordelivery: 1,
      delivered: 2,
      in: 3,
      stored: 3,
    };
    const total = pagination ? pagination.total : 0;
    const pageSize = 100;
    const storages = (this.props.storages || [])
      .filter(
        storage => storage.status !== 'closed' && storage.status !== 'cancelled'
      )
      .sort((a, b) => {
        if (StatusValue[a.status] === StatusValue[b.status]) {
          if (a.code && b.code) {
            if (a.code > b.code) return 1;
            if (b.code > a.code) return -1;
            return b.createdAt - a.createdAt;
          }
          if (a.code) return -1;
          if (b.code) return 1;
          return b.createdAt - a.createdAt;
        }
        return StatusValue[a.status] - StatusValue[b.status];
      });
    const requests = (this.props.requests || []).sort(
      (a, b) => b.createdAt - a.createdAt
    );
    if (storages.isFetching) {
      return <Loading />;
    }

    return (
      <div className="mx-auto">
        {title && <h2 className="sub-title">{title}</h2>}
        <div className={s.storageGridContainer}>
          {storages.length === 0 && (
            <p className="text-center">
              You don&#39;t have any items stored with us
            </p>
          )}
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 md:grid-cols-6">
            {storages.map(storage => {
              const statusClass =
                (storage.status === StorageStatus.delivered && 'bg-blue') ||
                (storage.status === StorageStatus.in && 'bg-green') ||
                (storage.status === StorageStatus.stored && 'bg-green') ||
                (storage.status === StorageStatus.fordelivery && 'bg-yellow') ||
                (storage.status === StorageStatus.forpickup && 'bg-yellow');

              let statusText = format(storage.status);
              if (storage.status === StorageStatus.forpickup) {
                const lastRequest = requests.find(
                  r =>
                    r.type === RequestType.pickup &&
                    r.storages.find(st => st.id === storage.id) !== -1
                );
                if (lastRequest) {
                  statusText = `Pickup on ${formatDate(lastRequest.date)}`;
                }
              }
              if (storage.status === StorageStatus.fordelivery) {
                const lastRequest = requests.find(
                  r =>
                    (r.type === RequestType.delivery ||
                      r.type === RequestType.newbox) &&
                    r.storages.find(st => st.id === storage.id) !== -1
                );
                if (lastRequest) {
                  statusText = `Delivery on ${formatDate(lastRequest.date)}`;
                }
              }

              let photo = this.props.photos.find(
                i => i.storageId === storage.id && i.default
              );

              if (!photo) {
                photo = this.props.photos.find(i => i.storageId === storage.id);
              }

              console.log({ code: storage.code, photo });

              const partsCount = this.props.parts
                ? this.props.parts.reduce((totalParts, currentPart) => {
                    if (
                      currentPart.isPart &&
                      currentPart.parentId === storage.id
                    ) {
                      return totalParts + 1;
                    } else {
                      return totalParts;
                    }
                  }, 0)
                : null;

              return (
                <div key={storage.id} className={cx(s.storageCol)}>
                  <button
                    className={`${s.storage} box`}
                    onClick={() => onClick(storage.id)}
                  >
                    {selectMode && (
                      <div
                        className={cx(
                          s.selectStorage,
                          'pretty success circle smooth plain'
                        )}
                      >
                        <input
                          id={`select_${storage.id}`}
                          type="checkbox"
                          checked={
                            (selectedIds || []).indexOf(storage.id) !== -1
                          }
                        />
                        <label htmlFor={`select_${storage.id}`}>
                          <i className="fa fa-check p-5" />
                        </label>
                      </div>
                    )}
                    {true && (
                      <div
                        className={cx(
                          statusClass,
                          s.storageStatus,
                          selectMode && s.selectModeStatus
                        )}
                      >
                        {statusText}
                      </div>
                    )}
                    {partsCount > 0 && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          color: '#39ca74',
                          padding: '2px',
                          border: '1px solid #39ca74',
                        }}
                      >
                        {partsCount} {partsCount > 1 && 'parts'}
                        {partsCount === 1 && 'part'}
                      </div>
                    )}
                    {photo && (
                      <ImageLoader
                        className={s.storageImage}
                        src={photo.thumbnail}
                        imgProps={{ alt: storage.name }}
                        preloader={() => <Loading />}
                      >
                        Image load failed!
                      </ImageLoader>
                    )}
                    {!photo && (
                      <div className={s.storageImage}>
                        <i
                          className="fa fa-archive fa-3x silver"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                    <div className={s.storageCode}>
                      {storage.name || storage.code || 'Unassigned'}
                      <span className="pl-1">
                        {!storage.storagetype.tracking
                          ? `(${storage.qty})`
                          : ''}
                      </span>
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
          <Pagination
            pageSize={pageSize}
            total={total}
            paginate={this.paginate}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    photos: (state as any).photos,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...photoActions,
        ...storageActions,
      },
      dispatch
    )
)(StorageList);
