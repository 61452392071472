import { PAYMENT_GET } from '../constants';
import errorHandleActions from '../actions/errorHandle';

const getPayment = paymentId => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: PAYMENT_GET,
    apiCall: {
      method: 'GET',
      path: `payments/${paymentId}`,
      params: { paymentId },
      queryParams: {
        userId: user.userId,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

export default {
  getPayment,
};
