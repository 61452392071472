import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cx from 'classnames';
import Button from '../components/Button';
import s from './styles.module.scss';
import { newOrderActions, appActions } from '../data/actions';
import { ACTION_START, ACTION_WAITING } from '../data/constants';
import OrderContinue from './OrderContinue';
import OrderHeader from './OrderHeader';
import Money from '../components/Money';
import { RequestStatus } from '../constants';
type Props = {
  user: any;
  newOrder: any;
  setPaymentMode: any;
  routeTo: any;
  config: any;
  requests: any;
  setMonths: any;
  confirmOrder: any;
  addCoupon: any;
  removeCoupon: any;
};
type State = {
  coupon: any;
  addCoupon: any;
};

class OrderConfirm extends React.Component<Props, State> {
  static propTypes = {
    user: PropTypes.object,
    newOrder: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    requests: PropTypes.arrayOf(PropTypes.object).isRequired,
    setPaymentMode: PropTypes.func.isRequired,
    setMonths: PropTypes.func.isRequired,
    confirmOrder: PropTypes.func.isRequired,
    addCoupon: PropTypes.func.isRequired,
    removeCoupon: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
  };
  public static defaultProps = {
    user: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      coupon: '',
      addCoupon: false,
    };
  }

  componentDidMount() {
    const { user, newOrder, setPaymentMode } = this.props;

    if (newOrder.status !== ACTION_START) {
      this.props.routeTo('order-storage');
    }

    if (!newOrder.paymentMode) {
      const mode = user.countryId === 'kuwait' ? 'tap' : 'payfort';
      setPaymentMode(mode);
    }
  }

  render() {
    const {
      user,
      newOrder,
      config,
      setPaymentMode,
      requests,
      setMonths,
      confirmOrder,
      addCoupon,
      removeCoupon,
      routeTo,
    } = this.props;
    const { currency, discounts, storagetypes } = config || {};
    const {
      items,
      months,
      status,
      totalSize,
      rate,
      monthlyRent,
      totalRent,
      discount,
      coupon,
      couponDiscount,
    } = newOrder;
    const minRent = config.countryId === 'kuwait' ? 60000 : 720000;
    const isDisabled = !newOrder.paymentMode || totalRent < minRent;
    const totalDiscountedRent = totalRent - (discount || 0);
    const totalItems = (items || [])
      .map(i => i.quantity)
      .reduce((q, agg) => q + agg, 0);

    // const nextDiscount = discounts
    //   .sort((a, b) => a.target - b.target)
    //   .find(d => totalRent < d.target);
    /* eslint-disable */
    const monthsDiscount = month => {
      return 0;
      const totalForMonth = monthlyRent * month;
      const md = discounts
        .sort((a, b) => b.target - a.target)
        .find(d => totalForMonth >= d.target);
      const d = md ? md.discount : 0;
      return d;
    };

    const plans = [];
    plans.push({ month: 1, discount: 0 }); // No discount for 1 month plan
    plans.push({ month: 3, discount: monthsDiscount(3) });
    plans.push({ month: 12, discount: monthsDiscount(12) });

    const moneyFromCredits = 0; /* Math.min(totalRent - (couponDiscount || 0), (user.credits || 0)); */
    let dueNow =
      totalRent - (discount || 0) - (couponDiscount || 0) - moneyFromCredits;
    dueNow = Math.max(dueNow, 0);

    const couponEnabled =
      (requests || []).filter(r => r.status === RequestStatus.complete)
        .length === 0;

    const paymentGateway = user.countryId === 'kuwait' ? 'tap' : 'payfort';
    const paymentModes = [
      {
        name: 'Cash on Delivery',
        code: 'cod',
        icon: 'fa-money',
      },
      {
        name: 'Credit Card',
        code: paymentGateway,
        icon: 'fa-credit-card',
      },
    ];

    return (
      <div>
        <OrderHeader
          newOrder={newOrder}
          currency={config.currency}
          storagetypes={config.storagetypes}
          isHeaderContinueDisabled={true}
          current="confirm"
          routeTo={routeTo}
        />
        <div className="container mx-auto">
          <h3 key="title1" className={`${s.orderQuestion} text-center`}>
            Great! Now choose a pricing plan
          </h3>

          <div className="row w-2/4 mx-auto">
            <div className="grid col-gap-3 grid-cols-12 col-start-1 m-b-20 mx-auto">
              {/* <div className="row p-10 p-t-0"> */}
              {plans.map(plan => (
                <div
                  key={plan.month}
                  className="col-span-4 col-gap-2 p-t-0 p-b-0 mb-4"
                >
                  <div
                    className={`${s.paymentMonth} box`}
                    onClick={() => {
                      setMonths(plan.month);
                    }}
                    // style={(plan.month !== months) ? { backgroundColor: '#ecf0f1' } : {}}
                  >
                    <div className={'pretty success circle smooth plain'}>
                      <input
                        id={`select_${plan.month}`}
                        type="checkbox"
                        checked={plan.month === months}
                        onChange={() => {}}
                      />
                      <label htmlFor={`select_${plan.month}`}>
                        <i className="fa fa-check p-5" />
                      </label>
                    </div>
                    <p className={s.payForSubtitle}>Pay for </p>
                    <p className={s.payForMonth}>{plan.month}</p>
                    <p className={s.payForSubtitle}>
                      {plan.month === 1 ? 'month' : 'months'}
                    </p>
                    <hr className="m-t-10 m-b-0" />
                    <p className={s.payForSubtitle}>
                      <Money
                        amount={plan.month * monthlyRent}
                        currency={currency}
                        showCurrency
                        skipEmptyFraction
                      />
                    </p>
                    {plan.discount > 0 && (
                      <p className={`m-b-0 green ${s.monthDiscount}`}>
                        <Money
                          amount={plan.discount}
                          currency={currency}
                          showCurrency
                          skipEmptyFraction
                        />{' '}
                        Off
                      </p>
                    )}
                    {plan.discount <= 0 && (
                      <p className={`m-b-0 green ${s.monthDiscount}`}>&nbsp;</p>
                    )}
                  </div>
                </div>
              ))}
              {/* </div> */}
              <div className="box col-span-12 p-10 ">
                {/* <h3 className="sub-title m-b-20">Pricing</h3> */}
                {/* <hr /> */}
                <table className={cx(s.priceTable, 'table table-hover m-b-0')}>
                  <tbody>
                    <tr>
                      <th className="col-span-8">{totalItems} Items</th>
                      <td className="text-right">
                        {/* <Money amount={monthlyRent} currency={currency} showCurrency skipEmptyFraction /> /month */}
                      </td>
                    </tr>
                    {(items || []).map(i => {
                      const st = storagetypes.find(
                        t => t.id === i.storagetypeId
                      );
                      if (!st) return null;
                      // const rent = i.quantity * st.rent;
                      return (
                        <tr key={st.name} className={s.itemRow}>
                          <td className="col-span-8">
                            {/* <span className={s.itemQty}>{i.quantity} x</span> */}
                            {st.name}
                          </td>
                          <td className="text-right">
                            {i.quantity}
                            {/* <Money amount={rent} currency={currency} showCurrency skipEmptyFraction />/mo */}
                          </td>
                        </tr>
                      );
                    })}

                    <tr>
                      <td colSpan={2}>
                        <hr />
                      </td>
                    </tr>

                    <tr className={s.itemListSub}>
                      <td className="col-span-7">Storage Size</td>
                      <td className="col-span-5 text-right">
                        {totalSize.toFixed(2)} CBM
                      </td>
                    </tr>

                    <tr className={s.itemListSub}>
                      <td className="col-span-7">Rate</td>
                      <td className="col-span-5 text-right">
                        <Money
                          amount={rate}
                          currency={currency}
                          showCurrency
                          skipEmptyFraction
                        />{' '}
                        /CBM
                      </td>
                    </tr>

                    <tr className={s.itemListSub}>
                      <td className="col-span-7">Monthly Rent</td>
                      <td className="col-span-5 text-right">
                        <Money
                          amount={monthlyRent}
                          currency={currency}
                          showCurrency
                          skipEmptyFraction
                        />{' '}
                        /month
                      </td>
                    </tr>

                    <tr className={s.itemListTotal}>
                      <th className="col-span-7">Total for {months} months</th>
                      <td className="col-span-5 text-right">
                        {discount > 0 && (
                          <span className={s.rentBeforeDiscount}>
                            <Money
                              amount={totalRent}
                              currency={currency}
                              showCurrency
                              skipEmptyFraction
                            />
                          </span>
                        )}
                        <Money
                          amount={totalDiscountedRent}
                          currency={currency}
                          showCurrency
                          skipEmptyFraction
                        />
                      </td>
                    </tr>

                    {/* {(discount > 0) && (
                      <tr>
                        <th className="col-xs-8">Discount</th>
                        <td className="text-right">
                          -<Money amount={discount} currency={currency} showCurrency skipEmptyFraction />
                        </td>
                      </tr>
                    )} */}
                    {couponEnabled && coupon && (
                      <tr>
                        <th>
                          Coupon &quot;{coupon}&quot;
                          <button
                            className="btn p-0"
                            type="button"
                            onClick={() => removeCoupon()}
                          >
                            <i className="fa fa-times red p-l-10" />
                          </button>
                        </th>
                        <td className="text-right">
                          {couponDiscount && (
                            <span>
                              -{' '}
                              <Money
                                amount={couponDiscount}
                                currency={currency}
                                showCurrency
                                skipEmptyFraction
                              />
                            </span>
                          )}
                        </td>
                      </tr>
                    )}
                    {couponEnabled && !coupon && dueNow > 0 && (
                      <tr>
                        <td
                          colSpan={2}
                          className={`${s.addCouponCode} text-right`}
                        >
                          {!this.state.addCoupon && (
                            <Button
                              className={`${s.addCouponButton}`}
                              text="Add Coupon"
                              onClick={() =>
                                this.setState({
                                  addCoupon: true,
                                })
                              }
                            />
                          )}
                          {this.state.addCoupon && (
                            <div>
                              <input
                                type="text"
                                className="form-control input-sm"
                                value={this.state.coupon}
                                placeholder="Coupon Code"
                                onChange={e => {
                                  this.setState({
                                    coupon: e.target.value,
                                  });
                                }}
                                onKeyPress={e => {
                                  if (e.key === 'Enter') {
                                    addCoupon(this.state.coupon);
                                  }
                                }}
                              />
                              <Button
                                className="p-h-10 p-v-5"
                                onClick={() => addCoupon(this.state.coupon)}
                              >
                                Apply
                              </Button>
                            </div>
                          )}
                        </td>
                      </tr>
                    )}
                    {/*moneyFromCredits > 0 && (
                      <tr>
                        <th>Account Credits</th>
                        <td className="text-right">
                          -<Money amount={moneyFromCredits} currency={currency} showCurrency skipEmptyFraction />
                        </td>
                      </tr>
                    )*/}
                    {dueNow !== totalDiscountedRent && (
                      <tr>
                        <th>Due Now</th>
                        <td className="text-right">
                          <Money
                            amount={dueNow}
                            currency={currency}
                            showCurrency
                            skipEmptyFraction
                          />
                        </td>
                      </tr>
                    )}

                    <tr
                      style={{
                        backgroundColor: '#f5f5f5',
                        marginTop: '10px',
                      }}
                    >
                      <td
                        colSpan={2}
                        style={{
                          fontWeight: 300,
                          color: '#a94442',
                        }}
                      >
                        Transportation and packing will be charged based on your
                        requirements.
                        <br />
                        We will contact you for details.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />

                {totalRent < minRent && (
                  <div className="block alert alert-danger p-5" role="alert">
                    <i
                      className="fa fa-info icon-small p-5 red"
                      aria-hidden="true"
                    />
                    Total Rent should be at least{' '}
                    <strong>
                      <Money
                        amount={minRent}
                        currency={currency}
                        showCurrency
                        skipEmptyFraction
                      />
                    </strong>
                  </div>
                )}

                {totalRent >= minRent && dueNow > 0 && (
                  <div className="center text-center mt-4">
                    <h4 className={s.paymentModeQuestion}>
                      How do you like to pay?
                      {/* You can pay <strong>{config.currency} {formatMoney(dueNow, currency)}</strong> when our team comes for pickup. */}
                    </h4>
                    {paymentModes.map(mode => (
                      <button
                        key={mode.code}
                        className={s.paymentMode}
                        onClick={() => {
                          if (newOrder.paymentMode !== mode.code)
                            setPaymentMode(mode.code);
                        }}
                      >
                        <div className={'pretty success circle smooth plain'}>
                          <input
                            id={`select_${mode.code}`}
                            type="checkbox"
                            checked={newOrder.paymentMode === mode.code}
                            onChange={() => {}}
                          />
                          <label htmlFor={`select_${mode.code}`}>
                            <i className="fa fa-check p-5" />
                          </label>
                        </div>
                        <p>{mode.name}</p>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <OrderContinue
          onContinueClick={() =>
            confirmOrder(() => {
              routeTo('order-status');
            })
          }
          isDisabled={isDisabled}
          isLoading={status === ACTION_WAITING}
          continueText="Confirm"
        />
      </div>
    );
  }
}

// OrderConfirm.propTypes = {
//     user: PropTypes.object,
//     newOrder: PropTypes.object.isRequired,
//     config: PropTypes.object.isRequired,
//     requests: PropTypes.arrayOf(PropTypes.object).isRequired,
//     setPaymentMode: PropTypes.func.isRequired,
//     setMonths: PropTypes.func.isRequired,
//     confirmOrder: PropTypes.func.isRequired,
//     addCoupon: PropTypes.func.isRequired,
//     removeCoupon: PropTypes.func.isRequired,
//     routeTo: PropTypes.func.isRequired,
// };

// OrderConfirm.defaultProps = {
//     user: {},
// };

const OrderConfirmContainer = connect(
  state => ({
    user: (state as any).user,
    config: (state as any).config,
    newOrder: (state as any).newOrder,
    requests: (state as any).requests,
  }),
  dispatch =>
    bindActionCreators({ ...newOrderActions, ...appActions }, dispatch)
)(OrderConfirm);

export default OrderConfirmContainer;
