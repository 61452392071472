import errorHandleActions from '../actions/errorHandle';
import {
  STORAGES_GET_PARTS,
  PHOTOS_GET_PARTS,
  STORAGES_GET_PARTS_NUMBER,
  STORAGES_GET_NUMBER,
  STORAGES_GET_STATS,
  STORAGES_ALL_GET,
  STORAGES_GET_DELIVERED_NUMBER,
  CURRENT_STORAGES_GET,
} from '../constants';

const getStorageParts = (parentId, callbackFn) => (dispatch, getState) => {
  const limit = 100;
  dispatch({
    type: STORAGES_GET_PARTS,
    apiCall: {
      method: 'GET',
      path: 'storages',
      queryParams: {
        parentId,
        $limit: limit,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }

        callbackFn(res);
      },
    },
  });
};

const getPartPhotos = storageId => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: PHOTOS_GET_PARTS,
    apiCall: {
      method: 'GET',
      path: 'photos',
      queryParams: {
        userId: user.userId,
        storageId,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const getAllStoragePartsNumber = () => (dispatch, getState) => {
  const user = getState().user;
  const limit = 0;
  dispatch({
    type: STORAGES_GET_PARTS_NUMBER,
    apiCall: {
      method: 'GET',
      path: 'storages',
      queryParams: {
        userId: user.userId,
        isPart: true,
        $limit: limit,
        status: ['in', 'stored'],
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const getAllStorageNumber = () => (dispatch, getState) => {
  const user = getState().user;
  const limit = 0;
  dispatch({
    type: STORAGES_GET_NUMBER,
    apiCall: {
      method: 'GET',
      path: 'storages',
      queryParams: {
        userId: user.userId,
        isPart: false,
        $limit: limit,
        status: ['in', 'stored'],
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const getStats = status => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: STORAGES_GET_STATS,
    apiCall: {
      method: 'GET',
      path: 'storage-statistics',
      queryParams: {
        userId: user.userId,
        storageStatus: status,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const getAllStorages = (params = null) => (dispatch, getState) => {
  const user = getState().user;
  const skip = params && params.skip ? params.skip : 0;
  const limit = params && params.limit ? params.limit : 100;
  dispatch({
    type: STORAGES_ALL_GET,
    apiCall: {
      method: 'GET',
      path: 'storages',
      queryParams: {
        userId: user.userId,
        $skip: skip,
        $limit: limit,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const getDeliveredStoragesNum = () => (dispatch, getState) => {
  const user = getState().user;
  const limit = 0;
  dispatch({
    type: STORAGES_GET_DELIVERED_NUMBER,
    apiCall: {
      method: 'GET',
      path: 'storages',
      queryParams: {
        userId: user.userId,
        isPart: false,
        $limit: limit,
        status: ['delivered'],
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const getCurrentStorage = id => (dispatch, getState) => {
  const user = getState().user;
  dispatch({
    type: CURRENT_STORAGES_GET,
    apiCall: {
      method: 'GET',
      path: 'storages',
      queryParams: {
        userId: user.userId,
        id,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

export default {
  getStorageParts,
  getPartPhotos,
  getAllStoragePartsNumber,
  getAllStorageNumber,
  getStats,
  getAllStorages,
  getDeliveredStoragesNum,
  getCurrentStorage,
};
