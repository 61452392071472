import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import s from './CardPayfort.module.scss';
import { newCardActions } from '../data/actions';
import { useHistory, useLocation } from 'react-router-dom';

const CardTapSave = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const errorRef = useRef();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const tap_id = searchParams.get('tap_id');
  useEffect(() => {
    console.log(search);
    setLoading(true);
    const mode = 'tap';
    const params = {
      authId: tap_id ? tap_id : null,
      command: 'VALIDATE_SAVE_CARD',
    };
    dispatch(
      newCardActions.createCard(mode, params, (err, res) =>
        handleResponse(err, res)
      )
    );
  }, []);

  const handleResponse = (err, res) => {
    setLoading(false);
    if (err) {
      const current: any = errorRef.current;
      current.innerHTML = err;
    } else if (res) {
      history.push('/profile');
    }
  };

  return (
    <div className={s.cardTapContainer}>
      {loading && <div id="loading-container"></div>}
      <div
        ref={errorRef}
        role="alert"
        id="error-handler"
        style={{
          color: 'red',
          marginLeft: '1rem',
          fontSize: '1.2rem',
        }}
      ></div>
    </div>
  );
};

export default CardTapSave;
