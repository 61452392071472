import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import {
  appActions,
  userActions,
  invoiceActions,
  configActions,
  storageActions,
  requestActions,
  storagePartsActions,
} from './data/actions';
import Layout from './components/Layout';
import Loading from './components/Loading';
import routes from './routes';
import { getQueryParams, getCountryFromIP } from './utils';
import { UserStatus } from './constants';
import settings from './settings';
import history from './history';
import ErrorPopup from './components/ErrorPopup/ErrorPopup';

type Props = {
  isLoggedIn: any;
  getConfig: any;
  getUser: any;
  getAllStorages: any;
  getRequests: any;
  getInvoices: any;
  user: any;
  config: any;
  routeTo: any;
};

class Root extends React.Component<Props> {
  static propTypes = {
    routeTo: PropTypes.func.isRequired,
    getConfig: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    getAllStorages: PropTypes.func.isRequired,
    getRequests: PropTypes.func.isRequired,
    getInvoices: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    config: PropTypes.object,
    user: PropTypes.object,
  };
  public static defaultProps = {
    config: {},
    user: {},
  };
  componentDidMount() {
    const {
      isLoggedIn,
      getConfig,
      getUser,
      getAllStorages,
      getRequests,
      getInvoices,
    } = this.props;

    if (isLoggedIn) {
      getUser();
      getAllStorages();
      getRequests();
      getInvoices();
    } else {
      // @ts-ignore
      const { city, country } = getQueryParams();
      if (city || country) {
        getConfig(country, city);
      } else {
        if (process.env.REACT_APP_ENV === 'production') {
          getCountryFromIP((err, ipCountry) => {
            getConfig(ipCountry);
          });
        } else {
          getConfig(1);
        }
      }
    }

    ReactGA.initialize('UA-80931409-1');
    history.listen(() => {
      // const { user } = this.props;
      // let isGuest = true;
      // if (isLoggedIn && user && user.status !== UserStatus.new) {
      //   isGuest = false;
      // }

      // ReactGA.set({
      //   page: window.location.pathname + window.location.search,
      //   dimension3: isGuest,
      // });
      ReactGA.pageview(window.location.pathname + window.location.search);

      window.scrollTo(0, 0);
    });
  }

  render() {
    const { config, isLoggedIn, routeTo } = this.props;
    const isReady = config && config.countryId;

    if (!isReady) {
      return (
        <Layout {...this.props}>
          <ErrorPopup />
          <Loading className="m-t-50" />
        </Layout>
      );
    }

    return (
      <ConnectedRouter history={history}>
        <Switch>
          {routes.map(
            (route: {
              name: string;
              path: string;
              title?: string;
              description?: string;
              keywords?: string;
              loginRequired?: boolean;
              fullWidth?: boolean;
              component: any;
            }) => (
              <Route
                exact
                key={route.name}
                path={route.path}
                component={props => {
                  if (route.loginRequired && !isLoggedIn) {
                    routeTo('login', {}, { next: props.match.url });
                    return <Loading />;
                  }

                  document.title = `${
                    route.title ? `${route.title} - ` : ''
                  } Boxit Storage`;
                  if (route.description) {
                    document
                      .querySelector('meta[name="description"]')
                      .setAttribute('content', route.description);
                  }
                  if (route.keywords) {
                    document
                      .querySelector('meta[name="keywords"]')
                      .setAttribute('content', route.keywords);
                  }

                  const queryParams = getQueryParams();
                  const urlParams =
                    (props && props.match && props.match.params) || {};
                  const allProps = {
                    ...props,
                    queryParams,
                    urlParams,
                    settings,
                  };
                  return (
                    <Layout
                      {...this.props}
                      className={route.fullWidth && 'full-width'}
                    >
                      <ErrorPopup />
                      <route.component {...allProps} />
                    </Layout>
                  );
                }}
              />
            )
          )}
        </Switch>
      </ConnectedRouter>
    );
  }
}

// Root.propTypes = {
//   routeTo: PropTypes.func.isRequired,
//   getConfig: PropTypes.func.isRequired,
//   getUser: PropTypes.func.isRequired,
//   getStorages: PropTypes.func.isRequired,
//   getRequests: PropTypes.func.isRequired,
//   getInvoices: PropTypes.func.isRequired,
//   isLoggedIn: PropTypes.bool.isRequired,
//   config: PropTypes.object,
//   user: PropTypes.object,
// };

// Root.defaultProps = {
//   config: {},
//   user: {},
// };

const RootContainer = connect(
  state => ({
    config: (state as any).config,
    isLoggedIn: !!(state as any).user.accessToken,
    user: (state as any).user.user,
    invoices: (state as any).invoices,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...appActions,
        ...configActions,
        ...userActions,
        ...invoiceActions,
        ...storageActions,
        ...requestActions,
        ...storagePartsActions,
      },
      dispatch
    )
)(Root);

export default RootContainer;
