import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PaymentMode } from '../constants';
import Loading from '../components/Loading';
import { newPaymentActions, appActions } from '../data/actions';
import madaLogo from './mada.png';
import mastercardLogo from './mastercard.png';
import visaLogo from './visa.png';
type Props = {
  settings: any;
  createPayment: any;
  computeHash: any;
  urlParams: any;
  routeTo: any;
  newPayment: any;
  userData: any;
};
class PayfortPay extends React.Component<Props> {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    urlParams: PropTypes.object.isRequired,
    createPayment: PropTypes.func.isRequired,
    newPayment: PropTypes.object.isRequired,
    computeHash: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    userData: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);

    this.state = {
      hash: null,
    };
  }

  componentDidMount() {
    const {
      settings,
      createPayment,
      computeHash,
      urlParams,
      routeTo,
      userData,
    } = this.props;
    const payfort = settings.payfort;
    const { userId, amount } = urlParams;
    const queryParams = new URLSearchParams(window.location.search);
    const countryId = queryParams.get('countryId');
    console.log('countryId', countryId);
    console.log('queryParams', queryParams);
    console.log('urlParamss', urlParams);


    if (!amount) {
      return;
    }

    createPayment(
      {
        userId,
        amount,
        currency: countryId == '3' ? 'SAR' : 'AED',
        type: 'payment',
        mode: PaymentMode.payfort,
      },
      (err, payment) => {
        if (err) {
          if (window.history.length > 2) {
            window.history.back();
          } else {
            routeTo('home');
          }
        } else {
          computeHash(PaymentMode.payfort, {
            access_code:
              countryId === '3' ? payfort.saudiAccessCode : payfort.accessCode,
            amount: Math.round(payment.amount / 100),
            command: 'PURCHASE',
            currency: payment.currency,
            customer_email: payment.user.email,
            language: 'en',
            merchant_identifier:
              countryId === '3' ? payfort.saudiMerchentId : payfort.merchantId, // payfort.merchantId,
            merchant_reference: payment.id,
            order_description: 'Boxit Storage',
            return_url: payfort.returnUrl,
          });
        }
      }
    );

    const i = setInterval(() => {
      if (
        $('#payfortForm #signature').val() &&
        $('#payfortForm [name=amount]').val()
      ) {
        $('#payfortForm').submit();
        clearInterval(i);
      }
    }, 500);
  }

  render() {
    const { newPayment, settings, userData } = this.props;
    const payfortSettings = settings.payfort;
    const { id, hashStatus, user, currency, amount } = newPayment || {};
    const payfortAmount = Math.round(amount / 100);
    const { email } = user || {};
    const {
      merchantId,
      saudiMerchentId,
      accessCode,
      saudiAccessCode,
      returnUrl,
      paymentUrl,
    } = payfortSettings || {};
    const itemName = 'Boxit Storage';
    const hash = (hashStatus && hashStatus.hash) || '';
    const queryParams = new URLSearchParams(window.location.search);
    const countryId = queryParams.get('countryId');

    return (
      <div>
        <div className="">
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            <h2
              style={{
                fontSize: '1.5rem',
                fontWeight: 500,
                marginBottom: '1rem',
              }}
            >
              Available payment methods
            </h2>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '2rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                <img src={madaLogo} style={{ width: 'auto', height: '5rem' }} />
                <h4 style={{ fontSize: '1rem' }}>
                  mada bank card / Credit card
                </h4>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                <img
                  src={mastercardLogo}
                  style={{ width: 'auto', height: '5rem' }}
                />
                <h4 style={{ fontSize: '1rem' }}>
                  mastercard bank card / Credit card
                </h4>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                <img src={visaLogo} style={{ width: 'auto', height: '5rem' }} />
                <h4 style={{ fontSize: '1rem' }}>
                  visa bank card / Credit card
                </h4>
              </div>
            </div>
          </div>
          <h2 className="sub-title m-t-20 m-b-20">Redirecting...</h2>
          <div className="grid grid-cols-1 mx-auto md:w-1/2">
            <div className="box center text-center p-20">
              <Loading />
              <form id="payfortForm" method="post" action={paymentUrl}>
                <input
                  type="hidden"
                  name="access_code"
                  value={
                    (countryId === '3' ? saudiAccessCode : accessCode) || ''
                  }
                />
                <input type="hidden" name="amount" value={payfortAmount || 0} />
                <input type="hidden" name="command" value="PURCHASE" />
                <input type="hidden" name="currency" value={currency || ''} />
                <input
                  type="hidden"
                  name="customer_email"
                  value={email || ''}
                />
                <input type="hidden" name="language" value="en" />
                <input
                  type="hidden"
                  name="merchant_identifier"
                  value={countryId === '3' ? saudiMerchentId : merchantId}
                />
                <input
                  type="hidden"
                  name="merchant_reference"
                  value={id || ''}
                />
                <input
                  type="hidden"
                  name="order_description"
                  value={itemName || ''}
                />
                <input
                  type="hidden"
                  name="return_url"
                  value={returnUrl || ''}
                />
                <input
                  id="signature"
                  type="hidden"
                  name="signature"
                  value={hash || ''}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// PayfortPay.propTypes = {
//     settings: PropTypes.object.isRequired,
//     urlParams: PropTypes.object.isRequired,
//     createPayment: PropTypes.func.isRequired,
//     newPayment: PropTypes.object.isRequired,
//     computeHash: PropTypes.func.isRequired,
//     routeTo: PropTypes.func.isRequired,
// };

const PayfortPayContainer = connect(
  state => ({
    newPayment: (state as any).newPayment,
    userData: (state as any).user.user,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...newPaymentActions,
        ...appActions,
      },
      dispatch
    )
)(PayfortPay);

export default PayfortPayContainer;
