import {
  REQUEST_GET,
  REQUESTS_GET,
  ACTION_START,
  ACTION_FAILED,
  ACTION_SUCCESS,
} from '../constants';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_GET:
      if (action.status === ACTION_START) {
        const requests = Object.assign([...state], {
          isFetching: true,
          error: null,
        });
        return requests;
      }
      if (action.status === ACTION_SUCCESS) {
        const requests = Object.assign([...state, action.data], {
          isFetching: false,
          error: null,
        });
        return requests;
      }
      if (action.status === ACTION_FAILED) {
        const requests = Object.assign([...state], {
          isFetching: false,
          error: action.error,
        });
        return requests;
      }
      return state;

    case REQUESTS_GET:
      if (action.status === ACTION_START) {
        const requests = Object.assign([...state], {
          isFetching: true,
          error: null,
        });
        return requests;
      }
      if (action.status === ACTION_SUCCESS) {
        const requests = Object.assign([...state, ...action.data.data], {
          isFetching: false,
          error: null,
        });
        return requests;
      }
      if (action.status === ACTION_FAILED) {
        const requests = Object.assign([...state], {
          isFetching: false,
          error: action.error,
        });
        return requests;
      }
      return state;

    default:
      return state;
  }
}
