import { ORDER_UPDATE } from '../constants';
import errorHandleActions from '../actions/errorHandle';

const orderUpdate = (
  id: number,
  params: any,
  accessToken: string
) => dispatch => {
  dispatch({
    type: ORDER_UPDATE,
    apiCall: {
      method: 'PATCH',
      path: `orders-access/${id}?accessToken=${accessToken}`,
      params,
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

export default {
  orderUpdate,
};
