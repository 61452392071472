import React from 'react';
import swal from 'sweetalert2';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import validator from 'validator';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Button from '../components/Button';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import s from './styles.module.css';
import {
  userActions,
  appActions,
  cardActions,
  addressActions,
} from '../data/actions';
import { ACTION_FAILED, ACTION_SUCCESS } from '../data/constants';
import Money from '../components/Money';
import { City, Country } from '../constants';

type Props = {
  user: any;
  getCards: any;
  allCities: any;
  deleteCard: any;
  makeDefault: any;
  getAddresses: any;
  getAllCities: any;
  updateUser: any;
  updateAddress: any;
  config: any;
  cards: any;
  addresses: any;
  changePassword: any;
  addAddress: any;
  logout: any;
  routeTo: any;
  getCities: any;
  cities: any;
};
type State = {
  userSavedData: any;
  saveAddressData: any;
  user: any;
  editMode: any;
  password: any;
  newPassword: any;
  addressName: string;
  addressLine: string;
  addressArea: string;
  autoCompleteAddress: any;
  editedAddress: any;
  addressCity: string;
  showAddressForm: boolean;
  showChangePasswordForm: boolean;
};

class ProfilePage extends React.Component<Props, State> {
  static propTypes = {
    updateUser: PropTypes.func.isRequired,
    updateAddress: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    addAddress: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    cards: PropTypes.array.isRequired,
    addresses: PropTypes.array.isRequired,
    allCities: PropTypes.array.isRequired,
    getCards: PropTypes.func.isRequired,
    deleteCard: PropTypes.func.isRequired,
    makeDefault: PropTypes.func.isRequired,
    getAddresses: PropTypes.func.isRequired,
    getAllCities: PropTypes.func.isRequired,
    getCities: PropTypes.func.isRequired,
    cities: PropTypes.array.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      newPassword: '',
      addressName: '',
      addressLine: '',
      addressArea: '',
      autoCompleteAddress: '',
      editedAddress: '',
      addressCity: '',
      user: props.user,
      userSavedData: props.user,
      saveAddressData: {},
      showAddressForm: false,
      showChangePasswordForm: false,
      editMode: {
        name: false,
        email: false,
        phone: false,
        phone2: false,
        password: null,
        newPassword: null,
      },
    };
  }

  componentDidMount() {
    const { getCards, getAddresses, getAllCities, getCities } = this.props;
    getCards();
    getAddresses();
    getAllCities();
    getCities();
  }

  isNameValid() {
    const { userSavedData } = this.state;
    return userSavedData.name.trim().length > 0;
  }

  isPhoneNumberValid(phone) {
    if (!phone || phone.length < 5) {
      return false;
    }
    const phoneNumber = phone[0] === '+' ? phone.substring(1) : phone;
    return validator.isNumeric(phoneNumber);
  }

  isPhoneValid() {
    const { userSavedData } = this.state;
    return this.isPhoneNumberValid(userSavedData.phone);
  }

  isPhone2Valid() {
    const { userSavedData } = this.state;
    return this.isPhoneNumberValid(userSavedData.phone2);
  }

  isEmailValid() {
    const { userSavedData } = this.state;
    const email = userSavedData.email;
    return validator.isEmail(email);
  }

  resetAddressForm() {
    this.setState({
      addressName: '',
      addressLine: '',
      addressArea: '',
      addressCity: '',
    });
  }

  update() {
    const { user } = this.state;
    const { userSavedData } = this.state;
    const name = this.isNameValid() ? userSavedData.name : user.name;
    const email = this.isEmailValid() ? userSavedData.email : user.email;
    const phone = this.isPhoneValid() ? userSavedData.phone : user.phone;
    const phone2 = this.isPhone2Valid() ? userSavedData.phone2 : user.phone2;

    this.props.updateUser({
      name,
      email,
      phone,
      phone2,
    });
  }

  updateAddress() {
    const { saveAddressData } = this.state;
    const id = saveAddressData.address.id;
    const name = saveAddressData.address.name;
    const line1 = saveAddressData.address.line1;
    const area = saveAddressData.address.area;
    this.props.updateAddress(id, {
      name,
      line1,
      area,
    });
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderEditableAddress(address, countryCode) {
    const { editMode, saveAddressData } = this.state;
    const { allCities } = this.props;
    const key = address.id;
    const isEditMode = editMode[key] || false;
    const name = saveAddressData.address?.name;
    const line1 = saveAddressData.address?.line1;
    const area = saveAddressData.address?.area;
    return (
      <div key={address.id} className="col-span-12">
        {!isEditMode && (
          <div className={`pt-3 ${s.userEdit}`}>
            <button
              type="button"
              className={`px-3 py-2 mb-2 bg-dark-blue text-gray-200 rounded`}
              onClick={() => {
                this.setState({
                  editMode: {
                    ...this.state.editMode,
                    [key]: true,
                  },
                  saveAddressData: {
                    address,
                  },
                });
                this.setState({
                  editedAddress: '',
                });
              }}
            >
              Edit
            </button>
            <table className="table table-bordered table-hover border w-full">
              <tbody>
                <tr>
                  <td className="w-1/3 px-4 py-2 border">Name</td>
                  <td className="w-2/3 px-4 py-2 border">{address.name}</td>
                </tr>
                <tr>
                  <td className="w-1/3 px-4 py-2 border">Line</td>
                  <td className="w-2/3 px-4 py-2 border">{address.line1}</td>
                </tr>
                <tr>
                  <td className="w-1/3 px-4 py-2 border">Area</td>
                  <td className="w-2/3 px-4 py-2 border">{address.area}</td>
                </tr>
                <tr>
                  <td className="w-1/3 px-4 py-2 border">City</td>
                  <td className="w-2/3 px-4 py-2 border">
                    {!!allCities &&
                    allCities.findIndex(item => item.id === address.cityId) !==
                      -1
                      ? allCities[
                          allCities.findIndex(
                            item => item.id === address.cityId
                          )
                        ].name
                      : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {isEditMode && (
          <div className={`${s.userEdit} pt-3`}>
            <button
              type="button"
              className={`px-3 py-2 mb-2 bg-green-500 text-gray-200 rounded`}
              onClick={() => {
                this.updateAddress();
                this.setState({
                  editMode: {
                    ...this.state.editMode,
                    [key]: false,
                  },
                });
              }}
            >
              Save
            </button>
            <table className="table table-bordered table-hover border w-full">
              <tbody>
                <tr>
                  <td className="w-1/3 px-4 py-2 border">Name</td>
                  <td className="w-2/3 px-2 py-2 border">
                    <input
                      type="text"
                      maxLength={25}
                      className="appearance-none border rounded w-full py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline input-sm"
                      value={name}
                      placeholder={'Name'}
                      onChange={event => {
                        event.preventDefault();
                        const newValue = event.target.value;
                        this.setState(prevState => {
                          return {
                            saveAddressData: {
                              ...prevState.saveAddressData,
                              address: {
                                ...prevState.saveAddressData.address,
                                name: newValue,
                              },
                            },
                          };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="w-1/3 px-4 py-2 border">Line</td>
                  <td className="w-2/3 px-2 py-2 border">
                    <input
                      type="text"
                      maxLength={25}
                      className="appearance-none border rounded w-full py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline input-sm"
                      value={line1}
                      placeholder={'Line'}
                      onChange={event => {
                        event.preventDefault();
                        const newValue = event.target.value;
                        this.setState(prevState => {
                          return {
                            saveAddressData: {
                              ...prevState.saveAddressData,
                              address: {
                                ...prevState.saveAddressData.address,
                                line1: newValue,
                              },
                            },
                          };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="w-1/3 px-4 py-2 border">Area</td>
                  <td className="w-2/3 px-2 py-2 border">
                    <GooglePlacesAutocomplete
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: [countryCode],
                        },
                      }}
                      selectProps={{
                        placeholder: 'Area',
                        required: true,
                        value: this.state.editedAddress,
                        onChange: event => {
                          const area = event.label.split(/,|-/);
                          event.label = area[0];
                          const newValue = event.label;
                          this.setState({
                            editedAddress: event,
                          });
                          this.setState(prevState => {
                            return {
                              saveAddressData: {
                                ...prevState.saveAddressData,
                                address: {
                                  ...prevState.saveAddressData.address,
                                  area: newValue,
                                },
                              },
                            };
                          });
                        },
                        className: `appearance-none border rounded w-4/5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline `,
                        styles: {
                          control: provided => ({
                            ...provided,
                            paddingTop: 0,
                            paddingBottom: 0,
                            borderColor: 'rgba(229, 231, 235 )',
                          }),
                        },
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="w-1/3 px-4 py-2 border">City</td>
                  <td className="w-2/3 px-2 py-2 border">
                    {allCities.findIndex(item => item.id === address.cityId) !==
                    -1
                      ? allCities[
                          allCities.findIndex(
                            item => item.id === address.cityId
                          )
                        ].name
                      : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }

  renderEditableRow(key, label, isValid) {
    const { editMode, userSavedData } = this.state;
    const isEditMode = editMode[key] || false;
    const data = userSavedData[key];
    const placeholder = 'Your ' + label;

    return (
      <tr>
        <td className="w-1/3 px-4 py-2 border">{label}</td>
        <td className="w-2/3 px-4 py-2 border">
          {!isEditMode && (
            <div className={s.userEdit}>
              {data}
              <button
                type="button"
                className={`px-3 bg-dark-blue text-gray-200 rounded`}
                onClick={() => {
                  this.setState({
                    editMode: {
                      ...this.state.editMode,
                      [key]: true,
                    },
                  });
                }}
              >
                Edit
              </button>
            </div>
          )}
          {isEditMode && (
            <div className={s.userEdit}>
              <input
                type="text"
                maxLength={25}
                className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline input-sm"
                value={data}
                placeholder={placeholder}
                onChange={e => {
                  this.setState({
                    userSavedData: {
                      ...userSavedData,
                      [key]: e.target.value,
                    },
                  });
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter' && isValid) {
                    this.update();
                    this.setState({
                      editMode: {
                        ...this.state.editMode,
                        [key]: false,
                      },
                    });
                  }
                }}
              />
              <button
                className={`px-3 py-2 bg-green-500 text-white rounded`}
                disabled={!isValid}
                onClick={() => {
                  this.update();
                  this.setState({
                    editMode: {
                      ...this.state.editMode,
                      [key]: false,
                    },
                  });
                }}
              >
                Save
              </button>
            </div>
          )}
        </td>
      </tr>
    );
  }

  render() {
    const {
      user,
      config,
      cards,
      addresses,
      changePassword,
      addAddress,
      deleteCard,
      makeDefault,
      cities,
    } = this.props;
    const { changePasswordStatus } = user;
    const countryCode =
      user.countryId === 1 ? 'ae' : user.countryId === 2 ? 'kw' : 'sa';
    const isEmail = this.isEmailValid();
    // const isName = this.isNameValid();
    const isPhone = this.isPhoneValid();
    const isPhone2 = this.isPhone2Valid();

    let changePasswordMessage;
    if (changePasswordStatus) {
      if (changePasswordStatus.status === ACTION_SUCCESS) {
        changePasswordMessage = {
          class: 'alert-success',
          message: 'Password Changed Successfully',
        };
      }
      if (changePasswordStatus.status === ACTION_FAILED) {
        changePasswordMessage = {
          class: 'alert-danger',
          message: changePasswordStatus.error,
        };
      }
    }

    console.log(user.cities);

    return (
      <div>
        <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">
          Profile
        </h2>
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className={`${s.profileContainer} center`}>
              <div className="p-8 lg:p-20 bg-white shadow sm:rounded-lg lg:rounded-xl">
                <h3 className="sub-title m-t-0 m-b-20">Account</h3>
                <table className=" w-full border-collapse border">
                  <tbody>
                    <tr>
                      <td className="w-1/3 px-4 py-2 border">Name</td>
                      <td className="w-2/3 px-4 py-2 border">{user.name}</td>
                    </tr>
                    {this.renderEditableRow('email', 'Email', isEmail)}
                    {this.renderEditableRow('phone', 'Phone', isPhone)}
                    {this.renderEditableRow('phone2', 'Phone', isPhone2)}
                    <tr>
                      <td className="w-1/3 px-4 py-2 border">
                        Credits
                        {user.credits > 0 && (
                          <i
                            className={`${s.cashIcon} bg-green fa fa-money`}
                            aria-hidden="true"
                          />
                        )}
                      </td>
                      <td className="w-2/3 px-4 py-2 border">
                        <Money
                          amount={user.credits}
                          currency={
                            user.countryId === 1
                              ? 'AED'
                              : user.countryId === 2
                              ? 'KWD'
                              : 'SAR'
                          }
                          showCurrency
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-1/3 px-4 py-2 border">Country</td>
                      <td className="w-2/3 px-4 py-2 border">
                        {user.countryId === 1
                          ? 'UAE'
                          : user.countryId === 2
                          ? 'Kuwait'
                          : 'KSA'}
                      </td>
                    </tr>
                    {user.trn && (
                      <tr>
                        <td className="w-1/3 px-4 py-2  border">TRN</td>
                        <td className="w-2/3 px-4 py-2  border">{user.trn}</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <hr />
                <h3 className="sub-title my-10">Saved Cards</h3>

                {cards.length ? (
                  <div className="grid grid-cols-12">
                    <div className="col-span-12">
                      <table className="w-full border-collapse border">
                        <thead>
                          <tr>
                            <th className="px-4 py-2 border">Card Number</th>
                            <th className="px-4 py-2 border">Name</th>
                            <th className="px-4 py-2 border">Expiry</th>
                            <th className="px-4 py-2 border">Status</th>
                            <th className="px-4 py-2 border"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {cards.map(card => (
                            <tr key={card.id}>
                              <td className="px-4 py-2 border">
                                {card.cardNumber}
                              </td>
                              <td className="px-4 py-2 border">
                                {card.cardHolderName.replace('+', ' ')}
                              </td>
                              <td className="px-4 py-2 border">{`${card.expiryDate.substring(
                                2,
                                4
                              )} / ${card.expiryDate.substring(0, 2)}`}</td>
                              <td className="px-4 py-2 border">
                                {this.capitalizeFirstLetter(card.status)}
                              </td>
                              {(card.status === 'inactive' ||
                                user.countryId === 2) &&
                              cards.length > 1 ? (
                                <td className="px-5 py-2 p-1 border flex gap-2">
                                  {!card.default && (
                                    <Button
                                      className={`bg-red p-2`}
                                      text="Delete"
                                      onClick={() => {
                                        swal
                                          .fire({
                                            title: 'Are you sure?',
                                            text:
                                              'Are you sure you want to delete this card?',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText:
                                              'Yes, delete it!',
                                          })
                                          .then(confirmed => {
                                            if (confirmed) {
                                              deleteCard(card.id);
                                              swal.fire(
                                                'Deleted!',
                                                'Your card has been deleted.',
                                                'success'
                                              );
                                            }
                                          });
                                      }}
                                    />
                                  )}
                                  {card.default ? (
                                    <div className="">
                                      <i
                                        className={`fa fa-check px-7 py-4`}
                                        aria-hidden="true"
                                      />
                                      Current Default
                                    </div>
                                  ) : (
                                    <Button
                                      className={`bg-primary p-2`}
                                      text="Make Default"
                                      onClick={() => {
                                        swal
                                          .fire({
                                            title: 'Are you sure?',
                                            text:
                                              'Are you sure you want to make this card your default card?',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'Confirm',
                                          })
                                          .then(confirmed => {
                                            if (confirmed.value) {
                                              makeDefault(card.id);
                                              swal.fire(
                                                'Success!',
                                                'Card is set as the default card.',
                                                'success'
                                              );
                                            }
                                          });
                                      }}
                                    />
                                  )}
                                </td>
                              ) : (
                                <td className="px-4 py-2 border">
                                  {card.default ? (
                                    <div className="">
                                      <i
                                        className={`fa fa-check px-4`}
                                        aria-hidden="true"
                                      />
                                      Current Default
                                    </div>
                                  ) : (
                                    <Button
                                      className={`bg-primary p-5`}
                                      text="Make Default"
                                      onClick={() => {
                                        swal
                                          .fire({
                                            title: 'Are you sure?',
                                            text:
                                              'Are you sure you want to make this card your default card?',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'Confirm',
                                          })
                                          .then(confirmed => {
                                            if (confirmed.value) {
                                              makeDefault(card.id);
                                              swal.fire(
                                                'Success!',
                                                'Card is set as the default card.',
                                                'success'
                                              );
                                            }
                                          });
                                      }}
                                    />
                                  )}
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="text-center" style={{ textAlign: 'center' }}>
                    <h5>You have not saved any cards!</h5>
                  </div>
                )}

                <div className="text-center">
                  <Button
                    className="block mx-auto my-10 px-5 border border-transparent rounded shadow-sm bg-boxit hover:bg-boxit-light focus:outline-none focus:ring focus:ring-offset-2 focus:ring-boxit-light focus:ring-opacity-50 active:bg-boxit text-white"
                    onClick={() => {
                      if (
                        user &&
                        (user.countryId === 1 || user.countryId === 3)
                      ) {
                        this.props.routeTo('card-payfort');
                      } else if (user && user.countryId === 2) {
                        this.props.routeTo('card-tap');
                      }
                    }}
                    text={cards.length ? 'Save New Card' : 'Add Card'}
                  />
                </div>

                <hr />
                <h3 className="sub-title my-10">Addresses</h3>

                {addresses.length ? (
                  <div className="grid grid-cols-12">
                    {addresses
                      .sort((a, b) => a.code - b.code)
                      .map(address =>
                        this.renderEditableAddress(address, countryCode)
                      )}
                  </div>
                ) : (
                  <h5 className="text-center">
                    You have not added an address!
                  </h5>
                )}

                {this.state.showAddressForm && (
                  <div>
                    <div className="my-8 p-8 border border-gray-200 bg-gray-100 rounded">
                      <div className="mb-4 grid grid-cols-12">
                        <label htmlFor="addressname col-span-12">Name</label>
                        <input
                          type="text"
                          className="col-span-12 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={this.state.addressName || ''}
                          id="addressname"
                          placeholder="Title"
                          onChange={e => {
                            this.setState({
                              addressName: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="addressline">Line</label>
                        <input
                          type="text"
                          className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={this.state.addressLine || ''}
                          id="addressline"
                          placeholder="Line"
                          onChange={e => {
                            this.setState({
                              addressLine: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="addressarea">Area</label>
                        <GooglePlacesAutocomplete
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: [countryCode],
                            },
                          }}
                          selectProps={{
                            placeholder: 'Area',
                            required: true,
                            value: this.state.autoCompleteAddress,
                            onChange: e => {
                              const area = e.label.split(/,|-/);
                              e.label = area[0];
                              this.setState({
                                addressArea: e.label,
                              });
                              this.setState({
                                autoCompleteAddress: e,
                              });
                            },
                            className: `appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline `,
                            styles: {
                              control: provided => ({
                                ...provided,
                                paddingTop: 0,
                                paddingBottom: 0,
                                borderColor: 'rgba(243, 244, 246)',
                              }),
                            },
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="addresscity">City</label>
                        {/*<input
                          type="text"
                          className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={this.state.addressCity || ''}
                          id="addresscity"
                          placeholder="City"
                          onChange={e => {
                            this.setState({
                              addressCity: e.target.value,
                            });
                          }}
                        />*/}
                        <select
                          id="addressCity"
                          className="form-control col-span-12 p-3 focus:ring-indigo-500 focus:border-indigo-500 border relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                          value={this.state.addressCity || ''}
                          onChange={e => {
                            if (e.target.value !== 'Select City') {
                              this.setState({
                                addressCity: e.target.value,
                              });
                            }
                          }}
                        >
                          {cities &&
                            cities.map(c => (
                              <option
                                key={c.key}
                                // @ts-ignore
                                name="addressCity"
                                value={c.value}
                              >
                                {c.key}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="flex justify-between">
                        <button
                          type="button"
                          className="inline-flex items-center px-5 py-2 border border-gray-300 shadow-sm font-medium rounded bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-gray-200"
                          onClick={() =>
                            this.setState({ showAddressForm: false })
                          }
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="inline-flex items-center px-5 py-2 border border-gray-300  font-medium rounded-md shadow-sm text-white bg-boxit bg-boxit-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                          disabled={
                            !this.state.addressName ||
                            !this.state.addressLine ||
                            !this.state.addressArea
                          }
                          onClick={() => {
                            addAddress(
                              this.state.addressName,
                              this.state.addressLine,
                              this.state.addressArea,
                              this.state.addressCity,
                              () => {
                                swal.fire({
                                  title: 'Done!',
                                  text: 'Address has been added successfully',
                                  icon: 'success',
                                });
                                this.resetAddressForm();
                                this.setState({ showAddressForm: false });
                              }
                            );
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {!this.state.showAddressForm && (
                  <div className="text-center">
                    <Button
                      className="mx-auto my-10 px-5 py-2 border border-transparent rounded shadow-sm bg-boxit hover:bg-boxit-light focus:outline-none focus:ring focus:ring-offset-2 focus:ring-boxit-light focus:ring-opacity-50 active:bg-boxit text-white"
                      onClick={() => this.setState({ showAddressForm: true })}
                    >
                      {addresses.length ? 'Add New Address' : 'Add Address'}
                    </Button>
                  </div>
                )}

                <hr />

                {this.state.showChangePasswordForm && (
                  <div>
                    <div className="my-8 p-8 border border-gray-200 bg-gray-100 rounded">
                      <div className="mb-4">
                        <label htmlFor="password">Current Password</label>
                        <input
                          type="password"
                          className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={this.state.password || ''}
                          id="password"
                          placeholder="Current Password"
                          onChange={e => {
                            this.setState({
                              password: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="password">New Password</label>
                        <input
                          type="password"
                          className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={this.state.newPassword || ''}
                          id="newPassword"
                          placeholder="New Password"
                          onChange={e => {
                            this.setState({
                              newPassword: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="flex justify-between">
                        <button
                          className="px-5 py-2 rounded border border-gray-300 bg-white hover:bg-gray-200"
                          onClick={() =>
                            this.setState({ showChangePasswordForm: false })
                          }
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="inline-flex items-center px-5 py-2 border border-transparent font-medium rounded-md shadow-sm text-white bg-boxit hover:bg-boxit-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                          disabled={
                            !this.state.password || !this.state.newPassword
                          }
                          onClick={() => {
                            changePassword(
                              this.state.password,
                              this.state.newPassword
                            );
                          }}
                        >
                          Save
                        </button>
                      </div>

                      {changePasswordMessage && (
                        <div
                          className={`${changePasswordMessage.class} alert m-b-0 m-t-10`}
                          role="alert"
                        >
                          {changePasswordMessage.message}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="flex justify-between mt-4">
                  <button
                    className="py-2 px-5 border border-transparent rounded shadow-sm bg-boxit hover:bg-boxit-light focus:outline-none focus:ring focus:ring-offset-2 focus:ring-boxit-light focus:ring-opacity-50 active:bg-boxit text-white disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={this.state.showChangePasswordForm}
                    onClick={() =>
                      this.setState({ showChangePasswordForm: true })
                    }
                  >
                    Change Password
                  </button>
                  <div className="flex-1" />
                  <button
                    className="py-2 px-3 rounded bg-red-500 text-white hover:bg-red-400"
                    onClick={() => {
                      this.props.logout(() => {
                        this.props.routeTo('login');
                        window.location.reload();
                      });
                    }}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// ProfilePage.propTypes = {
//     updateUser: PropTypes.func.isRequired,
//     logout: PropTypes.func.isRequired,
//     routeTo: PropTypes.func.isRequired,
//     changePassword: PropTypes.func.isRequired,
//     config: PropTypes.object.isRequired,
//     user: PropTypes.object.isRequired,
//     cards: PropTypes.array.isRequired,
//     addresses: PropTypes.array.isRequired,
//     getCards: PropTypes.func.isRequired,
//     getAddresses: PropTypes.func.isRequired,
// };

const ProfilePageContainer = connect(
  state => ({
    user: (state as any).user.user,
    config: (state as any).config,
    cards: (state as any).cards,
    addresses: (state as any).addresses,
    allCities: (state as any).user.allCities,
    cities: (state as any).user.cities,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...userActions,
        ...appActions,
        ...cardActions,
        ...addressActions,
      },
      dispatch
    )
)(ProfilePage);

export default ProfilePageContainer;
