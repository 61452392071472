import React from 'react';
import PropTypes from 'prop-types';
type Props = {
  to: any;
  onClick: any;
};
class Link extends React.PureComponent<Props> {
  static propTypes = {
    onClick: PropTypes.func,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
  };
  public static defaultProps = {
    onClick: null,
    to: null,
    className: null,
  };

  render() {
    const { to, onClick, ...props } = this.props;

    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href={to} onClick={onClick} {...props} />;
  }
}

// Link.propTypes = {
//     onClick: PropTypes.func,
//     to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
//     className: PropTypes.string,
// };

// Link.defaultProps = {
//     onClick: () => {},
//     to: null,
//     className: null,
// };

export default Link;
