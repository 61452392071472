import {
  NEW_PAYMENT,
  NEW_PAYMENT_CREATE,
  NEW_PAYMENT_PROCESS,
  NEW_PAYMENT_COMPUTE_HASH,
  ACTION_RESET,
} from '../constants';
import invoiceActions from './invoices';
import errorHandleActions from '../actions/errorHandle';

const computeHash = (mode, params) => (dispatch, getState) => {
  dispatch({
    type: NEW_PAYMENT_COMPUTE_HASH,
    apiCall: {
      method: 'POST',
      path: `${mode}-hash`,
      params,
    },
  });
};

const resetPayment = () => ({
  type: NEW_PAYMENT,
  status: ACTION_RESET,
});

const createPayment = (params, cb) => dispatch => {
  dispatch(resetPayment());
  dispatch({
    type: NEW_PAYMENT_CREATE,
    apiCall: {
      method: 'POST',
      path: 'payments',
      params,
      callback: (err, payment) => {
        if (payment && payment.amount <= 0) {
          throw new Error('Total amount is zero. No payment required.');
        }

        if (typeof cb === 'function') {
          cb(err, payment);
        }
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
          throw new Error('Invalid Invoice or Invoice already paid.');
        }
      },
    },
  });
};

const createPaymentWithCard = (params, cb) => dispatch => {
  dispatch(resetPayment());
  dispatch({
    type: NEW_PAYMENT_CREATE,
    apiCall: {
      method: 'POST',
      path: 'tap-capture',
      params,
      callback: (err, payment) => {
        if (payment && payment.amount <= 0) {
          throw new Error('Total amount is zero. No payment required.');
        }

        if (typeof cb === 'function') {
          cb(err, payment);
        } else if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
          throw new Error('Invalid Invoice or Invoice already paid.');
        }
      },
    },
  });
};

const processPayment = (mode, params, cb) => (dispatch, getState) => {
  dispatch({
    type: NEW_PAYMENT_PROCESS,
    apiCall: {
      method: 'POST',
      path: `${mode}`,
      params,
      callback: (err, res) => {
        if (!err) {
          dispatch(invoiceActions.getInvoices());
        } else {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
        if (typeof cb === 'function') {
          cb(err, res);
        }
      },
    },
  });
};

export default {
  createPayment,
  createPaymentWithCard,
  computeHash,
  processPayment,
  resetPayment,
};
