import axios from 'axios';
import { ACTION_START, ACTION_SUCCESS, ACTION_FAILED } from './constants';
import { formatError } from '../format';
import settings from '../settings';
import { userActions, appActions } from './actions';

function createInstance(state) {
  const user = state ? state.user : {};
  const { accessToken } = user;

  const instance = axios.create({
    baseURL: settings.apiUrl,
    timeout: 20000,
    ...(typeof accessToken === 'string' && {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  });

  return instance;
}

const apiMiddleWare = store => next => action => {
  if (action.apiCall) {
    const state = store.getState();
    const instance = createInstance(state);

    const { method, path, params, queryParams, callback } = action.apiCall;
    // const user = state ? state.user : { };

    const parseResponse = response => {
      const dateFormat = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
      const reviver = (k, v) => {
        if (typeof v === 'string' && dateFormat.test(v)) {
          return new Date(v);
        }
        return v;
      };

      if (response && response.data && typeof response.data === 'object') {
        return JSON.parse(JSON.stringify(response.data), reviver);
      }

      return null;
    };

    const handleError = error => {
      const response = parseResponse(error.response);
      let errorMessage = response && response.message;
      errorMessage =
        formatError(errorMessage) ||
        errorMessage ||
        formatError('ERR_UNEXPECTED_ERROR');

      console.log(error); // eslint-disable-line no-console

      store.dispatch({
        type: action.type,
        status: ACTION_FAILED,
        ...(typeof params === 'object' && { params }),
        ...(typeof queryParams === 'object' && { queryParams }),
        error: errorMessage,
      });

      if (typeof callback === 'function') {
        callback(error);
      }

      if (error.response && error.response.status === 401) {
        localStorage.clear();
        const route = state.app.route;
        if (
          (route && route.routeName === 'login') ||
          window.location.pathname.indexOf('login') !== -1
        ) {
          return;
        }
        store.dispatch(
          userActions.logout(() => {
            store.dispatch(appActions.routeTo('login'));
            window.location.reload();
          })
        );
      }
    };

    const handleResponse = res => {
      const response = parseResponse(res);

      if (!response) {
        handleError(new Error(response.message || 'API Call Failed'));
        return;
      }

      store.dispatch({
        type: action.type,
        status: ACTION_SUCCESS,
        ...(typeof params === 'object' && { params }),
        ...(typeof queryParams === 'object' && { queryParams }),
        data: response,
      });

      if (typeof callback === 'function') {
        callback(null, response);
      }
    };

    store.dispatch({
      type: action.type,
      status: ACTION_START,
      params,
      queryParams,
    });

    if (method === 'GET') {
      instance
        .get(path, {
          ...(typeof queryParams === 'object' && { params: queryParams }),
        })
        .then(handleResponse)
        .catch(handleError);
    }
    if (method === 'POST') {
      instance.post(path, params).then(handleResponse).catch(handleError);
    }
    if (method === 'PATCH') {
      instance.patch(path, params).then(handleResponse).catch(handleError);
    }
    if (method === 'DELETE') {
      instance.delete(path, params).then(handleResponse).catch(handleError);
    }

    return null;
  }

  return next(action);
};

export default apiMiddleWare;
