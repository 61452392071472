import React from 'react';
import PropTypes from 'prop-types';
import s from './Button.module.scss';
import Loading from '../Loading';
type Props = {
  text: any;
  onClick: any;
  disabled: any;
  big: any;
  full?: any;
  filled: any;
  className: any;
  loading: any;
  style?: any;
};

class Button extends React.PureComponent<Props> {
  static propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    big: PropTypes.bool,
    loading: PropTypes.bool,
    text: PropTypes.string,
    disabled: PropTypes.bool,
    filled: PropTypes.bool,
  };
  public static defaultProps = {
    onClick: null,
    children: null,
    className: null,
    big: false,
    loading: false,
    text: '',
    disabled: false,
    filled: true,
  };
  render() {
    const {
      text,
      onClick,
      disabled,
      big,
      full,
      filled,
      className,
      children,
      loading,
      style,
      ...props
    } = this.props;
    let cls = `${s.button}`;
    if (filled) {
      cls = `${s.buttonFilled}`;
    }
    if (className) {
      cls = `${cls} ${className}`;
    }
    if (big) {
      cls = `${cls} ${s.big}`;
    }
    if (full) {
      cls = `${cls} ${s.full}`;
    }

    return (
      <button
        className={cls}
        style={style}
        disabled={disabled}
        onClick={e => {
          if (typeof onClick === 'function') {
            onClick(e);
          }
        }}
        {...props}
      >
        {loading && <Loading color={'#fff'} />}
        {!loading && children && children}
        {!loading && text && text}
      </button>
    );
  }
}

// Button.propTypes = {
//   onClick: PropTypes.func,
//   children: PropTypes.node,
//   className: PropTypes.string,
//   big: PropTypes.bool,
//   loading: PropTypes.bool,
//   text: PropTypes.string,
//   disabled: PropTypes.bool,
//   filled: PropTypes.bool,
// };

// Button.defaultProps = {
//     onClick: () => {},
//     children: null,
//     className: null,
//     big: false,
//     loading: false,
//     text: "",
//     disabled: false,
//     filled: true,
// };

export default Button;
