import { ERROR_HANDLE, ROUTE_TO } from '../constants';

const initialState = {
  route: {},
  errorData: {
    isError: null,
    errorText: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ROUTE_TO:
      return {
        ...state,
        route: {
          routeName: action.routeName,
          params: action.params,
        },
      };
    case ERROR_HANDLE:
      return {
        ...state,
        errorData: {
          isError: action.isError,
          errorText: action.errorText,
        },
      };
    default:
      return state;
  }
}
