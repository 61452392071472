import { bindActionCreators } from 'redux';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import LoginForm from '../components/LoginForm';
import Link from '../components/Link';
import { appActions, userActions } from '../data/actions';

type Props = {
  user: any;
  routeTo: any;
  login: any;
  facebookLogin: any;
  queryParams: any;
  push: any;
};

class LoginPage extends React.Component<Props> {
  static propTypes = {
    login: PropTypes.func.isRequired,
    facebookLogin: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    queryParams: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { user, routeTo, queryParams } = this.props;
    if (user.isLoggedIn) {
      const { next } = queryParams;
      if (next) {
        this.props.push(next);
      } else {
        routeTo('home');
      }
    }
  }

  render() {
    const { user, login, facebookLogin, routeTo, queryParams } = this.props;
    const loginStatus = user.loginStatus || {};
    const { next } = queryParams;

    return (
      <div className="grid grid-cols-1 mx-auto  md:w-1/2 ">
        <div className="">
          <div className="box center mt-10 lg:p-16  rounded shadow-md">
            <h2 className="my-8 text-center text-4xl font-semibold text-gray-600 lg:my-4">
              Login
            </h2>
            <div className="w-2/3 center">
              <LoginForm
                loginStatus={loginStatus}
                onSubmit={data => {
                  const { isFacebook, fbLoginParams, email, password } = data;
                  if (isFacebook) {
                    facebookLogin(fbLoginParams, () => {
                      if (next) {
                        this.props.push(next);
                      } else {
                        routeTo('home');
                      }
                    });
                  } else {
                    login(email, password, err => {
                      if (!err) {
                        if (next) {
                          this.props.push(next);
                        } else {
                          routeTo('home');
                        }
                      }
                    });
                  }
                }}
              />
            </div>
            <hr className="mt-8 mb-4" />
            <div className="text-center lg:text-left pb-3">
              <Link
                className="text-blue-600 "
                onClick={() => routeTo('password-reset')}
              >
                Reset Password
              </Link>{' '}
              <br />
              <Link
                className="text-blue-600"
                onClick={() => routeTo('magic-login')}
              >
                Login with Magic Link
              </Link>
              <br />
              <Link
                className="text-blue-600"
                onClick={() => routeTo('storage-calculator')}
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// LoginPage.propTypes = {
//     login: PropTypes.func.isRequired,
//     facebookLogin: PropTypes.func.isRequired,
//     routeTo: PropTypes.func.isRequired,
//     user: PropTypes.object.isRequired,
// };

const LoginPageContainer = connect(
  state => ({ user: (state as any).user }),
  dispatch =>
    bindActionCreators({ ...appActions, ...userActions, push }, dispatch)
)(LoginPage);

export default LoginPageContainer;
