import { STORAGES_GET, STORAGE_UPDATE } from '../constants';
import errorHandleActions from '../actions/errorHandle';

const getStorages = (params = null) => (dispatch, getState) => {
  const user = getState().user;
  const skip = params && params.skip ? params.skip : 0;
  const limit = params && params.limit ? params.limit : 100;
  dispatch({
    type: STORAGES_GET,
    apiCall: {
      method: 'GET',
      path: 'storages',
      queryParams: {
        userId: user.userId,
        $skip: skip,
        $limit: limit,
        isPart: false,
        status: params ? params.status : null,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

const updateStorage = (storageId, params) => dispatch => {
  dispatch({
    type: STORAGE_UPDATE,
    apiCall: {
      method: 'PATCH',
      path: `storages/${storageId}`,
      params: {
        storageId,
        name: params.name,
      },
      callback: (err, res) => {
        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }
      },
    },
  });
};

export default {
  getStorages,
  updateStorage,
};
