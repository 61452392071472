import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import 'eonasdan-bootstrap-datetimepicker';
import _ from 'lodash';
import moment from 'moment';
import cx from 'classnames';
import s from './Appointment.module.css';
import Loading from '../Loading';
import { formatTimeSlot } from '../../format';

type Props = {
  timeslots: any;
  getBusyslots: any;
  cityId: any;
  dateFormat: any;
  selectedDate: any;
  selectedTimeSlot: any;
  onDateChange: any;
  onTimeSlotChange: any;
  currentCityId: any;
  getIsSlotValid: any;
};

type State = {
  maxDate: any;
  minDate: any;
  currentDate: any;
  ready: any;
  busyslots: any;
  disabledDates: any;
  disabledDateError: any;
};

class Appointment extends React.Component<Props, State> {
  static propTypes = {
    getBusyslots: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
    onTimeSlotChange: PropTypes.func.isRequired,
    selectedDate: PropTypes.string,
    cityId: PropTypes.string.isRequired,
    selectedTimeSlot: PropTypes.object.isRequired,
    timeslots: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentCityId: PropTypes.string.isRequired,
    getIsSlotValid: PropTypes.func.isRequired,
  };

  public static defaultProps = {
    selectedDate: moment().add(1, 'day').format('YYYY-MM-DD'),
    dateFormat: 'YYYY-MM-DD',
  };

  appointmentDate: any;

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      minDate: moment().format('YYYY-MM-DD'),
      maxDate: moment().add(59, 'days').format('YYYY-MM-DD'),
      currentDate: moment().format('YYYY-MM-DD'),
      busyslots: [],
      disabledDates: [],
      disabledDateError: false,
    };

    this.getNextFreeSlot = this.getNextFreeSlot.bind(this);
    this.isSlotValid = this.isSlotValid.bind(this);
  }

  setDisabledSlots(slots: any[]) {
    let disabledDates = slots.map(slot => {
      const startingDate = moment(slot.from);
      const endDate = moment(slot.to);
      let currentDate = startingDate;
      const slotDisabledDates: any[] = [];
      while (currentDate.isSameOrBefore(endDate)) {
        slotDisabledDates.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'days');
      }
      return slotDisabledDates;
    });
    disabledDates = disabledDates.flat(1);
    disabledDates = disabledDates.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    if (disabledDates.includes(this.state.currentDate)) {
      this.setState({ disabledDateError: true });
    }

    this.setState({ disabledDates });

    // Set busy slots to state
    this.setState({ busyslots: slots, ready: true });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    const { getBusyslots, getIsSlotValid } = this.props;
    /*if (
      prevProps.currentCityId !== this.props.currentCityId &&
      this.props.currentCityId &&
      this.props.currentCityId !== 'Select City'
    ) {
      this.setState({ disabledDateError: false });
      getBusyslots(this.props.currentCityId, (err, slots) => {
        let busyslots = [];
        if (!err) {
          busyslots = slots.data;
        }

        this.setDisabledSlots(busyslots);
      });
    }*/
    if (prevState.currentDate !== this.state.currentDate) {
      if (this.state.disabledDates.includes(this.state.currentDate)) {
        this.setState({ disabledDateError: true });
        getIsSlotValid(false);
      } else {
        this.setState({ disabledDateError: false });
        getIsSlotValid(true);
      }
    }
  }

  componentDidMount() {
    const { getBusyslots } = this.props;
    getBusyslots(_, (err, slots) => {
      this.setState({ disabledDateError: false });
      let busyslots = [];
      if (!err) {
        busyslots = slots.data;
      }

      this.setDisabledSlots(busyslots);
    });
  }

  getNextFreeSlot() {
    const { busyslots } = this.state;
    const { timeslots } = this.props;

    let freeDate = moment();
    let freeSlot;

    for (let i = 1; i < 30; i += 1) {
      freeDate = moment().add(i, 'days');
      const formattedDate = freeDate.format(this.props.dateFormat);
      const busyslotsOfDate = busyslots[formattedDate];

      // all slots are free
      if (!busyslotsOfDate || !busyslotsOfDate.length) {
        freeSlot = timeslots[0];
        break;
      }

      if (busyslotsOfDate.length === timeslots.length) {
        // all slots are busy
      } else {
        // some slots are free
        const busyFroms = busyslotsOfDate.map(b => b.from);
        const freeSlotsOfDate = timeslots.filter(
          ts => busyFroms.indexOf(ts.from) === -1
        );
        if (freeSlotsOfDate && freeSlotsOfDate.length) {
          freeSlot = freeSlotsOfDate[0];
          break;
        }
      }
    }

    return {
      date: freeDate.format(this.props.dateFormat),
      timeSlot: freeSlot,
    };
  }

  isSlotValid(date, timeSlot) {
    const { busyslots, minDate, maxDate } = this.state;
    const momentDate = moment(date);
    if (
      !momentDate.isValid() ||
      momentDate.isBefore(minDate) ||
      momentDate.isAfter(maxDate)
    ) {
      return false;
    }
    const formattedDate = momentDate.format(this.props.dateFormat);
    const busyslotsOfDate = busyslots.filter(b => b.date === formattedDate);
    return !busyslotsOfDate.find(sl => sl.from === timeSlot.from);
  }

  render() {
    const {
      selectedDate,
      selectedTimeSlot,
      timeslots,
      onTimeSlotChange,
    } = this.props;
    return (
      <div className="box p-20">
        <h3 className="sub-title m-b-20">Preferred Date & Time</h3>
        {!this.state.ready && (
          <div className={s.loadingContainer}>
            <Loading />
          </div>
        )}
        {this.state.ready && (
          <div className="form-group m-b-0">
            <div className="grid grid-cols-12">
              <div className="col-span-12">
                <p>
                  Please select the required date, we will do our best to fulfil
                  the order within this date if it is available or we will
                  contact you for a new date. Thank you for your understanding
                </p>
                <br />
                <input
                  type="date"
                  value={this.state.currentDate}
                  min={this.state.minDate}
                  max={this.state.maxDate}
                  onChange={e => {
                    this.setState({
                      currentDate: e.target.value,
                    });
                    this.props.onDateChange(moment(e.target.value));
                  }}
                />
                {this.state.disabledDateError && (
                  <h4 style={{ color: 'red', fontWeight: 'bold' }}>
                    This date is busy, please choose another date
                  </h4>
                )}
              </div>
              <div className="col-span-12"></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

// Appointment.propTypes = {
//     getBusyslots: PropTypes.func.isRequired,
//     onDateChange: PropTypes.func.isRequired,
//     onTimeSlotChange: PropTypes.func.isRequired,
//     selectedDate: PropTypes.string,
//     cityId: PropTypes.string.isRequired,
//     selectedTimeSlot: PropTypes.object.isRequired,
//     timeslots: PropTypes.arrayOf(PropTypes.object).isRequired,
// };

// Appointment.defaultProps = {
//     selectedDate: moment().add(1, "day").format("YYYY-MM-DD"),
// };

export default Appointment;
