import React from 'react';
import PropTypes from 'prop-types';
import s from './ErrorPopup.module.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { errorHandleActions } from '../../data/actions';
import ErrorContainer from './errorContainer';

type Props = {
  error: any;
  setError: any;
};

class ErrorPopup extends React.PureComponent<any> {
  static propTypes = {
    error: PropTypes.object,
    setError: PropTypes.func.isRequired,
  };
  public static defaultProps = {
    text: 'Error',
  };

  render() {
    const { error, ...props } = this.props;

    return error && error.isError ? (
      <ErrorContainer setError={this.props.setError} error={error} />
    ) : null;
  }
}

const errorPopupContainer = connect(
  state => ({
    error: (state as any).app.errorData,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...errorHandleActions,
      },
      dispatch
    )
)(ErrorPopup);

export default errorPopupContainer;
