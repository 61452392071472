import $ from 'jquery';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cards from 'react-credit-cards';
import Payment from 'payment';
import { PaymentMode } from '../constants';
import { newHashActions } from '../data/actions';
import Button from '../components/Button';
import s from './CardPayfort.module.scss';

type Props = {
  computeHash: any;
  settings: any;
  newHash: any;
  merchantReference: any;
  user: any;
};
type State = {
  merchantReference: string;
  cardNumber: string;
  cardHolderName: string;
  expiryDate: string;
  focused: string;
  cvc: string;
  isValid: boolean;
  isSubmiting: boolean;
};
class CardPayfort extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: '',
      cardHolderName: '',
      expiryDate: '',
      cvc: '',
      focused: 'cardNumber',
      merchantReference: `${props.userId}-${new Date().getTime()}`,
      isValid: false,
      isSubmiting: false,
    };
  }
  static propTypes = {
    settings: PropTypes.object.isRequired,
    newHash: PropTypes.object.isRequired,
    userId: PropTypes.number.isRequired,
    computeHash: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { computeHash, settings, user } = this.props;
    const { merchantReference } = this.state;
    const payfortSettings = settings.payfort;
    const {
      merchantId,
      saudiMerchentId,
      accessCode,
      saudiAccessCode,
      createTokenReturnUrl,
    } = payfortSettings || {};

    Payment.formatCardNumber(document.querySelector('[name="cardNumber"]'), 16);
    Payment.formatCardExpiry(document.querySelector('[name="expiryDate"]'));
    Payment.formatCardCVC(document.querySelector('[name="cvc"]'));

    /* eslint-disable */
    computeHash(PaymentMode.payfort, {
      service_command: 'TOKENIZATION',
      access_code: user.countryId === 3 ? saudiAccessCode : accessCode,
      merchant_identifier: user.countryId === 3 ? saudiMerchentId : merchantId,
      merchant_reference: merchantReference,
      language: 'en',
      return_url: createTokenReturnUrl,
    });
    /* eslint-enable */
  }

  handleInputFocus = e => {
    const target = e.target;

    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = e => {
    const target = e.target;

    if (target.name === 'cardNumber') {
      this.setState({
        cardNumber: target.value.replace(/ /g, ''),
      });
    } else if (target.name === 'expiryDate') {
      const trimmedValue = target.value.trim();
      const value = trimmedValue.replace(/\s/g, '');
      const mm = value.substring(0, value.indexOf('/'));
      const yy = value.substring(value.indexOf('/') + 1);
      this.setState({
        expiryDate: yy + mm,
      });
    } else {
      this.setState({
        [target.name]: target.value,
      } as Pick<State, keyof State>);
    }
  };

  handleSave = e => {
    e.preventDefault();
    if (this.state.isValid) {
      this.setState({ isSubmiting: true });
      $('#payfortCardForm').submit();
    }
  };

  render() {
    const { settings, newHash, user } = this.props;
    const {
      merchantReference,
      isValid,
      isSubmiting,
      cardHolderName,
      cardNumber,
      expiryDate,
      cvc,
      focused,
    } = this.state;
    const payfortSettings = settings.payfort;
    const {
      merchantId,
      saudiMerchentId,
      accessCode,
      saudiAccessCode,
      createTokenReturnUrl,
      createTokenUrl,
    } = payfortSettings || {};
    const { hash } = newHash || {};

    console.log(user);

    return (
      <div>
        <h1 className="text-center text-5xl">Save Card</h1>
        <h4 className="mt-4 text-center text-xl">
          We will charge you only after the pick up of your items!
        </h4>

        <div className="box center max-w-4xl mt-16 py-12 md:py-16 rounded-lg shadow-md">
          <div className="grid grid-cols-12 mx-auto">
            <div className="col-span-12 md:col-span-6">
              <Cards
                number={cardNumber}
                name={cardHolderName}
                expiry={expiryDate.substring(2, 4) + expiryDate.substring(0, 2)}
                cvc={cvc}
                focused={focused}
                callback={(type, isValid) => this.setState({ isValid })}
              />
            </div>

            <div className="col-span-12 md:col-span-6 mt-20 md:mt-0 px-10">
              <form className={s.ccForm}>
                <div className="mb-4">
                  <input
                    className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="tel"
                    name="cardNumber"
                    placeholder="Card Number"
                    onKeyUp={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </div>
                <div className="mb-4">
                  <input
                    className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    name="cardHolderName"
                    placeholder="Cardholder Name"
                    onKeyUp={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </div>
                <div className="grid grid-cols-12 mx-auto">
                  <div className="mb-4 col-span-6 mr-4">
                    <input
                      className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="tel"
                      name="expiryDate"
                      placeholder="Valid Thru"
                      onKeyUp={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                  <div className="mb-4 col-span-6 ml-4">
                    <input
                      className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="tel"
                      name="cvc"
                      placeholder="CVC"
                      onKeyUp={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                </div>

                <div className="w-full mt-8">
                  <Button
                    full
                    className="w-full"
                    disabled={!isValid || isSubmiting}
                    onClick={this.handleSave}
                    text="Save Card"
                  />
                </div>
              </form>

              <form id="payfortCardForm" method="post" action={createTokenUrl}>
                <input
                  type="hidden"
                  name="service_command"
                  value="TOKENIZATION"
                />
                <input
                  type="hidden"
                  name="access_code"
                  value={
                    (user.countryId === 3 ? saudiAccessCode : accessCode) || ''
                  }
                />
                <input
                  type="hidden"
                  name="merchant_identifier"
                  value={user.countryId === 3 ? saudiMerchentId : merchantId}
                />
                <input
                  type="hidden"
                  name="merchant_reference"
                  value={merchantReference}
                />
                <input type="hidden" name="language" value="en" />
                <input type="hidden" name="expiry_date" value={expiryDate} />
                <input type="hidden" name="card_number" value={cardNumber} />
                <input type="hidden" name="card_security_code" value={cvc} />
                <input
                  type="hidden"
                  name="card_holder_name"
                  value={cardHolderName}
                />
                <input
                  type="hidden"
                  name="return_url"
                  value={createTokenReturnUrl || ''}
                />
                <input
                  type="hidden"
                  name="signature"
                  id="signature"
                  value={hash || ''}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// CardPayfort.propTypes = {payfort
//     settings: PropTypes.object.isRequired,
//     newHash: PropTypes.object.isRequired,
//     userId: PropTypes.number.isRequired,
//     computeHash: PropTypes.func.isRequired,
// };

const CardPayfortContainer = connect(
  state => ({
    newHash: (state as any).newHash,
    userId: (state as any).user.userId,
    user: (state as any).user.user,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...newHashActions,
      },
      dispatch
    )
)(CardPayfort);

export default CardPayfortContainer;
