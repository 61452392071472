import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import Clipboard from 'clipboard';
import StorageList from '../components/StorageList';
import Button from '../components/Button';
import s from './styles.module.css';
import {
  storageActions,
  userActions,
  requestActions,
  appActions,
  cardActions,
  storagePartsActions,
} from '../data/actions';
import { RequestType, StorageStatus } from '../constants';

type Props = {
  getCards: any;
  user: any;
  storages: any;
  parts: any;
  pagination: any;
  requests: any;
  routeTo: any;
  settings: any;
  cards: any;
  stats: any;
  getStats: any;
  getStorages: any;
  allStorages: any;
  deliveredNumber: any;
  getDeliveredStoragesNum: any;
};
class HomePage extends React.Component<Props> {
  static propTypes = {
    routeTo: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    cards: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    storages: PropTypes.arrayOf(PropTypes.object).isRequired,
    parts: PropTypes.arrayOf(PropTypes.object).isRequired,
    requests: PropTypes.arrayOf(PropTypes.object).isRequired,
    getCards: PropTypes.func.isRequired,
    pagination: PropTypes.object.isRequired,
    getStats: PropTypes.func.isRequired,
    stats: PropTypes.object.isRequired,
    getStorages: PropTypes.func.isRequired,
    allStorages: PropTypes.arrayOf(PropTypes.object).isRequired,
    deliveredNumber: PropTypes.number,
    getDeliveredStoragesNum: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      clipboardText: 'Click to Copy',
    };
  }

  componentDidMount() {
    const {
      getCards,
      getStats,
      getStorages,
      getDeliveredStoragesNum,
    } = this.props;
    getStorages({ status: ['stored', 'in'] });

    getCards();
    getStats(['stored', 'in']);
    getDeliveredStoragesNum();

    new Clipboard(`.${s.referralContainer} button`); /* eslint no-new: "off"*/
  }

  render() {
    const {
      user,
      storages,
      parts,
      requests,
      routeTo,
      settings,
      cards,
      pagination,
      stats,
      allStorages,
      deliveredNumber,
    } = this.props;
    const total = pagination.total || 0;
    const scount = status =>
      allStorages.filter(st => st.status === status).length;
    const storedCount =
      total > pagination.pageSize
        ? total
        : scount(StorageStatus.stored) + scount(StorageStatus.in);
    const deliveredCount = scount(StorageStatus.delivered);
    const storageList = storages || [];

    return (
      <div>
        {!cards.length && (
          <div>
            <div className="mx-auto">
              <div className="grid grid-cols-1 mt-10">
                <div className="spacer-30" />
                <Button
                  className="block center"
                  onClick={() => {
                    if (
                      user &&
                      (user.user.countryId === 1 || user.user.countryId === 3)
                    ) {
                      routeTo('card-payfort');
                    } else {
                      routeTo('card-tap');
                    }
                  }}
                  text="Add Card"
                  big
                />
              </div>
            </div>

            <hr />
          </div>
        )}
        <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">
          Items
        </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '.5rem',
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              border: '1px solid #e2e8f0',
              width: '20rem',
              height: '3rem',
            }}
          >
            <h4
              style={{
                padding: '.5rem',
                width: '70%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderRight: '1px solid #e2e8f0',
                fontWeight: 'bold',
              }}
            >
              Description
            </h4>
            <h4
              style={{
                padding: '.5rem',
                width: '30%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
              }}
            >
              Count
            </h4>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              border: '1px solid #e2e8f0',
              width: '20rem',
              height: '3rem',
            }}
          >
            <h4
              style={{
                padding: '.5rem',
                width: '70%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderRight: '1px solid #e2e8f0',
              }}
            >
              Total main items
            </h4>
            <h3
              style={{
                padding: '.5rem',
                width: '30%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {!!stats && stats.parentStoragesCount}
            </h3>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              border: '1px solid #e2e8f0',
              width: '20rem',
              height: '3rem',
            }}
          >
            <h4
              style={{
                padding: '.5rem',
                width: '70%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderRight: '1px solid #e2e8f0',
              }}
            >
              Total parts
            </h4>
            <h3
              style={{
                padding: '.5rem',
                width: '30%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {!!stats && stats.partsCount}
            </h3>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              border: '1px solid #e2e8f0',
              width: '20rem',
              height: '3rem',
            }}
          >
            <h4
              style={{
                padding: '.5rem',
                width: '70%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderRight: '1px solid #e2e8f0',
              }}
            >
              Total items including parts
            </h4>
            <h3
              style={{
                padding: '.5rem',
                width: '30%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {!!stats && stats.parentStoragesCount + stats.partsCount}
            </h3>
          </div>
        </div>
        <StorageList
          storages={storageList || []}
          parts={parts || []}
          type={RequestType.delivery}
          pagination={pagination}
          requests={requests || []}
          settings={settings || {}}
          onClick={storageId => {
            const storage = storages.find(st => st.id === storageId);
            routeTo('storage', {
              storageId: storage.id,
            });
          }}
        />

        <div
          className="mx-auto"
          style={{ marginLeft: '15px', marginRight: '15px' }}
        >
          {storedCount > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-1">
              <div className="w-full bg-white rounded grid grid-cols-12">
                <div className="flex items-center p-2 pl-3 col-span-8 text-gray-600">
                  <p>
                    {' '}
                    <span>{!!stats && stats.parentStoragesCount} </span> Items
                    stored with us
                  </p>
                </div>
                <div className="col-span-4 p-2">
                  <Button
                    className="right"
                    onClick={() =>
                      routeTo('request', {
                        type: 'delivery',
                      })
                    }
                    text="Bring Back"
                  />
                </div>
              </div>
            </div>
          )}
          {deliveredNumber > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-1">
              <div className="w-full bg-white rounded grid grid-cols-12">
                <p className={cx(s.infoText, 'col-span-8 text-gray-600')}>
                  {deliveredNumber} Items with you
                </p>
                <div className={cx(s.requestButtonContainer, 'col-span-4')}>
                  <Button
                    className="right"
                    onClick={() =>
                      routeTo('request', {
                        type: 'pickup',
                      })
                    }
                    text="Schedule Pickup"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

// HomePage.propTypes = {
//     routeTo: PropTypes.func.isRequired,
//     user: PropTypes.object.isRequired,
//     cards: PropTypes.array.isRequired,
//     settings: PropTypes.object.isRequired,
//     config: PropTypes.object.isRequired,
//     storages: PropTypes.arrayOf(PropTypes.object).isRequired,
//     requests: PropTypes.arrayOf(PropTypes.object).isRequired,
//     getCards: PropTypes.func.isRequired,
// };

const HomePageContainer = connect(
  state => ({
    user: (state as any).user,
    storages: (state as any).storages,
    parts: (state as any).storageParts.allStorages.filter(
      storage => storage.isPart
    ),
    pagination: (state as any).storages,
    requests: (state as any).requests,
    config: (state as any).config,
    cards: (state as any).cards,
    stats: (state as any).storageParts.stats,
    allStorages: (state as any).storageParts.allStorages,
    deliveredNumber: (state as any).storageParts.deliveredNumber,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...storageActions,
        ...userActions,
        ...appActions,
        ...requestActions,
        ...cardActions,
        ...storagePartsActions,
      },
      dispatch
    )
)(HomePage);

export default HomePageContainer;
