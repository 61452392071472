import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import s from './styles.module.css';
import { surveyActions, appActions } from '../data/actions';
import { ACTION_START, ACTION_SUCCESS, ACTION_FAILED } from '../data/constants';
import PhotoBox from '../components/photobox';
import classes from './photoButton.module.css';
import Button from '../components/Button';
import ImageLoader from 'react-imageloader';
import Loading from '../components/Loading';
import cx from 'classnames';

type Props = {
  storage: any;
  getSurveyPhotos: any;
  addPhotos: any;
  deletePhoto: any;
  config: any;
  photos: any;
  addPhotosStatus: any;
  makeDefaultPhoto: any;
  routeTo: any;
  urlParams: any;
};
type State = {
  isPhotoOpen: boolean;
  photoIndex: null | number;
  isSurvey: boolean;
  surveyId?: string;
  query: string;
  userId?: string;
  partnerId?: string;
  countryId?: string;
};

class Survey extends React.Component<Props, State> {
  static propTypes = {
    urlParams: PropTypes.object.isRequired,
    addPhotos: PropTypes.func.isRequired,
    deletePhoto: PropTypes.func.isRequired,
    getSurveyPhotos: PropTypes.func.isRequired,
    photos: PropTypes.arrayOf(PropTypes.object).isRequired,
    addPhotosStatus: PropTypes.string,
    config: PropTypes.object.isRequired,
    routeTo: PropTypes.func.isRequired,
  };
  fileUpload: any;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  constructor(props) {
    super(props);
    this.state = {
      isPhotoOpen: false,
      photoIndex: null,
      isSurvey: true,
      query: '',
    };
    this.iframeRef = React.createRef();
  }

  componentDidMount() {
    const params = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );
    const surveyId = params.surveyId;
    const userId = params.userId;
    const partnerId = params.partnerId;
    const countryId = params.countryId;
    let query = '';
    for (const [key, value] of Object.entries(params)) {
      query = query + `${key}=${value}&`;
    }
    query = query.slice(0, -1);
    this.setState({ query, surveyId, userId, partnerId, countryId });
    const { getSurveyPhotos } = this.props;
    getSurveyPhotos(surveyId);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    const { getSurveyPhotos } = this.props;
    if (prevState.isSurvey !== this.state.isSurvey) {
      getSurveyPhotos(this.state.surveyId);
    }
  }

  openPhoto(image, index) {
    this.setState({
      isPhotoOpen: true,
      photoIndex: index,
    });
  }

  checkIfIframeFinished = () => {
    try {
      if (this.iframeRef.current.contentWindow.location.href) {
        console.log(this.iframeRef.current.contentWindow.location.href);
        this.setState({ isSurvey: false });
      }
    } catch (e) {
      console.log('url not in domain');
    }
  };

  render() {
    const {
      addPhotos,
      deletePhoto,
      photos,
      addPhotosStatus,
      urlParams,
    } = this.props;

    const uploading = addPhotosStatus === ACTION_START;
    const uploadSuccess = addPhotosStatus === ACTION_SUCCESS;
    const uploadFailed = addPhotosStatus === ACTION_FAILED;

    const encodeImageFileAsURL = (file, cb) => {
      const reader = new FileReader();
      reader.onloadend = function onloadend() {
        cb(reader.result);
      };
      reader.readAsDataURL(file);
    };

    return this.state.isSurvey ? (
      <div className={s.survey}>
        <iframe
          ref={this.iframeRef}
          className={s.surveyFrame}
          width={'100%'}
          height={'100%'}
          src={
            process.env.NODE_ENV === 'production' ||
            process.env.REACT_APP_ENV === 'production'
              ? `https://survey.zohopublic.eu/zs/KDhh9w?${this.state.query}`
              : `https://survey.zohopublic.eu/zs/6qDHSA?${this.state.query}`
          }
          onLoad={this.checkIfIframeFinished}
          title="survey"
          sandbox="allow-scripts allow-forms allow-popups allow-pointer-lock allow-same-origin"
        />
      </div>
    ) : (
      <div>
        <div className="grid grid-cols-12 col-span-12">
          {(photos || []).map((p, index) => (
            <div
              style={{ cursor: 'pointer' }}
              key={p.thumbnail}
              className="col-span-6 md:col-span-4 m-b-10"
            >
              <div
                onClick={() => {
                  this.openPhoto(p.key, index);
                }}
                className={['relative', classes.photoButton].join(' ')}
                style={{ position: 'relative' }}
              >
                <button
                  className={cx(
                    'btn bg-red top-10 left-10 absolute',
                    s.deletePhoto
                  )}
                  style={{ color: '#fff' }}
                  onClick={e => {
                    e.stopPropagation();
                    if (
                      // eslint-disable-next-line no-restricted-globals
                      confirm('Are you sure you want to delete this photo?')
                    ) {
                      deletePhoto(this.state.surveyId, p.id);
                    }
                  }}
                >
                  <i className="fa fa-times p-5" />
                </button>
                {/* <a
                      className=""
                      rel="popover"
                      target="blank"
                      href={p.photo && p.photo.url && p.photo.url()}
                    > */}
                <ImageLoader
                  className={s.storagePhotoContainer}
                  src={p.thumbnail}
                  preloader={() => <Loading />}
                >
                  Image load failed!
                </ImageLoader>
                {/* </a> */}
              </div>
            </div>
          ))}
          {(!photos || photos.length === 0) && (
            <div
              className="col-span-12 text-center"
              style={{ textAlign: 'center' }}
            >
              <p className="text-center m-b-0">
                No photos found for this survey.
              </p>
            </div>
          )}
        </div>

        <div className="grid grid-cols-12 center col-span-12">
          <div className="col-span-12 center flex flex-col gap-5">
            <Button
              className={`${s.addPhoto} center`}
              onClick={() => {
                //this.fileUpload.click();
              }}
              //text="Add Photos"
            >
              <input
                className={s.addPhotoInput}
                type="file"
                multiple
                accept="image/*"
                ref={input => {
                  this.fileUpload = input;
                }}
                onChange={() => {
                  const files = [];
                  for (let i = 0; i < this.fileUpload.files.length; i += 1) {
                    const file = this.fileUpload.files[i];
                    encodeImageFileAsURL(file, res => {
                      files.push(res);
                    });
                  }
                  const i = setInterval(() => {
                    if (files.length === this.fileUpload.files.length) {
                      addPhotos(
                        this.state.surveyId,
                        this.state.userId,
                        this.state.countryId,
                        this.state.partnerId,
                        files
                      );
                      clearInterval(i);
                    }
                  }, 1000);
                }}
              />
            </Button>
            <Button
              onClick={() => {
                window.location.href = '/';
              }}
            >
              Confirm
            </Button>
          </div>
          <div className="col-span-12">
            {uploading && (
              <div className="alert alert-info" role="alert">
                Uploading Photos...
                <span
                  className={cx(
                    s.storagePhotoStatus,
                    'pretty circle smooth plain',
                    uploading && 'info',
                    uploadSuccess && 'success',
                    uploadFailed && 'danger'
                  )}
                >
                  <input
                    id={`select_${urlParams.surveyId}`}
                    type="checkbox"
                    readOnly
                    checked
                  />
                  <label htmlFor={`select_${urlParams.surveyId}`}>
                    {uploading && <i className="fa fa-spinner p-5" />}
                    {uploadSuccess && <i className="fa fa-check p-5" />}
                    {uploadFailed && <i className="fa fa-times p-5" />}
                  </label>
                </span>
              </div>
            )}
          </div>
        </div>
        {this.state.isPhotoOpen ? (
          <PhotoBox
            storageId={this.state.surveyId}
            cancel={() => this.setState({ isPhotoOpen: false })}
            photos={photos}
            size={photos.length}
            initialPhotoIndex={this.state.photoIndex}
            removeMakeDefault
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const photos = state.survey;
  return {
    urlParams: props.urlParams,
    photos,
  };
};

export default connect(mapStateToProps, dispatch =>
  bindActionCreators(
    {
      ...appActions,
      ...surveyActions,
    },
    dispatch
  )
)(Survey);
