import { ERROR_HANDLE } from '../constants';

const setError = (isError, errorText) => dispatch => {
  dispatch({
    type: ERROR_HANDLE,
    isError,
    errorText,
  });
};

export default {
  setError,
};
