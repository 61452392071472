import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loading from '../components/Loading';
import Button from '../components/Button';
import s from './status.module.scss';
import { newOrderActions, appActions } from '../data/actions';
import { ACTION_SUCCESS, ACTION_FAILED } from '../data/constants';

type Props = {
  newOrder: any;
  routeTo: any;
  resetOrder: any;
};
class OrderStatus extends React.Component<Props> {
  static propTypes = {
    newOrder: PropTypes.object.isRequired,
    routeTo: PropTypes.func.isRequired,
    resetOrder: PropTypes.func.isRequired,
  };
  componentDidMount() {
    const { newOrder, routeTo } = this.props;

    if (!newOrder.status) {
      routeTo('storage-calculator');
    }
  }

  render() {
    const { newOrder, resetOrder, routeTo } = this.props;

    return (
      <div>
        <div className="m-b-20">
          <div className="spacer-50" />
        </div>

        <div className="grid grid-cols-1 mx-auto md:w-1/2">
          <div className="m-b-20">
            <div className="box center p-24 rounded shadow-md">
              {newOrder.status === ACTION_SUCCESS && !newOrder && <Loading />}

              {newOrder.status === ACTION_SUCCESS && (
                <div style={{ textAlign: 'center' }}>
                  <h3 key="title1" className={`${s.orderQuestion} text-center`}>
                    Thank You!
                  </h3>
                  <i className="fa fa-5x fa-check-circle-o green p-10" />
                  <p className={s.statusText}>
                    We have received your request.
                    <br />
                    We will contact you shortly.
                  </p>
                </div>
              )}

              {newOrder.status === ACTION_FAILED && (
                <div style={{ textAlign: 'center' }}>
                  <i className="fa fa-5x fa-times red p-5" />
                  <h3 key="title1" className={`${s.orderQuestion} text-center`}>
                    Oops!
                  </h3>
                  <p className={s.statusText}>
                    An error occured while sending your request.
                    <br />
                    Please contact us for support.
                  </p>
                </div>
              )}

              <Button
                className="block center mt-16"
                onClick={() => {
                  resetOrder();
                  routeTo('home');
                }}
                text="Finish"
                big
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// OrderStatus.propTypes = {
//     newOrder: PropTypes.object.isRequired,
//     routeTo: PropTypes.func.isRequired,
//     resetOrder: PropTypes.func.isRequired,
// };

const OrderStatusContainer = connect(
  state => ({
    newOrder: (state as any).newOrder,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...newOrderActions,
        ...appActions,
      },
      dispatch
    )
)(OrderStatus);

export default OrderStatusContainer;
