import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../components/Button';
import s from './CardPayfort.module.scss';
import { newCardActions } from '../data/actions';
import { useHistory } from 'react-router-dom';
import cardImg from './images/card.jpg';

const CardTap = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const script = document.createElement('script');
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.REACT_APP_ENV === 'development'
    ) {
      console.log('dvelopment env');
      script.src = '/js/tappayment-development.js';
    } else if (
      (process.env.NODE_ENV as any) === 'staging' ||
      process.env.REACT_APP_ENV === 'staging'
    ) {
      console.log('staging env');
      script.src = '/js/tappayment-staging.js';
    } else if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'production'
    ) {
      console.log('production env');
      script.src = '/js/tappayment-production.js';
    }
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const errorRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const onClick = e => {
    setLoading(true);
    window['createTapToken'](e)
      .then(res => {
        const mode = 'tap';
        const params = {
          token: res.id ? res.id : null,
          command: 'VERIFY_CARD',
        };
        dispatch(
          newCardActions.createCard(mode, params, (err, res) =>
            handleResponse(err, res)
          )
        );
      })
      .catch(e => {
        setLoading(false);
      });
  };

  const handleResponse = (err, res) => {
    setLoading(false);
    if (err) {
      const current: any = errorRef.current;
      current.innerHTML = err.response.data.message;
    } else if (res) {
      window.location.replace(res.transaction_url);
    }
  };

  const user = useSelector(state =>
    state['user'] && state['user']['user'] ? state['user']['user'] : null
  );
  // console.log(user)
  // if(user.countryId !== 2){
  //     return null
  // }

  return (
    <div className={s.cardTapContainer}>
      <img src={cardImg} className={s.cardImg} />
      <div className="flex flex-col flex-1">
        <div id="element-container"></div>
        <div
          ref={errorRef}
          role="alert"
          id="error-handler"
          style={{
            color: 'red',
            marginLeft: '1rem',
            fontSize: '1.2rem',
          }}
        ></div>
        <Button onClick={onClick} className="tap-btn" disabled={loading}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default CardTap;
