import {
  PAYMENT_GET,
  ACTION_START,
  ACTION_SUCCESS,
  ACTION_FAILED,
} from '../constants';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PAYMENT_GET:
      if (action.status === ACTION_START) {
        const payments = Object.assign([...state], { isFetching: true });
        return payments;
      }
      if (action.status === ACTION_SUCCESS) {
        const payments = Object.assign([action.data || null], {
          isFetching: false,
        });
        return payments;
      }
      if (action.status === ACTION_FAILED) {
        const payments = Object.assign([...state], { isFetching: false });
        return payments;
      }
      return state;

    default:
      return state;
  }
}
