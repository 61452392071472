import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import {
  newOrderActions,
  appActions,
  userActions,
  configActions,
} from '../data/actions';
import { ACTION_START, ACTION_WAITING } from '../data/constants';
import OrderContinue from '../order/OrderContinue';
import OrderHeader from './OrderHeader';
import { isValidPhoneNumber } from '../core/utils/phone';
import s from './QuoteForm.module.scss';

type Props = {
  newOrder: any;
  routeTo: any;
  user: any;
  confirmOrder: any;
  setOrderName: any;
  setOrderEmail: any;
  setOrderPhone: any;
  config: any;
};
type State = {
  isPhoneValid: boolean;
};
class QuoteForm extends React.Component<Props, State> {
  static propTypes = {
    config: PropTypes.object,
    newOrder: PropTypes.object,
    user: PropTypes.object,
    setOrderName: PropTypes.func.isRequired,
    setOrderEmail: PropTypes.func.isRequired,
    setOrderPhone: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    confirmOrder: PropTypes.func.isRequired,
  };
  public static defaultProps = {
    config: {},
    newOrder: {},
    user: {},
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      isPhoneValid: true,
    };
  }

  componentDidMount() {
    const { newOrder, routeTo, user, confirmOrder } = this.props;
    const { isLoggedIn } = user;

    if (newOrder.status !== ACTION_START) {
      routeTo('storage-calculator');
    }

    if (isLoggedIn) {
      confirmOrder(() => {
        routeTo('quote-status');
      });
    }
  }

  render() {
    const {
      newOrder,
      routeTo,
      setOrderName,
      setOrderEmail,
      setOrderPhone,
      config,
      user,
      confirmOrder,
    } = this.props;
    const { isLoggedIn } = user;

    const isDisabled =
      !newOrder.name ||
      !newOrder.phone ||
      !newOrder.email ||
      !this.state.isPhoneValid;

    const isLoading = (newOrder || {}).status === ACTION_WAITING;

    return (
      <div>
        <OrderHeader
          newOrder={newOrder}
          currency={config.currency}
          isHeaderContinueDisabled={isDisabled}
          onContinueClick={() => {
            confirmOrder(() => {
              routeTo('quote-status');
            });
          }}
        />
        <div className="container mx-auto">
          <div className="grid grid-cols-1 mx-auto md:w-1/2">
            <div className="">
              <div className="box center p-24 rounded shadow-md">
                <h3 key="title1" className={`${s.orderQuestion} text-center`}>
                  Get your free quote!
                </h3>
                {!isLoggedIn && (
                  <div>
                    <div className="mb-4">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="name"
                        value={newOrder.name || ''}
                        onChange={e => setOrderName(e.target.value)}
                      />
                    </div>
                    <div className="mb-5">
                      <label htmlFor="email">Email Address</label>
                      <input
                        type="email"
                        className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        value={newOrder.email || ''}
                        onChange={e => setOrderEmail(e.target.value)}
                      />
                    </div>
                    <div className="mb-16">
                      <label htmlFor="phone">Phone</label>
                      <PhoneInput
                        inputProps={{
                          name: 'phone',
                          required: true,
                          autoFocus: false,
                          style: { width: '100%' },
                        }}
                        country="ae"
                        preferredCountries={['ae', 'kw', 'sa']}
                        countryCodeEditable={false}
                        value={newOrder.phone || ''}
                        isValid={this.state.isPhoneValid}
                        onChange={(phone, country: any) => {
                          const isValid = isValidPhoneNumber(
                            phone,
                            country.countryCode
                          );
                          this.setState(
                            {
                              isPhoneValid: isValid,
                            },
                            () => setOrderPhone(phone)
                          );
                        }}
                      />
                    </div>

                    <OrderContinue
                      continueText="Submit"
                      onContinueClick={() => {
                        confirmOrder(() => {
                          routeTo('quote-status');
                        });
                      }}
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// QuoteForm.propTypes = {
//     config: PropTypes.object,
//     newOrder: PropTypes.object,
//     user: PropTypes.object,
//     setOrderName: PropTypes.func.isRequired,
//     setOrderEmail: PropTypes.func.isRequired,
//     setOrderPhone: PropTypes.func.isRequired,
//     routeTo: PropTypes.func.isRequired,
//     confirmOrder: PropTypes.func.isRequired,
// };

// QuoteForm.defaultProps = {
//     config: {},
//     newOrder: {},
//     user: {},
// };

const QuoteFormContainer = connect(
  state => ({
    config: (state as any).config,
    user: (state as any).user,
    newOrder: (state as any).newOrder,
    addresses: (state as any).addresses,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...newOrderActions,
        ...appActions,
        ...userActions,
        ...configActions,
      },
      dispatch
    )
)(QuoteForm);

export default QuoteFormContainer;
