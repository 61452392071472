import moment from 'moment';

const strings = {
  BB: 'Boxit Box',
  LG: 'Luggage',
  fordelivery: 'For Delivery',
  forpickup: 'For Pickup',
  delivered: 'Delivered',
  stored: 'Stored',
  in: 'Stored',
  paid: 'Paid',
  unpaid: 'Unpaid',
  partial: 'Partial',
  cancelled: 'Cancelled',
};

const errorMessages = {
  ERR_USER_EXISTS: 'There is an existing account with this email. Please login',
  ERR_USER_NOT_FOUND: 'No user found with this email address.',
  ERR_INCORRECT_PASSWORD: 'Incorrect password.',
  ERR_PASSWORD_RESET: 'Sorry, there was a problem resetting your password.',
  ERR_PASSWORD_RESET_REQUIRED: 'Password Reset Required',
  ERR_TOKEN_EXPIRED: 'Sorry, this link has expired.',
  ERR_INVALID_DATA: 'Invalid Data',
  ERR_INVALID_OR_EXPIRED_COUPON: 'Invalid or Expired Coupon',
  ERR_UNEXPECTED_ERROR: 'Unexpected Error occured',
  ERR_LOGIN_WITH_FACEBOOK:
    'Please login with Facebook, or use the Reset Password option to set a password.',
  ERR_FACEBOOK_AUTH: 'Error authenticating with Facebook.',
  ERR_FACEBOOK_LOGIN_FAILED: 'Facebook Login Failed.',
};

export function formatError(key) {
  return errorMessages[key];
}

export function format(key) {
  return strings[key];
}

export function formatDate(date, year = false) {
  return moment(date).format(`MMMM DD${year ? ', YYYY' : ''}`);
}

export function formatDateTime(date) {
  return moment(date).format('lll');
}

export function formatTimeSlot(timeSlot) {
  const fromText =
    timeSlot.from > 12 ? `${timeSlot.from % 12} PM` : `${timeSlot.from} AM`;
  const toText =
    timeSlot.to > 12 ? `${timeSlot.to % 12} PM` : `${timeSlot.to} AM`;
  return `${fromText} - ${toText}`;
}
