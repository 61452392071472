import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cx from 'classnames';
import OrderSteps from '../OrderSteps';
import OrderCart from '../OrderCart';
import $ from 'jquery';
import s from './styles.module.scss';
import imgLogo from './images/logo.png';
import Link from '../../components/Link';
import { newOrderActions, appActions } from '../../data/actions';
import { formatMoney } from '../../core/utils/money';
type Props = {
  newOrder: any;
  currency: any;
  current: any;
  onContinueClick: any;
  routeTo: any;
  setItemQuantity: any;
  isHeaderContinueDisabled: any;
};
type State = {
  showCart: any;
};

// const CartIcon = ({ newOrder, currency, totalItems, onClick }) => (
//   <div className={s.summaryItem} onClick={onClick}>
//     <i className="fa fa-2x fa-shopping-bag" aria-hidden="true" />

//     {totalItems > 0 && <div className={s.summaryBadge}>{totalItems}</div>}

//     {newOrder.monthlyRent > 0 && (
//       <div className={s.summarySubtitle}>
//         {currency}&nbsp;
//         {formatMoney(newOrder.monthlyRent, currency, true)}
//       </div>
//     )}
//   </div>
// );

class OrderHeader extends React.PureComponent<Props, State> {
  static propTypes = {
    newOrder: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    current: PropTypes.string.isRequired,
    routeTo: PropTypes.func.isRequired,
    onContinueClick: PropTypes.func,
    storagetypes: PropTypes.array.isRequired,
    setItemQuantity: PropTypes.func.isRequired,
    isHeaderContinueDisabled: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      showCart: false,
    };

    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.onDocumentClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onDocumentClick);
  }

  onDocumentClick(e) {
    // const $ = window.$;
    const container = $('.cartContainer');
    console.log('clicked---', container);
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      this.setState({ showCart: false });
    }
  }

  render() {
    const {
      newOrder,
      currency,
      current,
      onContinueClick,
      routeTo,
      setItemQuantity,
      isHeaderContinueDisabled,
    } = this.props;
    const { showCart } = this.state;
    const totalItems = (newOrder.items || [])
      .map(i => i.quantity)
      .reduce((a, b) => a + b, 0);

    return (
      <div className="m-b-20">
        <div className="spacer-50" />

        <div className={s.summaryContainer}>
          <div className="container flex">
            <Link onClick={() => routeTo('home')}>
              <img className={s.logo} src={imgLogo} alt="Boxit Storage" />
            </Link>

            <OrderSteps current={current} routeTo={routeTo} />

            <div className="visible  sm:invisible ">
              {/* <CartIcon
                newOrder={newOrder}
                currency={currency}
                totalItems={totalItems}
                onClick={e => e.stopPropagation()}
              /> */}
              <div className={s.summaryItem} onClick={e => e.stopPropagation()}>
                <i className="fa fa-2x fa-shopping-bag" aria-hidden="true" />
                {totalItems > 0 && (
                  <div className={s.summaryBadge}>{totalItems}</div>
                )}

                {newOrder.monthlyRent > 0 && (
                  <div className={s.summarySubtitle}>
                    {currency}&nbsp;
                    {formatMoney(newOrder.monthlyRent, currency, true)}
                  </div>
                )}
              </div>
            </div>

            <div className=" visible ">
              {/* <CartIcon
                newOrder={newOrder}
                currency={currency}
                totalItems={totalItems}
                onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    showCart: !this.state.showCart,
                  });
                }}
              /> */}
              <div
                className={s.summaryItem}
                onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    showCart: !this.state.showCart,
                  });
                }}
              >
                <i className="fa fa-2x fa-shopping-bag" aria-hidden="true" />

                {totalItems > 0 && (
                  <div className={s.summaryBadge}>{totalItems}</div>
                )}

                {newOrder.monthlyRent > 0 && (
                  <div className={s.summarySubtitle}>
                    {currency}&nbsp;
                    {formatMoney(newOrder.monthlyRent, currency, true)}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={cx(s.cartWrapper, 'container', {
              [s.open]: showCart && totalItems !== 0,
            })}
          >
            <OrderCart
              onContinueClick={onContinueClick}
              setItemQuantity={setItemQuantity}
              isHeaderContinueDisabled={isHeaderContinueDisabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

// OrderHeader.propTypes = {
//   newOrder: PropTypes.object.isRequired,
//   currency: PropTypes.string.isRequired,
//   current: PropTypes.string.isRequired,
//   routeTo: PropTypes.func.isRequired,
//   onContinueClick: PropTypes.func,
//   storagetypes: PropTypes.array.isRequired,
//   setItemQuantity: PropTypes.func.isRequired,
//   isHeaderContinueDisabled: PropTypes.bool.isRequired,
// };

const OrderHeaderContainer = connect(
  state => ({
    newOrder: (state as any).newOrder,
    config: (state as any).config,
  }),
  dispatch =>
    bindActionCreators({ ...newOrderActions, ...appActions }, dispatch)
)(OrderHeader);

export default OrderHeaderContainer;
