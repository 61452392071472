import moment from 'moment';
import {
  NEW_REQUEST,
  NEW_REQUEST_SET_STORAGEIDS,
  NEW_REQUEST_SET_DATE,
  NEW_REQUEST_SET_ADDRESS,
  NEW_REQUEST_SET_TIMESLOT,
  NEW_REQUEST_SET_TYPE,
  NEW_REQUEST_SUBMIT,
  ACTION_RESET,
} from '../constants';

const initialState = {
  storageIds: [],
  date: moment().add(1, 'day').format('YYYY-MM-DD'),
  timeSlot: {
    from: 9,
    to: 11,
  },
  address: {},
  status: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case NEW_REQUEST_SET_TYPE:
      return {
        ...state,
        type: action.requestType,
      };

    case NEW_REQUEST_SET_STORAGEIDS:
      return {
        ...state,
        storageIds: action.storageIds,
      };

    case NEW_REQUEST_SET_DATE:
      return {
        ...state,
        date: moment(action.date).format('YYYY-MM-DD'),
      };

    case NEW_REQUEST_SET_TIMESLOT:
      return {
        ...state,
        timeSlot: action.timeSlot,
      };

    case NEW_REQUEST_SET_ADDRESS:
      return {
        ...state,
        address: action.address,
      };

    case NEW_REQUEST_SUBMIT:
      return {
        ...state,
        status: action.status,
      };

    case NEW_REQUEST:
      if (action.status === ACTION_RESET) {
        return initialState;
      }
      return state;

    default:
      return state;
  }
}
