import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loading from '../components/Loading';
import Button from '../components/Button';
import { formatMoney } from '../core/utils/money';
import s from './styles.module.scss';
import { newOrderActions, appActions, cardActions } from '../data/actions';
import { ACTION_SUCCESS, ACTION_FAILED } from '../data/constants';

type Props = {
  newOrder: any;
  getCards: any;
  routeTo: any;
  user: any;
  cards: any;
  config: any;
  resetOrder: any;
};
class OrderStatus extends React.Component<Props> {
  static propTypes = {
    newOrder: PropTypes.object.isRequired,
    cards: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    getCards: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    resetOrder: PropTypes.func.isRequired,
  };
  componentDidMount() {
    const { newOrder, getCards, routeTo } = this.props;
    const { totalRent } = newOrder;

    getCards();

    if (!newOrder.status) {
      routeTo('order-storage');
    } else if (totalRent) {
      setTimeout(() => this.routeToPayment(), 3000);
    }
  }

  routeToPayment() {
    const { user, newOrder, cards, routeTo } = this.props;
    const { totalRent, paymentMode } = newOrder;

    if (cards && cards.length > 0) {
      return;
    }

    if (paymentMode === 'payfort') {
      routeTo(`card-${paymentMode}`);
      // routeTo(`payment-${paymentMode}`, { userId: user.userId, amount: totalRent });
    } else if (paymentMode === 'tap') {
      routeTo(`payment-${paymentMode}`, {
        userId: user.userId,
        amount: totalRent,
      });
    }
  }

  render() {
    const { newOrder, config, cards, resetOrder, routeTo } = this.props;
    const dueNow = Math.max(newOrder.totalRent ? newOrder.totalRent : 0, 0);
    const hasCardOnFile = cards && cards.length > 0;
    const showFinishButton =
      newOrder.status === 'FAILED' ||
      newOrder.paymentMode === 'cod' ||
      (newOrder.paymentMode === 'payfort' && hasCardOnFile);

    return (
      <div>
        <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">Order Status</h2>
        <div className="grid grid-cols-1 mx-auto md:w-1/2">
          <div className="m-b-20">
            <div className="box center text-center">
              {newOrder.status === ACTION_SUCCESS && !newOrder && <Loading />}
              {newOrder.status === ACTION_SUCCESS && (
                <div>
                  <i className="fa fa-5x fa-check-circle-o green p-10" />
                  <h3 className="sub-title m-t-0">Order Success</h3>
                  <p className={s.statusText}>
                    We have received your order.
                    <br />
                    We will get in touch with you shortly.
                  </p>
                  <hr />
                  {dueNow && newOrder.paymentMode === 'tap' && (
                    <div className={s.statusText}>
                      <p>
                        You are being redirected to our payment page where you
                        can pay {formatMoney(dueNow, config.currency, true)}{' '}
                        {config.currency}...
                      </p>
                      <Loading />
                    </div>
                  )}
                  {dueNow && newOrder.paymentMode === 'disabled' && (
                    <div className={s.statusText}>
                      <p>
                        You are being redirected to our payment page where you
                        can pay {formatMoney(dueNow, config.currency, true)}{' '}
                        {config.currency}...
                      </p>
                      <Loading />
                    </div>
                  )}
                  {dueNow &&
                    newOrder.paymentMode === 'payfort' &&
                    !hasCardOnFile && (
                      <div className={s.statusText}>
                        <p>
                          You are being redirected to your account page where
                          you can add your credit or debit card...
                        </p>
                        <Loading />
                      </div>
                    )}
                </div>
              )}
              {newOrder.status === ACTION_FAILED && (
                <div>
                  <i className="fa fa-5x fa-times red p-5" />
                  <h3 className="sub-title m-t-0">Order Failed</h3>
                  <p className={s.statusText}>
                    Unexpected error occured while placing your order.
                    <br />
                    Please try again later.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {showFinishButton && (
          <Button
            className="block center"
            onClick={() => {
              resetOrder();
              routeTo('home');
            }}
            text="Finish"
            big
          />
        )}
      </div>
    );
  }
}

// OrderStatus.propTypes = {
//     newOrder: PropTypes.object.isRequired,
//     cards: PropTypes.arrayOf(PropTypes.object).isRequired,
//     user: PropTypes.object.isRequired,
//     config: PropTypes.object.isRequired,
//     getCards: PropTypes.func.isRequired,
//     routeTo: PropTypes.func.isRequired,
//     resetOrder: PropTypes.func.isRequired,
// };

const OrderStatusContainer = connect(
  state => ({
    newOrder: (state as any).newOrder,
    cards: (state as any).cards,
    user: (state as any).user,
    config: (state as any).config,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...newOrderActions,
        ...cardActions,
        ...appActions,
      },
      dispatch
    )
)(OrderStatus);

export default OrderStatusContainer;
