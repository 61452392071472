import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import Button from '../Button';
import Loading from '../Loading';
import { ACTION_FAILED, ACTION_START } from '../../data/constants';
type Props = {
  loginStatus: any;
  onSubmit: any;
};
type State = {
  email: any;
  password: any;
};

function getErrorMessage(error) {
  switch (error) {
    case "'user' record in the database is missing a 'password'":
      return "You have not set a password yet. Use 'Reset Password' to set one.";
    default:
      return error;
  }
}

class LoginForm extends React.Component<Props, State> {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loginStatus: PropTypes.object.isRequired,
    fbLoginText: PropTypes.string,
  };
  public static defaultProps = {
    fbLoginText: 'Login with Facebook',
  };
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };

    this.isValid = this.isValid.bind(this);
  }

  isValid() {
    const { email, password } = this.state;
    return validator.isEmail(email) && password;
  }

  render() {
    const { email, password } = this.state;
    const { loginStatus, onSubmit } = this.props;
    let error;
    if (loginStatus && loginStatus.status === ACTION_FAILED) {
      error = loginStatus.error;
    }

    return (
      <div>
        {/*
                <FacebookLogin
                    appId={settings.facebook.appId}
                    onLogin={(params) => {
                        if (params.accessToken) {
                            onSubmit({
                                isFacebook: true,
                                fbLoginParams: params,
                            });
                        }
                    }}
                    buttonText={fbLoginText}
                />
                <hr />
                */}
        <div className="mb-4">
          <label htmlFor="email">Email Address</label>
          <input
            type="text"
            className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            value={email}
            placeholder="Email Address"
            onChange={e => {
              this.setState({ email: e.target.value });
            }}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={password}
            id="password"
            placeholder="Password"
            onChange={e => {
              this.setState({ password: e.target.value });
            }}
          />
        </div>
        <button
          className="block w-full mx-auto my-8 p-2 rounded bg-green-500 hover:bg-green-600 text-white text-lg"
          disabled={loginStatus.status === ACTION_START}
          onClick={() => onSubmit(this.state)}
        >
          <span>
            {loginStatus.status === ACTION_START ? (
              <Loading color={'#fff'} />
            ) : (
              'Login'
            )}
          </span>
        </button>

        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4  m-t-10"
            role="alert"
          >
            {getErrorMessage(error)}
          </div>
        )}
      </div>
    );
  }
}

// LoginForm.propTypes = {
//     onSubmit: PropTypes.func.isRequired,
//     loginStatus: PropTypes.object.isRequired,
//     fbLoginText: PropTypes.string,
// };

// LoginForm.defaultProps = {
//     fbLoginText: "Login with Facebook",
// };

export default LoginForm;
