import {
  COMPUTE_HASH,
  ACTION_START,
  ACTION_SUCCESS,
  ACTION_RESET,
} from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMPUTE_HASH:
      if (action.status === ACTION_RESET) {
        return initialState;
      }

      if (action.status === ACTION_START) {
        return {
          ...initialState,
          status: ACTION_START,
        };
      }

      if (action.status === ACTION_SUCCESS) {
        return {
          ...state,
          status: action.status,
          hash: action.data ? action.data.hash : null,
        };
      }

      return {
        ...state,
        status: action.status,
      };

    default:
      return state;
  }
}
