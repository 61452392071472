import { NEW_CARD, NEW_CARD_CREATE, ACTION_RESET } from '../constants';
import errorHandleActions from '../actions/errorHandle';

// const computeHash = (mode, params) => (dispatch, getState) => {
//   dispatch({
//     type: NEW_CARD_COMPUTE_HASH,
//     apiCall: {
//       method: 'POST',
//       path: `${mode}-hash`,
//       params,
//     },
//   });
// };

const resetCard = () => ({
  type: NEW_CARD,
  status: ACTION_RESET,
});

const createCard = (mode, params, cb) => (dispatch, getState) => {
  dispatch(resetCard());
  dispatch({
    type: NEW_CARD_CREATE,
    apiCall: {
      method: 'POST',
      path: `${mode}-cards`,
      params,
      callback: (err, res) => {
        if (typeof cb === 'function') {
          cb(err, res);
        } else if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
          throw new Error('Error in NEW_CARD_CREATE');
        }
      },
    },
  });
};

export default {
  createCard,
  //computeHash,
  resetCard,
};
