import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '../components/Button';
import s from './styles.module.css';
import { newRequestActions, appActions } from '../data/actions';
import { ACTION_SUCCESS, ACTION_FAILED } from '../data/constants';
type Props = {
  newRequest: any;
  resetRequest: any;
  routeTo: any;
};
// eslint-disable-next-line react/prefer-stateless-function
class RequestStatus extends React.Component<Props> {
  static propTypes = {
    newRequest: PropTypes.object.isRequired,
    resetRequest: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
  };
  render() {
    const { newRequest, resetRequest, routeTo } = this.props;

    return (
      <div>
        <div className="box p-40 mt-40 mx-auto max-w-4xl">
          <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">Request Status</h2>
          <div className="m-20">
            <div className="box center text-center">
              {newRequest.status === ACTION_SUCCESS && (
                <div>
                  <i className="fa fa-5x fa-check-circle-o green p-5" />
                  <h3 className="sub-title m-0">Success</h3>
                  <p className="mt-20 text-3xl">
                    We have received your request.
                    <br />
                    We will process it accordingly.
                  </p>
                </div>
              )}
              {newRequest.status === ACTION_FAILED && (
                <div>
                  <i className="fa fa-5x fa-times red p-5" />
                  <h3 className="sub-title m-0">Request Failed</h3>
                  <p className="mt-20 text-3xl">
                    Unexpected error occured while placing your request.
                    <br />
                    Please try again later.
                  </p>
                </div>
              )}
            </div>
          </div>
          <Button
            className="block center"
            onClick={() => {
              resetRequest();
              routeTo('home');
            }}
            text="Finish"
            big
          />
        </div>
      </div>
    );
  }
}

// RequestStatus.propTypes = {
//     newRequest: PropTypes.object.isRequired,
//     resetRequest: PropTypes.func.isRequired,
//     routeTo: PropTypes.func.isRequired,
// };

const RequestStatusContainer = connect(
  state => ({ newRequest: (state as any).newRequest }),
  dispatch =>
    bindActionCreators({ ...newRequestActions, ...appActions }, dispatch)
)(RequestStatus);

export default RequestStatusContainer;
