import {
  NEW_REQUEST_SET_STORAGEIDS,
  NEW_REQUEST_SET_DATE,
  NEW_REQUEST_SET_TIMESLOT,
  NEW_REQUEST_SET_ADDRESS,
  NEW_REQUEST_SET_TYPE,
  NEW_REQUEST_SUBMIT,
  ACTION_RESET,
  NEW_REQUEST,
} from '../constants';
import storageActions from './storages';
import invoiceActions from './invoices';
import requestActions from './requests';
import storaagePartsActions from './storageParts';
import errorHandleActions from '../actions/errorHandle';

const setStorageIds = storageIds => ({
  type: NEW_REQUEST_SET_STORAGEIDS,
  storageIds,
});

const setRequestType = requestType => ({
  type: NEW_REQUEST_SET_TYPE,
  requestType,
});

const setRequestDate = date => ({
  type: NEW_REQUEST_SET_DATE,
  date,
});

const setRequestTimeSlot = timeSlot => ({
  type: NEW_REQUEST_SET_TIMESLOT,
  timeSlot,
});

const setRequestAddress = address => ({
  type: NEW_REQUEST_SET_ADDRESS,
  address,
});

const confirmRequest = cb => (dispatch, getState) => {
  const { newRequest, user, storages, storageParts } = getState();
  const { type, storageIds, date, timeSlot, address } = newRequest;
  const storage = storageParts.allStorages.find(i => i.id === storageIds[0]);
  const orderId = storage && storage.orderId;

  dispatch({
    type: NEW_REQUEST_SUBMIT,
    apiCall: {
      method: 'POST',
      path: 'requests',
      params: {
        userId: user.userId,
        type,
        orderId,
        request_lines: storageIds.map(i => ({ storageId: i, qty: 1 })),
        date,
        from: timeSlot.from,
        to: timeSlot.to,
        addressId: address.id,
        line1: address.line1,
        area: address.area,
        cityId: address.cityId,
      },
      callback: (err, res) => {
        if (typeof cb === 'function') {
          cb(err, res);
        }

        if (err) {
          dispatch(
            errorHandleActions.setError(
              true,
              err.response.data.message || 'unknown error'
            )
          );
        }

        dispatch(storageActions.getStorages({}));
        dispatch(storaagePartsActions.getAllStorages({}));
        dispatch(invoiceActions.getInvoices());
        dispatch(requestActions.getRequests());
      },
    },
  });
};

const resetRequest = () => ({
  type: NEW_REQUEST,
  status: ACTION_RESET,
});

export default {
  setStorageIds,
  setRequestAddress,
  setRequestType,
  setRequestDate,
  setRequestTimeSlot,
  confirmRequest,
  resetRequest,
};
