import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AddressForm from '../components/AddressForm';
import SignupForm from '../components/SignupForm';
import Appointment from '../components/Appointment';
import {
  newOrderActions,
  appActions,
  userActions,
  configActions,
  addressActions,
} from '../data/actions';
import { ACTION_START } from '../data/constants';
import OrderContinue from './OrderContinue';
import OrderHeader from './OrderHeader';
import s from './styles.module.scss';

type Props = {
  user: any;
  newOrder: any;
  routeTo: any;
  getAddresses: any;
  signup: any;
  setOrderTimeSlot: any;
  getBusyslots: any;
  setOrderDate: any;
  setOrderAddress: any;
  config: any;
  addresses: any;
};

type State = {
  signupData: any;
};

class OrderAppointment extends React.Component<Props, State> {
  static propTypes = {
    config: PropTypes.object,
    newOrder: PropTypes.object,
    user: PropTypes.object,
    addresses: PropTypes.array.isRequired,
    getBusyslots: PropTypes.func.isRequired,
    getAddresses: PropTypes.func.isRequired,
    setOrderTimeSlot: PropTypes.func.isRequired,
    setOrderDate: PropTypes.func.isRequired,
    setOrderAddress: PropTypes.func.isRequired,
    routeTo: PropTypes.func.isRequired,
    signup: PropTypes.func.isRequired,
  };
  public static defaultProps = {
    config: {},
    newOrder: {},
    user: {},
  };

  appointment: any;
  signupForm: any;

  constructor(props) {
    super(props);

    this.state = {
      signupData: {},
    };
  }

  componentDidMount() {
    const { user, newOrder, routeTo, getAddresses } = this.props;

    if (newOrder.status !== ACTION_START) {
      routeTo('order-storage');
    }

    if (user.isLoggedIn) {
      getAddresses();
    }
  }

  signupUser(data, cb) {
    const { signup, user } = this.props;
    const { name, email, phone, cityId, countryId } = data;
    const fbUser = user && user.profile && user.profile.incomplete;

    const password = (() => {
      let text = '';
      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      for (let i = 0; i < 5; i += 1) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    })();

    signup({ name, email, password, phone, cityId, countryId, fbUser }, cb);
  }

  render() {
    const { signupData } = this.state;
    const {
      newOrder,
      setOrderTimeSlot,
      routeTo,
      getBusyslots,
      setOrderDate,
      setOrderAddress,
      config,
      user,
      addresses,
    } = this.props;
    const { isLoggedIn, signupStatus } = user;

    const isSlotValid =
      this.appointment &&
      this.appointment.isSlotValid(newOrder.requestDate, newOrder.timeSlot);
    const isDisabled =
      (this.signupForm && !signupData.isValid) ||
      !isSlotValid ||
      !newOrder.address.cityId ||
      !newOrder.address.area ||
      !newOrder.address.line1 ||
      !newOrder.items.length;

    const isLoading = (signupStatus || {}).status === ACTION_START;

    return (
      <div>
        <OrderHeader
          newOrder={newOrder}
          currency={config.currency}
          storagetypes={config.storagetypes}
          current="details"
          routeTo={routeTo}
          isHeaderContinueDisabled={isDisabled}
          onContinueClick={() => {
            if (!isLoggedIn) {
              const { name, password, phone, email } = signupData;
              this.signupUser(
                {
                  name,
                  phone,
                  email,
                  password,
                  cityId: newOrder.address.cityId,
                },
                () => {
                  routeTo('order-confirm');
                }
              );
            } else {
              routeTo('order-confirm');
            }
          }}
        />
        <div className="container">
          <h3 key="title1" className={`${s.orderQuestion} text-center`}>
            Now, let’s set up your appointment
          </h3>

          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-6 m-b-20">
              <Appointment
                ref={app => {
                  this.appointment = app;
                }}
                cityId={config.cityId}
                getBusyslots={getBusyslots}
                timeslots={config.timeslots}
                selectedDate={newOrder.requestDate}
                selectedTimeSlot={newOrder.timeSlot}
                onDateChange={setOrderDate}
                onTimeSlotChange={setOrderTimeSlot}
              />
            </div>
            <div className="col-span-12 md:col-span-6  m-b-20">
              <div className="box center p-20 mx-3">
                {!isLoggedIn && (
                  <SignupForm
                    cityId={config.cityId}
                    ref={signupForm => {
                      this.signupForm = signupForm;
                    }}
                    fbLoginText="Continue with Facebook"
                    onChange={data => {
                      this.setState({ signupData: data });
                    }}
                  />
                )}
                <AddressForm
                  title={isLoggedIn ? 'Address' : ''}
                  cityId={config.cityId}
                  addresses={addresses}
                  selectedAddress={newOrder.address}
                  onChange={setOrderAddress}
                />
              </div>
            </div>
          </div>
        </div>
        <OrderContinue
          onContinueClick={() => {
            if (!isLoggedIn) {
              const { name, password, phone, email, countryId } = signupData;
              this.signupUser(
                {
                  name,
                  phone,
                  email,
                  password,
                  countryId,
                  cityId: newOrder.address.cityId,
                },
                () => {
                  routeTo('order-confirm');
                }
              );
            } else {
              routeTo('order-confirm');
            }
          }}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </div>
    );
  }
}

// OrderAppointment.propTypes = {
//     config: PropTypes.object,
//     newOrder: PropTypes.object,
//     user: PropTypes.object,
//     addresses: PropTypes.array.isRequired,
//     getBusyslots: PropTypes.func.isRequired,
//     getAddresses: PropTypes.func.isRequired,
//     setOrderTimeSlot: PropTypes.func.isRequired,
//     setOrderDate: PropTypes.func.isRequired,
//     setOrderAddress: PropTypes.func.isRequired,
//     routeTo: PropTypes.func.isRequired,
//     signup: PropTypes.func.isRequired,
// };

// OrderAppointment.defaultProps = {
//     config: {},
//     newOrder: {},
//     user: {},
// };

const OrderAppointmentContainer = connect(
  state => ({
    config: (state as any).config,
    user: (state as any).user,
    newOrder: (state as any).newOrder,
    addresses: (state as any).addresses,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...newOrderActions,
        ...appActions,
        ...userActions,
        ...configActions,
        ...addressActions,
      },
      dispatch
    )
)(OrderAppointment);

export default OrderAppointmentContainer;
