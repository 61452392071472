import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
type Props = {
  isDisabled: any;
  isLoading: any;
  continueText: any;
  onContinueClick: any;
};

class OrderContinue extends React.PureComponent<Props> {
  static propTypes = {
    onContinueClick: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    continueText: PropTypes.string,
  };
  public static defaultProps = {
    isDisabled: false,
    isLoading: false,
    continueText: 'Continue',
  };
  render() {
    const { isDisabled, isLoading, continueText, onContinueClick } = this.props;

    return (
      <div className="text-center center">
        <div className="block">
          <Button
            onClick={onContinueClick}
            disabled={isDisabled}
            loading={isLoading}
            text={continueText}
            big
            full
          />
        </div>
      </div>
    );
  }
}

// OrderContinue.propTypes = {
//   onContinueClick: PropTypes.func.isRequired,
//   isDisabled: PropTypes.bool,
//   isLoading: PropTypes.bool,
//   continueText: PropTypes.string,
// };

// OrderContinue.defaultProps = {
//   isDisabled: false,
//   isLoading: false,
//   continueText: 'Continue',
// };

export default OrderContinue;
