import React, { Component } from 'react';
import swal from 'sweetalert2';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { appActions, orderActions } from '../data/actions';
import Button from '../components/Button';

type Props = {
  queryParams: any;
  routeTo: any;
  orderUpdate: any;
  urlParams: any;
};

type State = {
  reason: string[];
  note: string;
};

class OrderActionPage extends Component<Props, State> {
  static propTypes = {
    queryParams: PropTypes.object.isRequired,
    routeTo: PropTypes.func.isRequired,
    orderUpdate: PropTypes.func.isRequired,
    urlParams: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { queryParams, routeTo, orderUpdate, urlParams } = this.props;
    const orderId = urlParams.orderId;
    const action = queryParams.action;
    const accessToken = queryParams.accessToken;

    if (
      orderId &&
      action &&
      accessToken &&
      ['renew', 'close'].indexOf(action) !== -1
    ) {
      orderUpdate(orderId, { action: action }, accessToken);
    } else {
      routeTo('home');
    }
  }

  render() {
    const { queryParams } = this.props;
    const action = queryParams.action;
    return (
      <div>
        <div>
          <div className="box p-10 mt-10 mx-auto max-w-2xl">
            <h1 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">Thank You!</h1>
            <p className="text-center text-xl mt-16">
              Thank you for letting us know.
              <br />
              {action === 'close' && <span>We will contact you shortly.</span>}
            </p>
            <div className="text-center m-16">
              <Button
                className="center"
                onClick={() => {
                  window.location.href = 'https://www.boxitstorage.com/';
                }}
                text="Home"
                full={false}
                big
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const OrderActionContainer = connect(
  state => ({
    order: (state as any).order,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...appActions,
        ...orderActions,
      },
      dispatch
    )
)(OrderActionPage);

export default OrderActionContainer;
